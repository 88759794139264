import DS from 'ember-data';

const {
  Model,
  attr,
} = DS;

export default class MeritMoneyCategory extends Model {
  @attr('string')
  name;

  @attr('string')
  description;

  @attr('number')
  position;
}
