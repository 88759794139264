import { computed } from '@ember/object';
import Component from '@ember/component';
import { capitalize } from '@ember/string';
import { inPln } from 'selleo-dashboard/helpers/in-pln';

export default class TotalSplitsTo extends Component {
  @computed
  get chartOptions() {
    return {
      title: { text: null },

      tooltip: {
        useHTML: true,
        formatter(tooltip) {
          if (this.point.name === 'Seniority') {
            return (
              `MM Seniority: <strong> ${inPln(this.point.value)} </strong><br/>` +
              `Seniority Point Value: ${inPln(this.point.settlement.meritMoneyDetails.seniority_point_value)} <br/>` +
              `Your Seniority Points: ${this.point.settlement.meritMoneyDetails.seniority_points} <br/>`
            );
          } else if (this.point.name === 'Kudos') {
            return (
              `MM Kudos: <strong> ${inPln(this.point.value)} </strong><br/>` +
              `Kudo Value: ${inPln(this.point.settlement.meritMoneyDetails.kudo_value)} <br/>` +
              `Your Kudos: ${this.point.settlement.meritMoneyDetails.kudos_collected} <br/>`
            );
          } else if (this.point.name === 'Base') {
            let baseDetails = this.point.settlement.baseRateDetails;

            return (
              `Base: <strong> ${inPln(this.point.value)} </strong>

                <h4>Elements of Hourly Rate</h4>
                <table style="text-align: right;">
                <thead>
                  <tr>
                    <td style="min-width: 9em;"></td>
                    <td style="min-width: 9em;"><strong>${baseDetails.base_rate}h</strong></td>
                    <td style="min-width: 9em;"></td>
                  </tr>
                  <tr>
                  <th></th>
                  <th>hourly rate</th>
                  <th></th>
                </tr>
                  </thead>
                <tbody>
                  ${baseDetails.base_rate_lines.map((line) => {
                return `<tr>
                    <td style="text-align: left;">${line.name}</td>
                    <td>${inPln(line.value)}</td>
                    <td></td>
                  </tr>
                  <tr>
                  <td colspan="3" style="text-align: left; max-width: 27em;">
                        <small><small><i>${line.comment}</i></small></small>
                    </td>
                  </tr>`;
              }).join(' ')}
                </tbody>
               </table>

                <h4>Hours Delivered</h4>
                <table style="text-align: right;">
              <thead>
                  <tr>
                    <td style="min-width: 9em;"></td>
                    <td style="min-width: 9em;"></td>
                    <td style="min-width: 9em;"><strong>${baseDetails.hours_all}h</strong></td>
                  </tr>
                  <tr>
                  <th></th>
                <th>at rate</th>
                  <th>hours</th>
                  </tr>
                </thead>
                <tbody>
                ${baseDetails.work_elements.map((element) => {
                return `<tr>
                    <td>${element.label}</td>
                    <td>${inPln(element.rate)}</td>
                    <td>${element.hours}h</td>
                </tr>`;
              }).join(' ')}
                </tbody>
                </table>
                `
            );
          } else if (this.point.name === 'Extra') {
            let { extraDetails } = this.point.settlement;
            return (
              `Extra: <strong>${inPln(this.point.value)}</strong>
              <table style="text-align: right;table-layout: fixed">
                <thead>
                  <tr>
                    <td style="max-width: 100px;font-weight: 700">Comment</td>
                    <td style="min-width: 6em;font-weight: 700">Value</td>
                  </tr>
                </thead>
                <tbody>
              ${extraDetails.extra_lines.map((line) => (
                `<tr>
                  <td>${line.comment}</td>
                  <td>${inPln(line.value)}</td>
                 </tr>`
              ))}
                </tbody>
                </table>`
            );
          }  else if (this.point.name === 'SalaryOshee') {
            return (
              `Oshee: <strong> ${inPln(this.point.value)} </strong><br/>` +
              `Hourly Rate: ${inPln(this.point.settlement.baseRateDetails.oshee.rate)} <br/>` +
              `Learned Hours: ${this.point.settlement.baseRateDetails.oshee.hours} <br/>`
            );
          } else {
            return tooltip.defaultFormatter.call(this, tooltip);
          }
        },
      },
    };
  }

  @computed
  get chartData() {
    return [
      {
        type: 'treemap',
        layoutAlgorithm: 'stripes',
        alternateStartingDirection: true,
        levels: [
          {
            level: 1,
            layoutAlgorithm: 'sliceAndDice',
            dataLabels: {
              enabled: true,
              align: 'center',
              verticalAlign: 'middle',
              style: {
                fontSize: '20px',
                fontFamily: 'Roboto',
              },
            },
          },
        ],

        data: [...this.settlementParticles],
      },
    ];
  }

  @computed('settlement')
  get settlementParticles() {
    return [
      ['salaryBase', 'LightSkyBlue'],
      ['salaryOshee', 'Khaki'],
      ['salaryKudos', 'LightCoral'],
      ['salarySeniority', 'LightGreen'],
      ['salaryExtra', 'LightSalmon'],
      ['salaryRelaxing', 'LightYellow']
    ].map(([property, color]) => {
      return {
        id: property,
        settlement: this.get('settlement'),
        name: capitalize(property.replace('salary', '')),
        value: this.settlement[property],
        color,
      };
    });
  }
}
