import { computed } from '@ember/object';

import DS from 'ember-data';

const {
  Model,
  attr,
  hasMany
} = DS;

export default class Level extends Model {
  @attr('string')
  name;

  @attr('string')
  description;

  @attr('string')
  icon;

  @hasMany('constituent')
  constituents;

  @hasMany('contractor-level-stat')
  contractorStats;

  @attr('string')
  competenceName;

  @attr('string')
  contextName;

  @computed('competenceName', 'contextName', 'name')
  get label() {
    return `${this.competenceName} > ${this.contextName} > ${this.name}`;
  }
}
