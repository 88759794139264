import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import { tracked } from '@glimmer/tracking';

export default class AuthenticatedRouteRoute extends Route.extend(AuthenticatedRouteMixin) {
  @service
  store;

  @service
  terms;

  @tracked
  pendingTerms;

  async beforeModel() {
    if (await this.terms.areTermsAccepted()) {
      return this._super(...arguments);
    }

    this.transitionTo('terms');
  }

  afterModel() {
    this.get('session.currentUser');
  }
}
