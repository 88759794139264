import Controller from '@ember/controller';
import moment from 'moment';

export default class IndexController extends Controller {
  queryParams = [
    'contractor',
    'customer',
    'periodStart',
    'periodEnd',
    'category',
    'searchText',
  ];

  contractor = null;

  customer = null;

  periodStart = null;

  periodEnd = null;

  category = 'billable';

  searchText = null;

  init() {
    super.init(...arguments);

    if (this.periodStart) {
      return;
    }

    let target = moment().subtract(2, 'weeks').day(1);
    this.set('periodStart', target.format('YYYY-MM-DD'));
    this.set('periodEnd', target.add(2, 'weeks').day(0).format('YYYY-MM-DD'));
  }
}
