import { inject as service } from '@ember/service';
import Component from '@ember/component';
import EmberObject, { computed } from '@ember/object';
import { find, groupBy } from 'lodash';
import moment from 'moment';

export default class TeamHappix extends Component {
  @service
  store;

  @computed('categories.[]')
  get chartOptions() {
    return {
      chart: {
        height: 460,
        type: 'line'
      },

      title: {
        text: 'Happix'
      },

      tooltip: {
        shared: true
      },

      xAxis: {
        categories: this.categories
      },

      yAxis: {
        min: 1,
        max: 5,
        title: {
          text: 'Happixes'
        }
      },
    };
  }

  @computed
  get categories() {
    let target = moment().add(1, 'days');
    let current = moment().subtract(6, 'weeks');
    let series = [];
    while (current.add(1, 'days').isBefore(target)) {
      series.push(current.format('DD MMM'));
    }

    return series;
  }

  @computed('model')
  get groupedHappix() {
    return groupBy(this.model.toArray(), ({ contractor }) => contractor.get('id'));
  }

  @computed('categories', 'groupedHappix')
  get chartData() {
    let contractors = Object.keys(this.groupedHappix);
    return contractors.map((contractor) => {
      let happixes = this.groupedHappix[contractor];
      let name = this.store.peekRecord('contractor', contractor).get('name');
      return {
        name,
        data: this.categories.map((date) => {
          let item = find(happixes, (happix) => moment(happix.get('date')).format('DD MMM') === date) || EmberObject.create({ happix: null });
          return item.get('happix');
        })
      };
    });
  }
}
