import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class BillableController extends Controller {
  queryParams = ['scope'];

  @tracked
  scope = 'monthly';

  @action
  setScope(value) {
    this.scope = value;
  }
}
