import DS from 'ember-data';
import { belongsTo } from 'ember-data/relationships';

const {
  Model,
  attr
} = DS;

export default class ScheduleWeeklyStat extends Model {
  @belongsTo()
  customer;

  @belongsTo()
  contractor;

  @attr('number')
  year;

  @attr('number')
  week;

  @attr('number')
  minutes;
}
