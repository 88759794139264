import { helper } from '@ember/component/helper';
import { round } from 'lodash';

export function inPercentage([value, range, precision = 0]) {
  if (value && range) {
    return round(value / range * 100, precision);
  }

  return 0;
}

export default helper(inPercentage);
