import Component from '@ember/component';
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';

export default class HealthcheckList extends Component {
  sortDefinition = Object.freeze(['balance.balance']);

  @sort('customers', 'sortDefinition')
  sortedCustomers;

  @computed('showInactive', 'sortedCustomers')
  get activeCustomers() {
    let sorted = this.sortedCustomers;
    let { showInactive } = this;
    return showInactive ? sorted : sorted.filterBy('isActive', true);
  }

  @computed('query', 'activeCustomers')
  get filteredCustomers() {
    let regExp = new RegExp(this.query, 'i');
    return this.activeCustomers.filter((customer) => customer.get('name').match(regExp));
  }
}
