import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { sum, mapBy } from '@ember/object/computed';
import Component from '@ember/component';

export default @tagName('span') class BalanceSum extends Component {
  @mapBy('customers', 'balance')
  financeStats;

  @computed('financeStats')
  get balances() {
    return this.financeStats.map((stat) => stat.get('balance') || 0);
  }

  @sum('balances')
  sumOfBalances;
}
