import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { groupBy, sortBy, sumBy } from 'lodash';
import moment from 'moment';

export default class AvgHappix extends Component {
  @service
  store;

  @service
  router;

  @computed('categories.[]')
  get chartOptions() {
    return {
      chart: {
        height: 200,
        type: 'line'
      },

      title: {
        text: 'Team Happix'
      },

      legend: {
        enabled: true,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        floating: false,
        borderWidth: 0,
        useHTML: true,
        navigation: {
          enabled: false
        },

        title: {
          style: { fontSize: '36px' },
          text: this.weekAvg
        }
      },

      xAxis: {
        categories: this.categories
      },

      yAxis: {
        min: 1,
        floor: 1,
        max: 5,
        ceiling: 5,
        startOnTick: false,
        endOnTick: false,
        title: {
          enabled: false
        }
      }
    };
  }

  @computed('groupedHappix')
  get categories() {
    return sortBy(Object.keys(this.groupedHappix), (date) => this.groupedHappix[date][0].date);
  }

  @computed('model')
  get groupedHappix() {
    return groupBy(this.model.toArray(), ({ date }) =>
      moment(date).format('DD MMM')
    );
  }

  @computed('categories')
  get chartData() {
    let dates = this.categories;
    return [
      {
        name: 'Average',
        data: dates.map((date) => {
          let happixes = this.groupedHappix[date];
          return (
            Math.round(
              sumBy(happixes, (happix) => happix.get('happix') * 100) /
                happixes.length
            ) / 100
          );
        })
      }
    ];
  }

  @computed('chartData')
  get weekAvg() {
    return (
      Math.round(
        sumBy(this.chartData[0].data, (happix) => happix * 100) /
          this.chartData[0].data.length
      ) / 100
    );
  }

  click() {
    this.router.transitionTo('teams.team.happix', this.team);
  }
}
