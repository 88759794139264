import { action, computed } from '@ember/object';
import Component from '@ember/component';
import { tracked } from '@glimmer/tracking';

export default class ForTeams extends Component {
  today = new Date();

  @tracked
  selectedMonth = this.today.getMonth() + 1;

  @tracked
  selectedYear = this.today.getFullYear();

  @computed('selectedMonth', 'selectedYear')
  get isNextMonthDisabled() {
    return this.selectedMonth === this.today.getMonth() + 1 && this.selectedYear === this.today.getFullYear();
  }

  @action
  changeToPreviousMonth() {
    if (this.selectedMonth === 1) {
      this.selectedMonth = 12;
      this.selectedYear -= 1;
      return;
    }

    this.selectedMonth -= 1;
  }

  @action
  changeToNextMonth() {
    if (this.selectedMonth === 12) {
      this.selectedMonth = 1;
      this.selectedYear += 1;
      return;
    }

    this.selectedMonth += 1;
  }
}
