import DS from 'ember-data';

const {
  Model,
  attr,
  belongsTo
} = DS;

export default class FinanceCustomerCashInflow extends Model {
  @attr('number')
  value;

  @belongsTo()
  customer;

  @belongsTo('payroll-period')
  period;
}
