import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import DS from 'ember-data';

const { Model, attr, hasMany, belongsTo } = DS;

export default class Customer extends Model {
  @attr('string')
  name;

  @alias('name')
  label;

  @attr()
  compCostParticipation;

  @attr('string')
  since;

  @attr()
  opts;

  @belongsTo('finance-customer-balance')
  balance;

  @hasMany('contractors', { inverse: 'customers' })
  contractors;

  @hasMany('contractor')
  recentContractors;

  @hasMany()
  events;

  @hasMany('finance-customer-cash-inflow')
  cashInflows;

  @hasMany('finance-customer-cash-outflow')
  cashOutflows;

  @computed('opts')
  get isActive() {
    return !(this.get('opts.archived') === 'true');
  }

  @computed('opts.logo')
  get logoPath() {
    if (this.get('opts.logo')) {
      return `/companies/${this.get('opts.logo')}`;
    } else {
      return false;
    }
  }
}
