import ENV from '../config/environment';
import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { inject as service } from '@ember/service';
import { underscore } from '@ember/string';
import { pluralize } from 'ember-inflector';
import { get, computed } from '@ember/object';
import { isPresent } from '@ember/utils';

export default class ApplicationAdapter extends JSONAPIAdapter {
  @service
  auth0;

  @service
  session;

  host = ENV.APP.host;

  namespace = 'api/v2';

  pathForType(type) {
    return pluralize(underscore(type));
  }

  @computed('session.data.authenticated.accessToken')
  get headers() {
    let { accessToken } = get(this, 'session.data.authenticated');

    if (isPresent(accessToken)) {
      return {
        'Authorization': `Bearer ${accessToken}`
      };
    } else {
      return {};
    }
  }

  handleResponse(status, headers, payload, requestData) {
    this.ensureResponseAuthorized(status, headers, payload, requestData);
    return super.handleResponse(...arguments);
  }

  ensureResponseAuthorized(status/* ,headers, payload, requestData */) {
    if (status === 401 && this.get('session.isAuthenticated')) {
      this.get('session').invalidate();
    }
  }
}
