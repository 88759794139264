import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default class KudosGiven extends Component {
  @service
  session;

  header = 'Kudos Given';

  @computed('kudosGiven.@each.{state,isNew}')
  get drafts() {
    return this.kudosGiven.filter((kudo) => !kudo.isNew && kudo.state === 'draft');
  }

  @computed('kudos.@each.{giver}')
  get kudosGiven() {
    return this.kudos.filter((kudo) => kudo.get('giver.id') === this.session.get('currentUser.id'));
  }
}
