import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"accept-terms-page\">\n  <div class=\"accept-terms-background\" />\n  <div class=\"accept-terms-modal\">\n    <div class=\"accept-terms-modal__heading\">\n      <h1 class=\"accept-terms-modal__header\">\n        {{this.currentTerm.title}}\n      </h1>\n    </div>\n    <div class=\"accept-terms-modal__content\">\n      <p>\n        {{this.content}}\n      </p>\n      <button\n        disabled={{this.acceptTerm.isRunning}}\n        type=\"button\"\n        class=\"accept-terms-modal__accept-button\"\n        {{on \"click\" (perform this.acceptTerm)}}\n      >\n        Confirm\n      </button>\n    </div>\n  </div>\n</div>\n", {"contents":"<div class=\"accept-terms-page\">\n  <div class=\"accept-terms-background\" />\n  <div class=\"accept-terms-modal\">\n    <div class=\"accept-terms-modal__heading\">\n      <h1 class=\"accept-terms-modal__header\">\n        {{this.currentTerm.title}}\n      </h1>\n    </div>\n    <div class=\"accept-terms-modal__content\">\n      <p>\n        {{this.content}}\n      </p>\n      <button\n        disabled={{this.acceptTerm.isRunning}}\n        type=\"button\"\n        class=\"accept-terms-modal__accept-button\"\n        {{on \"click\" (perform this.acceptTerm)}}\n      >\n        Confirm\n      </button>\n    </div>\n  </div>\n</div>\n","moduleName":"selleo-dashboard/components/accept-terms-modal.hbs","parseOptions":{"srcName":"selleo-dashboard/components/accept-terms-modal.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { task } from 'ember-concurrency';

export default class AcceptTermsModalComponent extends Component {
  @service
  terms;

  @service
  router;

  get currentTerm() {
    return this.terms.currentTerm;
  }

  get content() {
    return htmlSafe(this.currentTerm?.richContent);
  }

  get isMoreThanOneTerm() {
    return this.terms.pendingTerms?.length > 1;
  }

  @(task(function* () {
    yield this.terms.acceptTerm();

    if (!this.currentTerm) {
      this.router.transitionTo('d.personal');
    }
  }).drop())
  acceptTerm;
}
