import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';
import { hash } from 'rsvp';
import moment from 'moment';

export default class IndexRoute extends AuthenticatedRoute {
  model() {
    let team = this.modelFor('teams.team');
    let { utilizations } = this.modelFor('teams');

    if (team.type === 'Team::ServiceDelivery') {
      return hash({
        team,
        utilizations,
        happixes: this.store.query('schedule-happiness', {
          include: 'contractor',
          filter: {
            team_id: team.id,
            within_period: `${moment()
              .subtract(2, 'weeks')
              .format('YYYY-MM-DD')}..${moment().format('YYYY-MM-DD')}`
          }
        }),

        bookingExceptions: this.store.query('schedule-booking-exception', {
          include: 'contractor',
          filter: {
            team_id: team.id
          }
        })
      });
    } else {
      return { team };
    }
  }
}
