import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

export default @tagName('span') class DisplayUniqAvatars extends Component {
  @computed
  get uniqContractorAvatars() {
    let contractorAvatars = [];
    let customers = this.get('teamLeader.customers');
    customers.forEach(function(customer) {
      let contractors = customer.get('contractors');
      contractors.forEach(function(contractor) {
        contractorAvatars.pushObject(contractor.get('avatarUrl'));
      });
    });
    return contractorAvatars.uniq();
  }
}
