import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class TermsRoute extends Route {
  @service
  session;

  @service
  terms;

  @service
  router;

  async beforeModel() {
    if (!this.session.isAuthenticated) {
      this.router.transitionTo('login');
    }

    if (await this.terms.areTermsAccepted()) {
      this.router.transitionTo('d.personal');
    }
  }
}
