import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { reverse, uniqBy } from 'lodash';
import moment from 'moment';

export default class TeamStream extends Component {
  @service
  store;

  @computed('weekNames.[]')
  get chartOptions() {
    return ({
      chart: {
        height: '60%',
        type: 'streamgraph'
      },

      tooltip: {
        valueDecimals: 0
      },

      title: {
        text: ''
      },

      xAxis: {
        categories: this.weekNames
      },

      yAxis: {
        visible: false,
        startOnTick: false,
        endOnTick: false
      },

      plotOptions: {
        area: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          }
        }
      }
    });
  }

  @computed('model.workloadStats')
  get weeks() {
    return reverse(uniqBy(this.model.workloadStats.toArray().map((stat) => ({
      year: stat.get('year'),
      week: stat.get('week')
    })), (week) => `${week.year}-${week.week}`));
  }

  @computed('model.workloadStats')
  get contractors() {
    return uniqBy(this.model.workloadStats.toArray().map((stat) => stat.get('contractor')), (contractor) => contractor.get('id'));
  }

  @computed('weeks.[]')
  get weekNames() {
    return this.weeks.map((week) => moment()
      .year(week.year)
      .week(week.week)
      .day(1).format('Do MMM YYYY'));
  }

  @computed('weeks.[]')
  get chartData() {
    let chartData = this.contractors.map((contractor) => {
      let data = {
        name: contractor.get('name'),
        data: this.weeks.map((week) => {
          let stats = this.model.workloadStats.filter(this._filterStatForWeek(contractor, week));
          let minutes = stats.reduce((acc, item) => {
            return acc += item.minutesPaid / 60.0;
          }, 0);
          return minutes;
        })
      };
      return data;
    });
    return chartData;
  }

  _filterStatForWeek(contractor, week) {
    return (stat) => stat.get('contractor.id') === contractor.get('id') && stat.week === week.week && stat.year === week.year;
  }
}
