import { action, computed } from '@ember/object';
import Component from '@ember/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class ForTeams extends Component {
  @service
  store;

  @tracked
  pastUtilizations = [];

  @tracked
  isLoading = false;

  @computed('selectedMonth', 'selectedYear')
  get selectedDateFormatted() {
    return `${this.selectedMonth}/${this.selectedYear}`;
  }

  @computed('pastUtilizations.[]', 'selectedMonth', 'selectedYear', 'today', 'utlizations.[]')
  get data() {
    if (this.selectedMonth === this.today.getMonth() + 1 && this.selectedYear === this.today.getFullYear()) {
      return this.utilizations;
    }

    return this.pastUtilizations;
  }

  @action
  async loadPastUtilizations() {
    this.isLoading = true;
    let pastUtilizations = await this.store.query('schedule-contractor-past-utilization', {
      include: 'contractor',
      filter: {
        month: this.selectedMonth,
        year: this.selectedYear
      }
    });

    this.pastUtilizations = pastUtilizations;
    this.isLoading = false;
  }
}
