import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';
import { hash } from 'rsvp';

export default class InteractionsStatsRoute extends AuthenticatedRoute {
  queryParams = {
    scope: {
      refreshModel: true
    }
  };

  model(params) {
    return hash({
      interactions: this.store.query('merit-money-interactions-stat', {
        filter: {
          for_contractor: this.paramsFor('mm.kudos.contractor').contractor_id,
          week_scope: params.scope
        },

        include: 'giver,receiver,week'
      }),

      kudos: this.store.query('merit-money-kudo', {
        filter: {
          chart_scope: [this.paramsFor('mm.kudos.contractor').contractor_id, params.scope]
        },

        include: 'giver,receiver'
      })
    });
  }
}
