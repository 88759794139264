import DS from 'ember-data';

const { Model, attr, belongsTo } = DS;

export default class ScheduleRecentDelayed extends Model {
  @belongsTo
  contractor;

  @belongsTo('payroll-period')
  period;

  @attr
  delayedHours;

  @attr
  mmCut;

  @attr('number')
  bookingExceptionCut;
}
