import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class LogoutRoute extends Route {
  @service
  auth;

  activate() {
    this.auth.logout();
    this.transitionTo('index');
  }
}
