import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { map } from '@ember/object/computed';
import { debounce } from '@ember/runloop';
import RSVP from 'rsvp';

export default class ContractorFilter extends Component {
  @service
  store;

  filter = {};

  @computed
  get contractors() {
    return this.store.query('contractor', { filter: this.filter });
  }

  @computed('contractors.isFulfilled')
  get contractorsAreLoaded() {
    return !this.get('contractors.isFulfilled');
  }

  @map('searchResults', function(searchResult) {
    return searchResult.get('name');
  })
  searchResultsContractorNames;

  _escapeRegExp(input) {
    // $& means the whole matched string
    return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  _setSearchText(contractorSearchText, resolve) {
    let { contractors } = this;

    let nameRegExp = new RegExp(this._escapeRegExp(`${contractorSearchText}`), 'i', 'g');

    let results = contractors.filter((contractor) => nameRegExp.exec(contractor.get('name'))) || [];

    resolve(results);
    this.set('searchResults', results);
    this.set('results', contractorSearchText.length > 1 ? this.searchResultsContractorNames : []);

    return this.results;
  }

  @action
  debounceSearch(contractorSearchText) {
    return new RSVP.Promise((resolve) => {
      debounce(this, this._setSearchText, contractorSearchText, resolve, 300);
    });
  }

  @action
  onSelectionChange(selectedContractor) {
    this.set('selectedContractor', selectedContractor);
    this.set('results', []);
    if (this.callback) {
      this.callback(selectedContractor);
    }
  }
}
