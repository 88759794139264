import DS from 'ember-data';

const {
  Model,
  attr,
  belongsTo
} = DS;

export default class Rating extends Model {
  @attr('number')
  points;

  @belongsTo('contractor')
  contractor;
}
