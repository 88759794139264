import { classNames } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';

export default

@classNames(
  'layout-row',
  'layout-align-space-between-start',
  'md-autocompleter-height-limit'
)
class TopBar extends Component {
  @service
  router;

  @alias('currentContractorWeek.contractor')
  contractor;

  @alias('contractor.kudosGiven')
  kudosGiven;

  @computed('kudosGiven.@each.{value,state}')
  get kudosLeft() {
    return (
      this.currentContractorWeek.get('kudosRation') -
      this.kudosGiven.reduce(
        (memo, kudo) =>
          kudo.get('state') === 'draft' ? memo + kudo.get('value') : memo,
        0
      )
    );
  }

  @computed('kudosLeft')
  get kudosLeftPercentage() {
    if (this.kudosLeft < 0) {
      return 0.1;
    } else {
      return Math.floor(
        (this.kudosLeft / this.currentContractorWeek.kudosRation) * 100.0
      );
    }
  }

  @computed('kudosLeft')
  get absKudosLeft() {
    return Math.abs(this.kudosLeft);
  }

  _escapeRegExp(input) {
    // $& means the whole matched string
    return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  @action
  onSelectionChange(selectedContractor) {
    this.set('searchResults', []);
    this.set('searchText', '');
    if (selectedContractor) {
      if (this.router.currentRouteName === 'mm.kudos.index') {
        this.router.transitionTo(
          'mm.kudos.contractor',
          selectedContractor.get('id')
        );
      } else {
        this.router.transitionTo(
          this.router.currentRouteName,
          selectedContractor.get('id'),
          { queryParams: this.router.currentRoute.queryParams }
        );
      }
    }
  }

}
