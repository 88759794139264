import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';

export default class CustomersRoute extends AuthenticatedRoute {
  model() {
    return this.store.query('customer', {
      include: 'balance',
      filter: {
        billable: true
      }
    });
  }
}
