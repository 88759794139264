import ApplicationAdapter from './application';

export default class Contractor extends ApplicationAdapter {
  buildQuery(snapshot) {
    let query = super.buildQuery(...arguments);

    if (snapshot.adapterOptions) {
      let { extraFields } = snapshot.adapterOptions;

      if (extraFields) {
        query['extra_fields[contractors]'] = extraFields;
      }
    }

    return query;
  }
}
