import Component from '@ember/component';
import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { groupBy } from 'lodash';

export default @tagName('') class InteractionsChart extends Component {
  @service
  router;

  @service
  store;

  @service
  session;

  @computed('interactions.@each')
  get data() {
    return [
      {
        type: 'sankey',
        keys: ['from', 'to', 'weight', 'comments'],
        data: this.get('interactions').map((i) => [
          i.get('giver.name'),
          i.get('receiver.name'),
          i.get('kudos'),
          this.kudosComments(i)
        ])
      }
    ];
  }

  @computed('groupedDataSeries.[]')
  get options() {
    return {
      title: undefined,
      chart: {
        marginLeft: 115,
        marginBottom: 125,
        marginRight: 115,
        height: 800
      },

      tooltip: {
        pointFormat:
          '<span style="font-size: 12px; font-weight: 400"><i>{point.fromNode.name} \u2192 {point.toNode.name}:</i> \
                      <b>{point.weight} kudo(s)</b></span><br/>\
                      `<br/>{point.comments}',

        nodeFormat: '{point.name}<br/>'
      },

      plotOptions: {
        sankey: {
          clip: false,
          name: '',
          nodeWitdth: 10,
          dataLabels: {
            color: '#333',
            style: {
              textOutline: '4px contrast'
            }
          },

          allowPointSelect: false
        }
      }
    };
  }

  @computed('kudos.@each')
  get kudosByContrator() {
    return groupBy(
      this.kudos.toArray(),
      (k) => `${k.get('giver.id')}-${k.get('receiver.id')}`
    );
  }

  kudosComments(interaction) {
    let kudos = this.kudosByContrator[
      `${interaction.get('giver.id')}-${interaction.get('receiver.id')}`
    ];
    // TODO: remove conditions after data loading fix
    if (kudos) {
      return kudos.map((k) => `<i>${k.tags[0]}</i> - <b>${k.value} kudo(s)</b> <br/>💬 ${k.comment}`).join('<br/>');
    } else {
      return '';
    }
  }
}
