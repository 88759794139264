import DS from 'ember-data';

const {
  Model,
  attr,
  hasMany
} = DS;

export default class AchievementCategory extends Model {
  @hasMany('achievement')
  achievements;

  @attr('string')
  name;

  @attr('string')
  label;

  @attr('number')
  weight;
}
