import { inject as service } from '@ember/service';
import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';

export default class MyRoute extends AuthenticatedRoute {
  @service
  session;

  model() {
    return this.store.query('team', {
      include: 'members,members.contractor,members.role',
      filter: {
        contractor_id: this.session.data.contractor_id
      }
    });
  }
}
