import { helper } from '@ember/component/helper';
import moment from 'moment';

export function prettyWeek([value]) {
  let [year, week] = value.split(/-|W/);
  let monday = moment().year(year).isoWeek(week).day(1);
  return monday.format('Do MMM YYYY');
}

export default helper(prettyWeek);
