import { inject as service } from '@ember/service';
import CapturePaste from 'selleo-dashboard/components/ui-generic/capture-paste/component';

export default class FromClipboard extends CapturePaste {
  @service
  router;

  handlePaste() {
    this.router.transitionTo('schedule.import-time-entries');
  }
}
