import { computed } from '@ember/object';
import { orderBy } from 'lodash';
import TeamUtilizationComponent from 'selleo-dashboard/components/schedule/team-utilization/component';
import moment from 'moment';
export default class ForTeam extends TeamUtilizationComponent {
  @computed('companyStat', 'teamStat', 'teammatesStat.[]')
  get data() {
    return [this.companyStat, this.teamStat, ...this.teammatesStat];
  }

  @computed('utilizations.[]')
  get companyStat() {
    return {
      label: 'Selleo',
      avatarUrl: '/images/selleo-avatar.jpg',
      ...this._prepareStat(this._sumMinutes(this.utilizations))
    };
  }

  @computed('utilizations.[]', 'team')
  get teamStat() {
    return {
      label: this.team.get('name'),
      avatarUrl: '/images/team-avatar.jpg',
      ...this._prepareStat(
        this._sumMinutes(this._contractorUtilizationsForTeam(this.team))
      )
    };
  }

  @computed('utilizations.[]')
  get teammatesStat() {
    let temmates =  this._contractorUtilizationsForTeam(this.team).map((cu) => {
      return {
        label: cu.contractor.get('name'),
        avatarUrl: cu.contractor.get('avatarUrl'),
        linkUrl: this._linkToTimeReport(cu.contractor.get('id')),
        ...this._prepareStat(cu)
      };
    });

    return orderBy(temmates, ['percentage', 'hoursAll'], ['desc', 'desc']);
  }

  _linkToTimeReport(contractorId) {
    let startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    let endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
    return `/schedule/report?category=non-billable&contractor=${contractorId}&periodEnd=${endOfMonth}&periodStart=${startOfMonth}`;
  }
}
