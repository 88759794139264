import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller, { inject as controller } from '@ember/controller';

export default class IndexController extends Controller {
  @controller('mm.kudos')
  parent;

  @service
  store;

  @computed('parent.{searchResults.[],searchText}')
  get filteredContractors() {
    return this.parent.searchResults.length && this.parent.searchText.length ? this.parent.searchResults : this.parent.model.contractors;
  }
}
