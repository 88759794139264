import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { sum, mapBy, filter } from '@ember/object/computed';
import Component from '@ember/component';

export default

@classNames('flex-gt-xs', 'flex-xs', 'flex-25', 'contractor-daily-stat-card-wrapper')
class AllThisMonth extends Component {
  @computed('dailyReports')
  get projects() {
    let { dailyReports } = this;
    let reduced = dailyReports.reduce(function(hash, dailyReport) {
      let key = dailyReport.get('customer.name');
      if (hash[key]) {
        hash[key].worked += dailyReport.get('worked');
        hash[key].target += dailyReport.get('target');
        hash[key].billable += dailyReport.get('billable');
      } else {
        hash[key] = {
          customer: key,
          worked: dailyReport.get('worked'),
          target: dailyReport.get('target'),
          billable: dailyReport.get('billable'),
        };
      }

      return hash;
    }, []);
    return Object.values(reduced);
  }

  @filter('projects', (project) => project.billable > 0)
  billableProjects;

  @filter('projects', (project) => project.billable === 0)
  nonBillableProjects;

  @mapBy('projects', 'worked')
  worked;

  @mapBy('projects', 'billable')
  billable;

  @mapBy('projects', 'target')
  target;

  @sum('worked')
  workedSum;

  @sum('billable')
  billableSum;

  @sum('target')
  targetSum;
}
