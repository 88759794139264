import DS from 'ember-data';
import { hasMany } from 'ember-data/relationships';

const {
  Model,
  attr
} = DS;

export default class Role extends Model {
  @attr('string')
  name;

  @attr('string')
  code;

  @attr('string')
  note;

  @hasMany('member')
  members;

  @hasMany('team')
  teams;
}
