export default function() {
  this.transition(
    this.fromRoute('mm.contractors'),
    this.toRoute('mm.contractor'),
    this.useAndReverse('explode', {
      matchBy: 'data-contractor-id',
      use: ['fly-to', { duration: 500, easing: 'ease-in-out' }]
    })
    // this.use('toLeft', { duration: 1000 }),
    // this.reverse('toRight')
  );
  this.transition(
    this.fromRoute('sm.wall'),
    this.toRoute('sm.wall.contractor.achievements'),
    this.useAndReverse('explode', {
      matchBy: 'data-contractor-id',
      use: ['fly-to', { duration: 500, easing: 'ease-in-out' }]
    })
  );
}
