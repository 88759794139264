import { classNameBindings } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import Component from '@ember/component';
import { floor } from 'lodash';

export default

@classNameBindings('isZero:color-grey')
class CellHours extends Component {
  @equal('minutes', 0)
  isZero;

  @computed('minutes')
  get hours() {
    return floor(this.minutes / 60);
  }

  @computed('minutes')
  get moduloMinutes() {
    return (this.minutes % 60).toString().padStart(2, '0');
  }
}
