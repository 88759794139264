import Component from '@ember/component';
import EmberObject, { computed } from '@ember/object';
import { groupBy, sum } from 'lodash';
import moment from 'moment';
import { minutesToHours } from 'selleo-dashboard/helpers/minutes-to-hours';

export default class WorklogGraph extends Component {
  @computed('categories')
  get chartOptions() {
    return {
      chart: {
        height: 480,
        type: 'line'
      },

      title: {
        text: 'Cumulative Worklog Graph'
      },

      xAxis: {
        categories: this.categories,
        plotLines: [
          {
            color: '#5C99D2',
            dashStyle: 'dot',
            width: 1,
            value: this.categories.indexOf(moment().format('D MMM'))
          }
        ]
      },

      yAxis: {
        title: {
          text: 'Hours'
        }
      },

      tooltip: {
        shared: true,
        headerFormat: '<i>{point.x}</i><br />',
        pointFormatter() {
          return `<b>${this.series.name}</b>:
            ${minutesToHours([this.minutes])}h - total
            ${minutesToHours([this.y * 60])}h<br/>`;
        }
      },

      plotOptions: {
        series: {
          step: 'left'
        }
      }
    };
  }

  @computed
  get categories() {
    return this.dates.map((date) => moment(date, 'D-MM').format('D MMM'));
  }

  @computed('bookingAvailabilities')
  get groupedTarget() {
    return groupBy(this.bookingAvailabilities.toArray(), (ba) =>
      moment(ba.get('dayOn')).format('D-MM')
    );
  }

  @computed('dailyStats')
  get groupedDailyStats() {
    return groupBy(this.dailyStats.toArray(), (ds) =>
      moment(ds.date).format('D-MM')
    );
  }

  @computed
  get dates() {
    let dates = [];
    let month = moment().month();
    for (let i = 1; i <= moment().daysInMonth(); i++) {
      dates.push(`${i}-${(month + 1).toString().padStart(2, '0')}`);
    }

    return dates;
  }

  @computed('bookingAvailabilities')
  get chartData() {
    let targets = this.dates.reduce((acc, date) => {
      let { y: lastY } = acc.slice(-1).pop() || { y: 0 };
      let hoursTarget = sum(
        (
          this.groupedTarget[date] || [EmberObject.create({ hoursTarget: 0 })]
        ).map((ht) => ht.get('hoursTarget'))
      );
      acc.push({ y: lastY + hoursTarget, minutes: hoursTarget * 60 });
      return acc;
    }, []);
    return [
      {
        name: 'Target',
        color: '#4C4D52',
        data: targets,
        marker: false,
        lineWidth: 1
      },
      {
        name: 'Worked',
        color: '#5C99D2',
        lineWidth: 3,
        data: this.dates.reduce((acc, date, i) => {
          if (moment(date, 'D-M').isAfter(moment())) {
            return acc;
          }

          let { y: lastY } = acc.slice(-1).pop() || { y: 0 };
          let minutesWorked = sum(
            (
              this.groupedDailyStats[date] || [
                EmberObject.create({ worked: 0 })
              ]
            ).map((gds) => gds.get('worked'))
          );
          let y = lastY + minutesWorked / 60.0;
          let fillColor =
            y < targets[i].y ? '#FF6D2A' : '#5C99D2';
          acc.push({
            y,
            minutes: minutesWorked,
            marker: {
              fillColor,
              symbol: 'circle'
            }
          });
          return acc;
        }, [])
      }
    ];
  }
}
