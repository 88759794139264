import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
/* global Highcharts */
import Component from '@ember/component';

export default @tagName('') class CircularChart extends Component {
  @computed
  get chartOptions() {
    return {
      chart: {
        type: 'solidgauge',
        width: 190,
        height: 190,
      },

      title: {
        text: null
      },

      pane: {
        center: ['50%', '50%'],
        size: '100%',
        startAngle: 0,

        endAngle: 360,
        background: {
          backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || '#DFEBF6',

          outerRadius: '100%',
          shape: 'circle',
          borderWidth: 0
        }
      },

      tooltip: {
        enabled: false
      },

      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],

        title: {
          y: -70,
          text: undefined
        },

        labels: {
          enabled: false
        }
      },

      credits: {
        enabled: false
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: -25,
            borderWidth: 0,
            useHTML: true,
            format:
              '<div style="text-align:center">' +
              `<span style="font-size:25px">${this.percentage}</span><br/>` +
              '<span style="font-size:12px;opacity:0.4">%</span>' +
              '</div>'
          },

          tooltip: {
            valueSuffix: ' %'
          }
        }
      }
    };
  }

  @computed('percentage')
  get chartData() {
    return [
      {
        data: [
          {
            radius: 86,
            color: '#FFFA',
            innerRadius: 81,
            y: 100
          },
          {
            radius: 90,
            color: '#5C99D2',
            innerRadius: 77,
            y: this.percentage
          },
          {
            radius: 90,
            color: '#3871A5',
            innerRadius: 77,
            y: (this.billable.billable / this.billable.worked) * this.percentage
          }
        ]
      }
    ];
  }
}
