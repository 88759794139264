import DS from 'ember-data';

const { Model, attr } = DS;

export default class FinanceInvoice extends Model {
  @attr('string')
  state;

  @attr('number')
  year;

  @attr('number')
  serial;

  @attr('string')
  number;

  @attr('date')
  creationDate;

  @attr('string')
  creationPlace;

  @attr('date')
  saleDate;

  @attr('number')
  netValue;

  @attr('number')
  netValuePln;
}
