import { underscore } from '@ember/string';
import { pluralize } from 'ember-inflector';
import JSONAPISerializer from '@ember-data/serializer/json-api';

export default class ApplicationSerializer extends JSONAPISerializer {
  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(rel) {
    return underscore(rel);
  }

  payloadKeyFromModelName(modelName) {
    return pluralize(underscore(modelName));
  }

  serializeAttribute(snapshot, json, key, attribute) {
    if (!attribute.options.readOnly) {
      super.serializeAttribute(...arguments);
    }
  }

  serializeBelongsTo(snapshot, json, relationship) {
    if (!relationship.options.readOnly) {
      super.serializeBelongsTo(...arguments);
    }
  }
}
