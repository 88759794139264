import DS from 'ember-data';
const { Model, attr, belongsTo } = DS;

export default class ScheduleBookingAvailability extends Model {
  @belongsTo()
  contractor;

  @attr('date')
  dayOn;

  @attr('boolean')
  notATarget;

  @attr('number')
  hoursTarget;
}
