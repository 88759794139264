import DS from 'ember-data';

const { Model, attr } = DS;

export default class ScheduleTimeEntryImport extends Model {

  @attr('number', { readOnly: true })
  minutesImported;

  @attr({ readOnly: true })
  timeEntries;

  @attr('number')
  happix;

  @attr('string')
  timeEntriesRaw;
}
