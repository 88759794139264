import DS from 'ember-data';

const {
  Model,
  attr,
  belongsTo
} = DS;

export default class FinanceCustomerCashOutflow extends Model {
  @attr('number')
  directLaborCost;

  @attr('number')
  overheadCost;

  @attr('number')
  billableHours;

  @belongsTo()
  customer;

  @belongsTo('payroll-period')
  period;
}
