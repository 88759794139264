import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';
import RSVP from 'rsvp';

export default class FinanceRoute extends AuthenticatedRoute {
  model() {
    let { team_id: teamId } = this.paramsFor('teams.team');

    return RSVP.hash({
      periods: this.store.query('payroll-period', {
        filter: {
          last_years: true
        }
      }),

      cashFlow: this.store.findRecord('team', teamId, {
        include: [
          'client_and_team_leads.customer.cash_inflows.period',
          'client_and_team_leads.customer.cash_outflows.period',
        ].join(','),

        reload: true })
    });
  }
}
