import { alias } from '@ember/object/computed';
import DS from 'ember-data';

const {
  Model,
  attr,
  belongsTo
} = DS;

export default class Achievement extends Model {
  @belongsTo('period')
  period;

  @belongsTo('level')
  level;

  @belongsTo('project-stat')
  projectStat;

  @belongsTo('achievement-category')
  category;

  @attr('number')
  workload;

  @attr('string')
  link;

  @attr('string')
  title;

  @attr('string')
  description;

  @attr('string')
  status;

  @attr('date')
  createdAt;

  @alias('level.iconPath')
  icon;
}
