import { computed } from '@ember/object';
import DS from 'ember-data';
import { belongsTo } from 'ember-data/relationships';
import { inPercentage } from 'selleo-dashboard/helpers/in-percentage';

const { Model, attr } = DS;

export default class ScheduleContractorPastUtilization extends Model {
  @belongsTo('contractor')
  contractor;

  @attr('number')
  workedMinutes;

  @attr('number')
  paidMinutes;

  @attr('number')
  month;

  @attr('number')
  year;

  @computed('workedMinutes,paidMinutes')
  get percentage() {
    return inPercentage([this.paidMinutes, this.workedMinutes]);
  }
}
