import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';

export default AuthenticatedRoute.extend({
  queryParams: {
    time: {
      refreshModel: true
    }
  },

  model({ time }) {
    return this.store.query('payroll-hourly-rate-stat', { filter: { scope: 'all', time } });
  }
});
