import { computed } from '@ember/object';
import { filter } from '@ember/object/computed';
import Component from '@ember/component';
import { sampleSize } from 'lodash';

export default class TeamsGrid extends Component {
  @computed('orphans.[]')
  get orphansSample() {
    return sampleSize(this.orphans.toArray(), 11);
  }

  @filter('teams', function(team) {
    return team.members.length > 0;
  })
  filteredTeams;
}
