import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { difference, filter, sortBy } from 'lodash';

export default class UserList extends Component {
  @service
  session;

  @service
  store;

  @service('-routing')
  routing;

  @service
  paperToaster;

  @computed
  get kudoOmniFieldConfig() {
    let contractors = this.contractors.map((contractor) =>
      contractor.getProperties('id', 'nickname', 'imageUrl'),
    );
    return [
      {
        token: '@',
        searchProperty: 'nickname',
        values: contractors
      },
      {
        token: '#',
        values: ['ember', 'react', 'angular'],
      },
    ];
  }

  @action
  goTo(route) {
    this.routing.transitionTo(route);
  }

  @action
  addKudoFromOmniField() {
    let value, comment;
    let contractors = [];
    let tags = [];
    let commentWords = [];
    let { omniField } = this;
    let words = omniField.split(' ');
    for (let word of words) {
      let contractorMatch = /@\b(\S+)\b/.exec(word);
      if (contractorMatch) {
        contractors = [
          ...contractors,
          this.contractors.filter(
            (c) => c.get('nickname') === contractorMatch[1],
          )[0]
        ];
        continue;
      }

      let tagMatch = /#\b(\S+)\b/.exec(word);
      if (tagMatch) {
        tags = [...tags, tagMatch[1]];
        continue;
      }

      let valueMatch = /\b([1-5])\b/.exec(word);
      if (!value && valueMatch) {
        value = valueMatch[1];
        continue;
      }

      commentWords = [...commentWords, word];
    }

    value = value || 1;
    if (!contractors.length) {
      this.paperToaster.show('You must enter receiver!');
      return;
    }

    comment = commentWords.join(' ');
    for (let receiver of contractors) {
      this.store
        .createRecord('merit-money-kudo', { tags, value, receiver, comment })
        .save()
        .then(() => {
          this.set('omniField', '');
          this.paperToaster.show(
            `Added ${value}* kudo for ${receiver.get(
              'name',
            )} with comment '${comment}'`,
          );
        });
    }
  }

  @computed('contractors,byTeams')
  get teamWithContractors() {
    let fetchContractors = (ids) => (
      this.contractors.filter((c) => ids.includes(c.get('id')))
    );

    return this.byTeams.map((team) => (
      {
        name: team.name,
        contractors: [...fetchContractors([team.leader]), ...fetchContractors(team.members)]
      }
    ));
  }

  @computed('teams')
  get filteredTeams() {
    return filter(this.teams.toArray(), (team) => team.get('members').length > 0);
  }

  @computed('filteredTeams')
  get byTeams() {
    let currentUserId = this.session.get('currentUser.id');
    let allContractorIds = this.store.peekAll('contractor').map((c) => c.get('id'));
    let alreadyInTeamIds = [];

    return sortBy([...this.filteredTeams.map((team) => {
      let memberIds = team.get('members').map((t) => t.get('contractor.id'));
      let priority = 100 - memberIds.length;
      alreadyInTeamIds.push(...memberIds);
      if (memberIds.includes(currentUserId)) {
        priority = -1;
      }

      return {
        priority,
        name: team.name,
        leader: team.get('leader.contractor.id'),
        members: team.get('membersWithoutLeader').map((m) => m.get('contractor.id'))
      };
    }),
    {
      priority: (difference(allContractorIds, alreadyInTeamIds).includes(currentUserId) ? -1 : 100 - difference(allContractorIds, alreadyInTeamIds).length),
      name: 'Servant Leadership',
      leader: '19',
      members: difference(allContractorIds, [...alreadyInTeamIds, '19'])
    }], 'priority');
  }
}
