import DS from 'ember-data';
const { Model, attr, belongsTo } = DS;

export default class ScheduleBookingException extends Model {
  @belongsTo()
  contractor;

  @attr('dateRange')
  period;

  @attr('string')
  comment;

  @attr('boolean')
  osheeRelaxing;
}
