import { computed } from '@ember/object';
import DS from 'ember-data';

const {
  Model,
  attr,
  belongsTo
} = DS;

export default class FinanceCustomerWeeklyStatsWorkload extends Model {
  @attr('number')
  minutesWorked;

  @attr('number')
  minutesPaid;

  @attr('number')
  directLaborCost;

  @attr('number')
  overheadCost;

  @belongsTo('customer')
  customer;

  @belongsTo('contractor')
  contractor;

  @attr('number')
  year;

  @attr('number')
  week;

  className = 'workload';

  @computed('week', 'year')
  get sortOrder() {
    return `${this.year}W${this.week.toString().padStart(2, '0')}`;
  }
}
