import DS from 'ember-data';

const {
  Model,
  attr
} = DS;

export default Model.extend({
  label: attr('string'),
  scope: attr('string'),
  year: attr('number'),
  quarter: attr('number'),
  avgBase: attr('number'),
  avgEffective: attr('number'),
  medianBase: attr('number'),
  medianEffective: attr('number'),
});
