import DS from 'ember-data';
import moment from 'moment';

const { Transform } = DS;

const RANGE_REGEXP = /^\[(.+),(.+)\]$/;

export default class DateRange extends Transform {
  deserialize(serialized) {
    if (!serialized) {
      return {};
    }

    let [, start, end] = serialized.match(RANGE_REGEXP);

    return {
      start,
      end,
    };
  }

  serialize(deserialized) {
    return `[${moment(deserialized.start).format('YYYY-MM-DD')},${moment(
      deserialized.end,
    ).format('YYYY-MM-DD')}]`;
  }
}
