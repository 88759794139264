import { helper } from '@ember/component/helper';
import moment from 'moment';

export function dateFormatter(params) {
  if (!params.includes(undefined)) {
    return moment(params[0]).format('ll');
  }
}

export default helper(dateFormatter);
