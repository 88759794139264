import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default class CapturePaste extends Component {
  @service
  clipboard;

  didInsertElement() {
    super.didInsertElement(...arguments);
    this.pasteEventWithBind = this.pasteEvent.bind(this);

    document.addEventListener('paste', this.pasteEventWithBind);
  }

  willDestroyElement() {
    document.removeEventListener('paste', this.pasteEventWithBind);

    super.willDestroyElement(...arguments);
  }

  pasteEvent(event) {
    this.clipboard.set('clipboardText', event.clipboardData.getData('text'));

    if (this.handlePaste && this.handlePaste(event)) {
      event.preventDefault();
    }
  }
}
