import DS from 'ember-data';

const { Model, attr, hasMany } = DS;

export default class CustomerAccountMeta extends Model {
  @attr
  name;

  @hasMany('schedule-booking-access-token')
  bookingTokens;

  @hasMany('customer-account-meta-customer')
  metaCustomers;
}
