import DS from 'ember-data';

const {
  Model,
  attr,
  belongsTo
} = DS;

export default class MeritMoneyWeeklyKudosReceived extends Model {
  @attr('number')
  kudosReceived;

  @belongsTo('merit-money-week')
  week;

  @belongsTo('contractor')
  receiver;

  @attr('string')
  sortOrder;
}
