import DS from 'ember-data';
import { belongsTo } from 'ember-data/relationships';

const { Model, attr } = DS;

export default class ScheduleTimeEntriesStat extends Model {
  @belongsTo('work-unit')
  parent;

  @belongsTo()
  workUnit;

  @attr('number')
  workedMinutesInclusive;

  @attr('number')
  workedMinutesExclusive;

  @attr('number')
  paidMinutesInclusive;

  @attr('number')
  paidMinutesExclusive;

  @attr('number')
  osheeMinutesInclusive;

  @attr('number')
  osheeMinutesExclusive;
}
