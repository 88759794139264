import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import Auth0BaseAuthenticator from './auth0-base';

export default class Auth0Lock extends Auth0BaseAuthenticator {
  @service
  auth0;

  authenticate(options) {
    return get(this, 'auth0').showLock(options);
  }
}
