import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';

export default Component.extend({
  classNames: ['cell-contractor-header', 'flex', 'layout-row', 'layout-align-start-center'],

  selectedTechnologies: alias('cell.filters.selectedTechnologies'),
  technologyIds: alias('cell.contractor.preferredCompetences'),

  firstTech: computed('selectedTechnologies', 'technologyIds', function() {
    if (this.selectedTechnologies[0]
      && this.technologyIds.includes(this.selectedTechnologies[0].get('id'))) {
      return this.selectedTechnologies[0];
    }
  }),

  secondTech: computed('selectedTechnologies', 'technologyIds', function() {
    if (this.selectedTechnologies[1]
      && this.technologyIds.includes(this.selectedTechnologies[1].get('id'))) {
      return this.selectedTechnologies[1];
    }
  }),

  thirdTech: computed('selectedTechnologies', 'technologyIds', function() {
    if (this.selectedTechnologies[2]
      && this.technologyIds.includes(this.selectedTechnologies[2].get('id'))) {
      return this.selectedTechnologies[2];
    }
  })
});
