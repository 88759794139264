import DS from 'ember-data';

const {
  Model,
  attr,
  belongsTo
} = DS;

export default class MeritMoneyKudo extends Model {
  @attr('number')
  value;

  @attr('string')
  comment;

  @attr()
  tags;

  @attr('string', { readOnly: true })
  state;

  @belongsTo('merit-money-week', { readOnly: true })
  week;

  @belongsTo('contractor', { inverse: 'kudosGiven', readOnly: true })
  giver;

  @belongsTo('contractor', { inverse: 'kudosReceived' })
  receiver;
}
