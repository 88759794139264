import { action } from '@ember/object';
import { oneWay } from '@ember/object/computed';
import Component from '@ember/component';
import { debounce } from '@ember/runloop';

export default class NameFilter extends Component {
  @oneWay('value')
  inputValue;

  delay = 250;

  @action
  updateValue(newInputValue) {
    debounce(this, 'update', newInputValue, this.delay);
  }
}
