import { computed } from '@ember/object';
import DS from 'ember-data';
import { compact } from 'lodash';

const { Model, attr } = DS;

export default class WorkUnit extends Model {
  @attr()
  name;

  @attr()
  wuid;

  @computed('name', 'wuid')
  get label() {
    return compact([this.name, this.wuid]).join(' - ');
  }
}
