import { computed } from '@ember/object';
import Component from '@ember/component';
import { find, groupBy, sum, uniqBy } from 'lodash';
import moment from 'moment';

export default class TeamVelocity extends Component {
  @computed('categories.[]')
  get chartOptions() {
    return {
      chart: {
        height: 800,
        type: 'area'
      },

      title: {
        text: 'Velocity'
      },

      xAxis: {
        categories: this.categories
      },

      yAxis: {
        min: 0,
        title: {
          text: 'Hours'
        },

        labels: {
          formatter() {
            return `${Math.floor(this.value / 60)}${
              this.value % 60 > 0 ? `:${this.value % 60}` : ''
            }h`;
          }
        },

        stackLabels: {
          enabled: true,
          formatter() {
            return `${Math.floor(this.total / 60)}${
              this.value % 60 > 0 ? `:${this.total % 60}` : ''
            }h`;
          },

          verticalAlign: 'top',
          y: -10,
          style: {
            color: 'black'
          }
        }
      },

      tooltip: {
        formatter() {
          return this.points
            .filter((point) => point.y > 0)
            .map(
              (point) =>
                `<span style="color: ${point.color}">${
                  point.series.name
                }</span>: <b>${Math.floor(point.y / 60)}${
                  point.y % 60 > 0 ? `:${point.y % 60}` : ''
                }h</b>`
            )
            .join('<br /><br />');
        },

        shared: true
      },

      plotOptions: {
        area: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          }
        }
      }
    };
  }

  @computed('model.weeklyStats')
  get weeklyDataSeries() {
    return groupBy(this.model.weeklyStats.toArray(), function(data) {
      let customerName = data.get('customer.name');
      let contractorName = data.get('contractor.name');
      return `${customerName} - ${contractorName}`;
    });
  }

  @computed('model.monthlyStats')
  get monthlyDataSeries() {
    return groupBy(this.model.monthlyStats.toArray(), function(data) {
      let customerName = data.get('customer.name');
      let contractorName = data.get('contractor.name');
      return `${customerName} - ${contractorName}`;
    });
  }

  @computed('model.weeklyStats')
  get weeks() {
    return uniqBy(
      this.model.weeklyStats.map(({ year, week }) => [year, week]),
      ([year, week]) => `${year}-${week}`
    );
  }

  @computed('model.monthlyStats')
  get months() {
    return uniqBy(
      this.model.monthlyStats.map(({ year, month }) => [year, month]),
      ([year, month]) => `${year}-${month}`
    );
  }

  @computed('months')
  get quarters() {
    return uniqBy(
      this.months.map(([year, month]) => [year, this._whichQuarter(month)]),
      ([year, quarter]) => `${year}-${quarter}`
    );
  }

  @computed('weeks', 'months', 'quarters', 'scope')
  get categories() {
    if (this.scope === 'weekly') {
      return this.weeks.map(([year, week]) =>
        moment()
          .year(year)
          .week(week)
          .day(1)
          .format('DD MMM')
      );
    } else if (this.scope === 'monthly') {
      return this.months.map(([year, month]) =>
        moment()
          .year(year)
          .month((month + 11) % 12)
          .format("MMM 'YY")
      );
    } else {
      return this.quarters.map(
        ([year, quarter]) => `${quarter} '${year % 100}`
      );
    }
  }

  @computed('weeklyDataSeries', 'monthlyDataSeries', 'scope')
  get chartData() {
    return Object.keys(
      this.scope === 'weekly' ? this.weeklyDataSeries : this.monthlyDataSeries
    ).map((category) => {
      let data;
      if (this.scope === 'weekly') {
        data = this.weeks.map(([year, week]) => {
          return (
            find(
              this.weeklyDataSeries[category],
              (weeklyStat) =>
                weeklyStat.year === year && weeklyStat.week === week
            ) || { minutes: 0 }
          ).minutes;
        });
      } else if (this.scope === 'monthly') {
        data = this.months.map(([year, month]) => {
          return (
            find(
              this.monthlyDataSeries[category],
              (monthlyStat) =>
                monthlyStat.year === year && monthlyStat.month ===  month
            ) || { minutes: 0 }
          ).minutes;
        });
      } else if (this.scope === 'quarterly') {
        let monthRanges = {
          I: [1, 3],
          II: [4, 6],
          III: [7, 9],
          IV: [10, 12]
        };
        data = this.quarters.map(([year, quarter]) => {
          let quarterlyData = this.monthlyDataSeries[category].filter(
            (monthlyStat) =>
              monthlyStat.year === year &&
              monthlyStat.month <= monthRanges[quarter][1] &&
              monthlyStat.month >= monthRanges[quarter][0]
          );
          if (!quarterlyData.length) {
            quarterlyData.push({ minutes: 0 });
          }

          return sum(quarterlyData.map((quarterData) => quarterData.minutes));
        });
      }

      return {
        name: category,
        data
      };
    });
  }

  _whichQuarter(month) {
    let quarter = null;
    if (month <= 3) {
      quarter = 'I';
    }

    if (month >= 4 && month <= 6) {
      quarter = 'II';
    }

    if (month >= 7 && month <= 9) {
      quarter = 'III';
    }

    if (month >= 10 && month <= 12) {
      quarter = 'IV';
    }

    return quarter;
  }
}
