import DS from 'ember-data';
const { Model, attr } = DS;
export default class ScheduleMonthlyStatsBillableHour extends Model {
  @attr('string')
  month;

  @attr('string')
  year;

  @attr('number')
  contractors;

  @attr('string')
  teamType;

  @attr('number')
  hoursNonBillable;

  @attr('number')
  hoursBillable;
}
