import { computed } from '@ember/object';
import Component from '@ember/component';
import moment from 'moment';

export default class LastMonth extends Component {
  @computed('settlement.{year.month}')
  get date() {
    return moment(`${this.settlement.year}-${this.settlement.month}`, 'YYYY-MM').format('MMMM \'YY');
  }

  @computed('settlement.{paidAtHand,paidVat}')
  get totalTransfer() {
    return this.settlement.paidAtHand + this.settlement.paidVat;
  }
}
