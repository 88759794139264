import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';
import { hash } from 'rsvp';

export default class CustomerRoute extends AuthenticatedRoute {
  model(params) {
    return hash({
      customer: this.store.findRecord('customer', params.customer_id, { include: 'balance' }),
      financeStats: this.store.query('finance-customer-weekly-stats-booking', { filter: params, include: 'invoice' }),
      workloadStats: this.store.query('finance-customer-weekly-stats-workload', { filter: params, include: 'contractor' }),
      balanceWeeklies: this.store.query('finance-customer-weekly-stats-balance', { filter: params })
    });
  }
}
