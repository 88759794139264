import DS from 'ember-data';
import { belongsTo } from 'ember-data/relationships';

const { Model, attr } = DS;

export default class CssSurvey extends Model {
  @belongsTo('contractor')
  customer;

  @belongsTo('contractor')
  team;

  @attr('date')
  resultsCollectedOn;

  @attr('string')
  comment;

  @attr('number')
  year;

  @attr('number')
  quarter;

  @attr('number')
  nps;

  @attr('number')
  qaSoftware;

  @attr('number')
  qaService;

  @attr('number')
  qaDevelopment;

  @attr('number')
  average;

  @attr('number')
  numberOfSurveys;
}
