import { alias } from '@ember/object/computed';
import BaseComponent from 'selleo-dashboard/components/finance/effective-hours/base-component/component';

export default class Semesterly extends BaseComponent {
  @alias('effectiveHoursPeriod')
  categories;

  aggregateByKey(year, month) {
    return `${month <= 6 ? 'I' : 'II'} '${year % 100}`;
  }
}

