import { computed } from '@ember/object';
import { map } from '@ember/object/computed';
import Component from '@ember/component';

export default class TeamTree extends Component {
  @computed
  get chartOptions() {
    return {
      title: { text: null },
      chart: {
        height: '60%'
      },

      tooltip: {
        valueDecimals: 0
      }
    };
  }

  @computed
  get chartData() {
    return [{
      type: 'treemap',
      layoutAlgorithm: 'stripes',
      alternateStartingDirection: true,
      levels: [{
        level: 1,
        layoutAlgorithm: 'sliceAndDice',
        dataLabels: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          style: {
            fontSize: '15px',
            fontFamily: 'Roboto'
          }
        }
      }],

      data: this.coloredWorkloadStats
    }];
  }

  @map('workloadStats', function (stat, index) {
    stat.color = this.colors[index];
    return stat;
  })
  coloredWorkloadStats;

  @computed('model.workloadStats')
  get workloadStats() {
    let stats = this.model.workloadStats.reduce((acc, item) => {
      let key = item.get('contractor.id');
      if (acc[key]) {
        acc[key].value += item.minutesPaid / 60.0;
      } else {
        acc[key] = {
          value: item.minutesPaid / 60.0,
          name: item.contractor.get('name')
        };
      }

      return acc;
    }, {});
    return Object.values(stats);
  }

  @computed('workloadStats.length')
  get colors() {
    return this._createSpectrum(this.workloadStats.length);
  }

  _hslToHex(_h, s, l) {
    let h = Math.min(_h + 30, 359) / 60;

    let c = (1 - Math.abs((2 * l) - 1)) * s;
    let x = c * (1 - Math.abs((h % 2) - 1));
    let m = l - (0.5 * c);

    let [r, g, b] = [m, m, m];

    if (h < 1) {
      r += c, g = +x, b += 0;
    } else if (h < 2) {
      r += x, g += c, b += 0;
    } else if (h < 3) {
      r += 0, g += c, b += x;
    } else if (h < 4) {
      r += 0, g += x, b += c;
    } else if (h < 5) {
      r += x, g += 0, b += c;
    } else if (h < 6) {
      r += c, g += 0, b += x;
    } else {
      r = 0, g = 0, b = 0;
    }

    return `#${Math.round(r * 255).toString(16)}${Math.round(g * 255).toString(16)}${Math.round(b * 255).toString(16)}`;
  }

  _createSpectrum(length) {
    let colors = [];
    let step = 360 / length;
    for (let i = 1; i <= length; i++) {
      let color = this._hslToHex((i) * step, 0.5, 0.5);
      colors.push(color);
    }

    return colors;
  }
}
