import { computed } from '@ember/object';
import { lte } from '@ember/object/computed';
import PaperProgressCircular from 'ember-paper/components/paper-progress-circular';

export default class ProgressCircular extends PaperProgressCircular {
  @lte('value', 0)
  valueBelowOrEqualZero;

  @computed('valueBelowOrEqualZero', 'hideProgress')
  get visibility() {
    return this.hideProgress || this.valueBelowOrEqualZero ? 'hidden' : 'visible';
  }
}
