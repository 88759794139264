import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import KudoValidations from 'selleo-dashboard/validations/kudo';
import lookupValidator from 'ember-changeset-validations';
import Changeset from 'ember-changeset';
import { task } from 'ember-concurrency';

export default class UserCard extends Component {
  @service
  store;

  @service
  session;

  init() {
    super.init(...arguments);
    this._setupKudo();
  }

  @computed
  get categories() {
    return this.store.findAll('merit-money-category');
  }

  byPosition = Object.freeze(['position:asc']);

  @sort('categories', 'byPosition')
  sortedCategories;

  @computed('changeset.tags')
  get firstTag() {
    return this.changeset.get('tags').length > 0 ? this.changeset.get('tags')[0] : '';
  }

  @(task(function* (categoryTag) {
    let { changeset } = this;
    changeset.set('tags', [categoryTag]);
    changeset.set('giver', this.session.currentUser);
    changeset.set('state', 'draft');
    changeset.set('receiver', this.contractor);
    yield changeset.save();
    this._setupKudo();
  }).drop())
  addKudo;

  _setupKudo() {
    this._setupChangeset(this._findLastUnsavedOrCreateNewKudo());
  }

  _findLastUnsavedOrCreateNewKudo() {
    let { store } = this;
    let [model] = store.peekAll('merit-money-kudo').filterBy('isNew', true);

    if (model && model.get('receiver.id') === this.get('contractor.id')) {
      return model;
    } else {
      return store.createRecord('merit-money-kudo', { tags: [] });
    }
  }

  _setupChangeset(kudo) {
    this.set('changeset', new Changeset(kudo, lookupValidator(KudoValidations), KudoValidations));
    if (kudo.isNew) {
      this.changeset.set('value', 1);
    }

    this.changeset.set('state', 'draft');
    this.changeset.validate();
  }

  @computed('contractor', 'session.currentUser')
  get contractorIsCurrentUser() {
    return this.contractor.id === this.session.get('currentUser.id');
  }

  @computed('changeset.value')
  get formStarsIter() {
    let stars = new Array(5);
    let starsCount = this.get('changeset.value') - 1;
    for (let i = 0; i < 5; i++) {
      stars[i] = i <= starsCount;
    }

    return stars;
  }

  @action
  setFormStars(count) {
    this.set('changeset.value', count + 1);
  }

  @action
  addTag(tag) {
    this.get('changeset.tags').pushObject(tag);
    tag = '';
  }

  @action
  removeTag(tag) {
    this.get('changeset.tags').popObject(tag);
  }

  @action
  editKudo(kudo) {
    this._setupChangeset(kudo);
  }

  @action
  deleteKudo(kudo) {
    kudo.destroyRecord().then(() => {
      this.store.findRecord('contractor', this.contractor.id, { reload: true });
    });
  }

  @action
  cancelEdit() {
    this._setupKudo();
  }
}
