import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';

export default class CtlsRoute extends AuthenticatedRoute {
  model() {
    return this.store.query('contractor', {
      filter: { is_team_leader: true },
      include: 'customers,customers.contractors'
    });
  }
}
