import { inject as service } from '@ember/service';
import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';
import RSVP from 'rsvp';
import moment from 'moment';

const { hash } = RSVP;

export default class PersonalRoute extends AuthenticatedRoute {
  @service
  session;

  model() {
    let currentUserId = this.get('session.data.contractor_id');
    return hash({
      dailyReports: this.store.query('daily-time-stat', {
        include: 'customer,contractor',
        filter: {
          'contractor_id': currentUserId || 0
        }
      }),

      bookingAvailabilities: this.store.query('schedule-booking-availability', {
        include: 'contractor',
        filter: {
          'contractor_id': currentUserId || 0
        }
      }),

      remainingBookingException: this.store.findRecord('schedule-remaining-booking-exception', `${moment().year()}-${currentUserId}` || 0),
      osheeYearlyStat: this.store.findRecord('schedule-oshee-yearly-stat', `${moment().year()}-${currentUserId}`),
      recentDelayed: this.store.findRecord('schedule-recent-delayed', 0),
      remainingOsheeRelaxing: this.store.findRecord('schedule-remaining-oshee-relaxing', `${currentUserId}-${moment().year()}`),
      currentUser: this.get('session.currentUser')
    });
  }
}
