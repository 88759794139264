import { helper } from '@ember/component/helper';
import moment from 'moment';

const sortInTheRow = (row) => {
  row.events.sort((a, b) => a.get('date') > b.get('date'));
  return row;
};

const nextWeek = (previousDate, currentDate) => {
  let previousDay = previousDate.isoWeekday();
  let currentDay = currentDate.isoWeekday();
  let diff = previousDate.diff(currentDate);
  let daysDiff = Math.floor(moment.duration(diff, 'milliseconds').asDays());
  let firstRowDay = currentDate.isoWeekday() === 7;
  let firstRowDayOfNextWeek = firstRowDay && daysDiff > 1;
  let sameweek = daysDiff <= 7 && currentDay <= previousDay;
  return !sameweek || firstRowDayOfNextWeek;
};

export function weekSplitter([events]) {

  let currentRow = {
    events: [],
    start: '',
    end: '',
  };
  let rows = [];
  let previousEventDate = null;

  let setupNewRow = () => {
    currentRow = {
      events: [],
      start: '',
      end: '',
    };
  };

  let saveCurrentRow = (row, date) => {
    currentRow.start = date.startOf('isoWeek').format('MM/DD/YYYY');
    currentRow.end = date.endOf('isoWeek').format('MM/DD/YYYY');
    let sortedRow = sortInTheRow(row);
    rows.pushObject(sortedRow);
  };

  events.map((ev, index) => {
    let currentDate = moment(ev.get('date'));

    if (index === events.length - 1) {
      if (previousEventDate) {
        if (nextWeek(previousEventDate, currentDate)) {
          saveCurrentRow(currentRow, previousEventDate);
          setupNewRow();
          currentRow.events.pushObject(ev);
          saveCurrentRow(currentRow, currentDate);
          return;
        } else {
          currentRow.events.pushObject(ev);
          saveCurrentRow(currentRow, currentDate);
          return;
        }
      }
    }

    if (previousEventDate) {
      if (nextWeek(previousEventDate, currentDate)) {
        saveCurrentRow(currentRow, previousEventDate);
        setupNewRow();
      }
    }

    currentRow.events.pushObject(ev);
    previousEventDate = currentDate;
  });
  return rows;
}

export default helper(weekSplitter);
