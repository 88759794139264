import DS from 'ember-data';

const {
  Model,
  belongsTo
} = DS;

export default class ClientAndTeamLead extends Model {
  @belongsTo('customer')
  customer;

  @belongsTo()
  team;
}
