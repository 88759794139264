import { computed } from '@ember/object';
import Component from '@ember/component';
import { sortBy } from 'lodash';

export default class KudosReceived extends Component {
  header = 'Kudos Received';

  @computed('kudos.@each.{week}')
  get byWeek() {
    let weeks = [];
    let kudosByWeek = this.kudos.reduce((acc, kudo) => {
      let key = kudo.week.get('id');
      if (key) {
        if (!acc[key]) {
          acc[key] = {
            header: kudo.week.get('monday').format('Do MMM'),
            kudos: [],
          };
          weeks.push(kudo.week);
        }

        acc[key].kudos.push(kudo);
      }

      return acc;
    }, {});

    return sortBy(weeks, (w) => w.get('sortOrder')).reverse().map((week) => kudosByWeek[week.get('id')]);
  }
}
