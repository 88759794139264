import Component from '@ember/component';
import { computed } from '@ember/object';
import { mapBy, sort } from '@ember/object/computed';

export default Component.extend({
  chartOptions: computed('categories.[]', function() {
    return {
      chart: {
        type: 'line',
        zoomType: 'x'
      },

      exporting: {
        chartOptions: {
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true
              }
            }
          }
        },

        enabled: true,
        fallbackToExportServer: false
      },

      title: {
        text: 'Hourly Rates'
      },

      subtitle: {
        text: 'Base Rate & Effective Rate'
      },

      xAxis: {
        categories: this.categories
      },

      yAxis: {
        title: {
          text: '(zł/h)'
        }
      },

      tooltip: {
        shared: true
      },

      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      }
    };
  }),

  sortedModel: sort('model', function(a, b) {
    if (this.scope === 'yearly') {
      return parseInt(a.get('label').substring(1)) - parseInt(b.get('label').substring(1));
    } else {
      let aYear = parseInt(a.get('label').substring(-2));
      let aQuarter = parseInt(a.get('label').substring(1, 2));

      let bYear = parseInt(b.get('label').substring(-2));
      let bQuarter = parseInt(b.get('label').substring(1, 2));

      return (aYear * 10 + aQuarter) - (bYear * 10 - bQuarter);
    }
  }),

  categories: mapBy('sortedModel', 'label'),
  avgBase: mapBy('sortedModel', 'avgBase'),
  avgEffective: mapBy('sortedModel', 'avgEffective'),
  medianBase: mapBy('sortedModel', 'medianBase'),
  medianEffective: mapBy('sortedModel', 'medianEffective'),
  chartData: computed('avgBase', function() {
    return [{
      name: 'Base (average)',
      data: this.avgBase,
      color: 'rgb(124,181,236)'
    }, {
      name: 'Effective (average)',
      data: this.avgEffective,
      color: 'black'
    }, {
      name: 'Base (median)',
      data: this.medianBase,
      color: 'rgb(124,181,236)',
      dashStyle: 'dot'
    }, {
      name: 'Effective (median)',
      data: this.medianEffective,
      color: 'black',
      dashStyle: 'dot'
    }];
  }),
});
