import { attributeBindings, tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

export default

@tagName('svg')
@attributeBindings('svgStyle:style', 'viewBox:viewBox', 'visibility:visibility')
class Svg extends Component {
  @computed('diameter')
  get viewBox() {
    return `0 0 ${this.diameter} ${this.diameter}`;
  }
}
