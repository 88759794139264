import { computed } from '@ember/object';
import DS from 'ember-data';

const { Model, attr, belongsTo } = DS;

export default class FinanceCustomerBalance extends Model {
  @attr('number')
  hoursTotal;

  @attr('number')
  hoursPayable;

  @attr('number')
  hoursBooked;

  //
  // revenue: attr(),
  // unconsumedRevenue: attr(),
  //
  // labourCost: attr(),
  // overheadCost: attr(),
  //
  // profit: attr(),
  //
  // expectedRevenue: attr(),
  // expectedProfit: attr(),
  //
  @belongsTo('customer')
  customer;

  //
  @computed('hoursPayable', 'hoursBooked')
  get balance() {
    return this.hoursBooked - this.hoursPayable;
  }
  // revenuePerHour: computed('revenue', 'hoursTotal', function() {
  //   return (this.get('revenue') / this.get('hoursTotal')).toFixed(2);
  // }),
  // revenuePerWeek: computed('revenue', 'hoursTotal', function() {
  //   return ((this.get('revenue') / this.get('hoursTotal')) * 40).toFixed(2);
  // }),
  // profitPerHour: computed('profit', 'hoursTotal', function() {
  //   return (this.get('profit') / this.get('hoursTotal')).toFixed(2);
  // }),
  // profitPerWeek: computed('profit', 'hoursTotal', function() {
  //   return ((this.get('profit') / this.get('hoursTotal')) * 40).toFixed(2);
  // }),
  // expectedProfitPerHour: computed('expectedProfit', 'hoursBooked', function() {
  //   return (this.get('expectedProfit') / this.get('hoursBooked')).toFixed(2);
  // })
}
