import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { round } from 'lodash';

export default

@classNames('flex-100', 'remaining-oshee')
class RemainingOshee extends Component {
  title = 'Oshee';

  limitMinutes = 240 * 60;

  @alias('osheeYearlyStat.osheeMinutes')
  learnedMinutes;

  @computed('linkUrl')
  get href() {
    return this.linkUrl();
  }

  @computed('learnedMinutes')
  get learnedHours() {
    return round(this.learnedMinutes / 60.0, 1);
  }

  @computed('limitMinutes')
  get limitHours() {
    return round(this.limitMinutes / 60.0, 0);
  }

  @computed('learnedMinuts,limitMinutes')
  get leftMinutes() {
    return round(this.limitMinutes - this.learnedMinutes, 0);
  }

  @computed('learnedHours,limitHours')
  get leftHours() {
    return round(this.limitHours - this.learnedHours, 1);
  }
}
