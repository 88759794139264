import { computed } from '@ember/object';
import DS from 'ember-data';

const {
  Model,
  attr,
  belongsTo
} = DS;

export default class FinanceCustomerWeeklyStatsBooking extends Model {
  @belongsTo('finance-invoice')
  invoice;

  @attr('number')
  hoursBooked;

  @attr('number')
  year;

  @attr('number')
  week;

  @belongsTo('customer')
  customer;

  className = 'finance';

  @computed('week', 'year')
  get sortOrder() {
    return `${this.year}W${this.week.toString().padStart(2, '0')}`;
  }
}
