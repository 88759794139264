import Component from '@glimmer/component';
import { maxBy } from 'lodash';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class FinanceSettlementsComponent extends Component {
  @tracked
  hourRateScope = 'monthly';

  get lastMonth() {
    return maxBy(this.args.settlements.toArray(), (s) => s.get('sortOrder'));
  }

  @action
  setHourRateScope(value) {
    this.hourRateScope = value;
  }
}
