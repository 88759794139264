import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';
import moment from 'moment';

export default class HappixRoute extends AuthenticatedRoute {
  model() {
    // eslint-disable-next-line camelcase
    let { team_id } = this.paramsFor('teams.team');
    return this.store.query('schedule-happiness', {
      include: 'contractor',
      filter: {
        team_id,
        within_period: `${moment()
          .subtract(6, 'weeks')
          .format('YYYY-MM-DD')}..${moment().format('YYYY-MM-DD')}`
      }
    });
  }
}
