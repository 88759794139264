import { computed } from '@ember/object';
import Component from '@ember/component';

export default class HappixChart extends Component {
  @computed
  get avgHappix() {
    let happixArray = this.happixData;
    return happixArray.reduce((sum, value) => sum + value) / happixArray.length;
  }

  @computed
  get chartOptions() {
    return {
      chart: {
        height: 200
      },

      title: {
        text: `${this.chartTitle} avg happix - ${this.avgHappix}`,
      },

      yAxis: {
        title: {
          text: '',
        },
      },

      xAxis: {
        visible: false,
      },

      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
        },
      },

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },

            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    };
  }

  @computed
  get chartData() {
    return [
      {
        name: 'happix',
        data: this.happixData,
      },
    ];
  }
}
