import { inject as service } from '@ember/service';
import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';
import { hash } from 'rsvp';
import moment from 'moment';

export default class TeamRoute extends AuthenticatedRoute {
  @service
  session;

  model() {
    return this.get('session.currentUser').then((user) =>
      user.get('serviceDeliveryMember.team').then((team) => {
        return hash({
          team: this.store.findRecord('team', team.id, {
            include: 'members,members.contractor,members.role',
            reload: true
          }),

          utilizations: this.store.findAll('schedule-contractor-utilization', {
            include: ['contractor']
          }),

          happixes: this.store.query('schedule-happiness', {
            include: 'contractor',
            filter: {
              team_id: team.id,
              within_period: `${moment()
                .subtract(2, 'weeks')
                .format('YYYY-MM-DD')}..${moment().format('YYYY-MM-DD')}`
            }
          }),

          bookingExceptions: this.store.query('schedule-booking-exception', {
            include: 'contractor',
            filter: {
              team_id: team.id
            }
          })
        });
      })
    );
  }
}
