import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { tagName } from '@ember-decorators/component';
import { map, sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { groupBy } from 'lodash';
import moment from 'moment';

export default @tagName('') class Chart extends Component {
  @service
  can;

  @service
  store;

  @service
  session;

  subtitle = '';

  showEditForm = false;

  showNewForm = false;

  byContractorName = Object.freeze(['contractor.name:asc']);

  @sort('bookingExceptions', 'byContractorName')
  bookingExceptionsSorted;

  @computed('groupedDataSeries.[]')
  get options() {
    return {
      title: {
        text: 'Booking Exceptions'
      },

      subtitle: {
        text: this.subtitle
      },

      tooltip: {
        headerFormat: '<span style="font-size: 14px; font-weight: 700">{series.name}</span><br/>',
        pointFormat: '<span style="font-style: italic">{point.comment}</span><br/><span style="font-size: 12px">From: {point.start:%e. %b}</span><br/><span style="font-size: 12px">To: {point.end:%e. %b}</span>'
      },

      xAxis: {
        currentDateIndicator: true
      },

      yAxis: {
        type: 'category',
        grid: {
          columns: [{
            title: {
              text: 'Contractor'
            },

            labels: {
              useHTML: true,
              align: 'left',
              formatter() {
                return `&nbsp;&nbsp;${this.value}`;
              }
            },

            categories: this.groupedDataSeries.map((s) => s.name)
          }]
        }
      },

      plotOptions: {
        series: {
          allowPointSelect: true,
          point: {
            events: {
              select: this._clickCallback()
            }
          }
        }
      }
    };
  }

  @map('bookingExceptionsSorted.@each.{id}', function (bookingException) {
    let label = (bookingException.get('contractor.name') || 'National days off');
    let { id } = bookingException;
    let comment = bookingException.comment || '';
    let periodStart = bookingException.get('period.start');
    let periodEnd = bookingException.get('period.end');

    let start = new Date(periodStart);
    let end = new Date(periodEnd);

    start.setHours(0);
    end.setHours(24);

    return [
      label,
      id,
      comment,
      start.getTime(),
      end.getTime() - 1,
    ];
  })
  chartDataSeries;

  @computed('filteredByContractor', 'filteredByContractorNames', 'chartDataSeries')
  get dataSeries() {
    let { filteredByContractor, filteredByContractorNames, chartDataSeries } = this;
    if (!isEmpty(filteredByContractor)) {
      return filteredByContractor;
    }

    if (!isEmpty(filteredByContractorNames)) {
      return filteredByContractorNames;
    }

    return chartDataSeries;
  }

  @computed('dataSeries')
  get groupedDataSeries() {
    let initial = groupBy(this.dataSeries, (data) => data[0]);
    let grouped = Object.keys(initial).map((key, i) => {
      let data = initial[key].map((stat) => {
        let [, id, comment, start, end] = stat;
        return {
          id,
          comment,
          start,
          end,
          y: i
        };
      });
      return {
        name: key,
        data
      };
    });
    return grouped;
  }

  @computed('results')
  get filteredByContractorNames() {
    if (!isEmpty(this.results)) {
      return this.chartDataSeries.filter(([contractorName]) => this.results.indexOf(contractorName) >= 0);
    }

    return [];
  }

  @computed('selectedContractor')
  get filteredByContractor() {
    let selectedContractorName = this.get('selectedContractor.name');
    return this.chartDataSeries.filter(([contractorName]) => contractorName === selectedContractorName);
  }

  _createTooltip(label, comment, periodStart, periodEnd, bookingExceptionId) {
    let bookingDuration = moment(periodEnd).add(1, 'day').diff(periodStart, 'days');

    return `<div class="timeline-tooltip" booking_exception_id=${bookingExceptionId}>
      <h2> ${label} </h2>
      <p> ${comment} </p>
      <hr>
      <p class="tooltip-period"> ${this._formatPeriod(periodStart)} - ${this._formatPeriod(periodEnd)}</p>
      <p><b>Duration</b> ${bookingDuration} Days</p>
    </div>`;
  }

  _formatPeriod(date) {
    return moment(date).format('ddd, DD MMM');
  }

  _clickCallback() {
    let component = this;

    return function() {
      let bookingException = component.store.peekRecord('schedule-booking-exception', this.id);
      component.set('selectedBookingException', bookingException);

      if (component.can.can('edit schedule-booking-exception', bookingException)) {
        component.toggleProperty('showEditForm');
      }

      return false;
    };
  }

  @action
  selectCurrentContractor() {
    this.set('selectedContractor', this.get('session.currentUser'));
  }

  @action
  hideForm() {
    this.toggleProperty('showEditForm');
  }

  @action
  createNewException() {
    this.toggleProperty('showNewForm');
  }
}
