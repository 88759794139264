import { action } from '@ember/object';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default @tagName('') class MenuButton extends Component {
  @service('-routing')
  routing;

  @action
  goTo(route) {
    this.routing.transitionTo(route);
  }
}
