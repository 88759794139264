import DS from 'ember-data';
import { belongsTo } from 'ember-data/relationships';

const { Model, attr } = DS;

export default class ScheduleHappiness extends Model {
  @belongsTo()
  contractor;

  @attr('date')
  date;

  @attr('number')
  happix;
}
