import { computed } from '@ember/object';
import DS from 'ember-data';
import { hasMany } from 'ember-data/relationships';

const {
  Model,
  attr
} = DS;

export default class Team extends Model {
  @attr('string')
  name;

  @attr('string')
  type;

  @attr('string')
  notes;

  @attr('string')
  code;

  @hasMany('task')
  tasks;

  @hasMany('member')
  members;

  @hasMany('client-and-team-lead')
  clientAndTeamLeads;

  @hasMany('customer')
  activeCustomers;

  @hasMany('schedule-booking-access-token')
  bookingTokens;

  @computed('members.[]')
  get leader() {
    return this.members.find((member) => member.get('role.code') === 'leader');
  }

  @computed('members.[],leader')
  get membersWithoutLeader() {
    return this.members.toArray().removeObject(this.leader);
  }
}
