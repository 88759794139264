import DS from 'ember-data';

const {
  Model,
  attr,
  belongsTo
} = DS;

export default class MeritMoneyMonthlyKudosReceived extends Model {
  @attr('number')
  kudosReceived;

  @belongsTo('payroll-period')
  period;

  @belongsTo('contractor')
  receiver;
}
