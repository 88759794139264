import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import EmberObject, { computed } from '@ember/object';
import { A } from '@ember/array';
import { isEmpty } from '@ember/utils';

export default @tagName('') class ByContractorFilter extends Component {
  @service
  router;

  @computed('filteredByContractor', 'selectedContractor')
  get emptyForSelectedContractor() {
    let { selectedContractor } = this;
    if (selectedContractor) {
      return isEmpty(this.filteredByContractor);
    } else {
      return true;
    }
  }

  @computed('selectedContractor')
  get filteredByContractor() {
    let selectedContractorName = this.get('selectedContractor.name');
    return this.dailyReportsByContractor.filter(({ contractorName }) => contractorName === selectedContractorName);
  }

  @computed('results')
  get filteredByContractorNames() {
    if (!isEmpty(this.results)) {
      return this.dailyReportsByContractor.filter(({ contractorName }) => this.results.indexOf(contractorName) >= 0);
    } else {
      return [];
    }
  }

  @computed('dailyReportsByContractor', 'fitleredByContractorNames', 'results')
  get contractorsDailyReports() {
    let { filteredByContractor, filteredByContractorNames } = this;

    if (!isEmpty(filteredByContractorNames)) {
      return filteredByContractorNames;
    }

    if (!isEmpty(filteredByContractor)) {
      return filteredByContractor;
    }

    return this.dailyReportsByContractor;
  }

  @computed('dailyReports')
  get dailyReportsByContractor() {
    let { dailyReports } = this;
    let reduced = dailyReports.reduce(function(hash, dailyReport) {
      let key = dailyReport.get('contractor.id');

      if (hash[key]) {
        hash[key].dailyReports.pushObject(dailyReport);
      } else {
        hash[key] = EmberObject.create({
          contractorName: dailyReport.get('contractor.name'),
          contractorAvatarUrl: dailyReport.get('contractor.avatarUrl'),
          dailyReports: A([dailyReport]),
        });
      }

      return hash;
    }, {});

    return Object.values(reduced);
  }
}
