import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { sampleSize } from 'lodash';

export default @tagName('') class Element extends Component {
  @computed('team.membersWithoutLeader.[]')
  get memberSamples() {
    return sampleSize(this.team.membersWithoutLeader, 18);
  }
}
