import { tagName } from '@ember-decorators/component';
import { map } from '@ember/object/computed';
import Component from '@ember/component';
import EmberObject, { computed } from '@ember/object';
import { round } from 'lodash';
import moment from 'moment';

const _roundNumber = (number) => round(number, 0);

export default @tagName('') class MonthlyChart extends Component {
  @map('settlements', (settlement) => _roundNumber(settlement.get('salaryBase')))
  salaryBase;

  @map('settlements', (settlement) => _roundNumber(settlement.get('salaryOshee')))
  salaryOshee;

  @map('settlements', (settlement) => _roundNumber(settlement.get('salaryKudos')))
  salaryKudos;

  @map('settlements', (settlement) => _roundNumber(settlement.get('salarySeniority')))
  salarySeniority;

  @map('settlements', (settlement) => _roundNumber(settlement.get('salaryExtra')))
  salaryExtra;

  @map('settlements', (settlement) => _roundNumber(settlement.get('salaryRelaxing')))
  salaryRelaxing;

  @map('settlements', function(settlement) {
    let { year, month } = settlement;
    return moment(`${year}-${month}-2`).format('MMM \'YY');
  })
  categories;

  @computed(
    'salaryBase',
    'salaryKudos',
    'salarySeniority',
    'salaryExtra',
    'salaryOshee'
  )
  get dataSeries() {
    return EmberObject.create({
      salaryBase: this.salaryBase,
      salaryOshee: this.salaryOshee,
      salaryKudos: this.salaryKudos,
      salarySeniority: this.salarySeniority,
      salaryExtra: this.salaryExtra,
      salaryRelaxing: this.salaryRelaxing
    });
  }
}
