import Service, { inject as service } from '@ember/service';
import ENV from '../config/environment';

export default class AuthService extends Service {
  @service
  session;

  @service
  store;

  @service
  rollbar;

  login() {
    let lockOptions = {
      autoclose: true,
      container: 'lock-container',
      auth: {
        audience: ENV.APP.audience,
        redirect: false,
        params: { scope: 'openid email profile' }
      },

      languageDictionary: { title: '' },
    };
    this.session
      .authenticate('authenticator:auth0-lock', lockOptions)
      .then(() => {
        this.store
          .query('contractor', {
            filter: {
              uid: this.get('session.data.authenticated.profile.sub'),
            },
          })
          .then((contractors) => {
            let contractor = contractors.get('firstObject');
            contractor.set(
              'avatarUrl',
              this.get('session.data.authenticated.profile.picture'),
            );
            this.session.set('data.contractor_id', contractor.id);
            this.set('rollbar.currentUser', {
              'contractor_id': this.get('session.data.contractor_id'),
              profile: this.get('session.data.authenticated.profile'),
            });
            contractor.save();
          });
      });
  }

  logout() {
    this.session.invalidate();
  }

  autologin() {
    if (!this.get('session.isAuthenticated')) {
      this.login();
    }
  }
}
