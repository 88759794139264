import DS from 'ember-data';

const { Model, attr } = DS;

export default class MeritMoneyPeriodStat extends Model {
  @attr('number')
  kudoRate;

  @attr('number')
  seniorityRate;

  @attr('number')
  kudosTotal;

  @attr('number')
  seniorityTotal;

  @attr('number')
  kudosValue;

  @attr('number')
  seniorityValue;

  @attr('number')
  seniorityAverageBonus;

  @attr('number')
  meritMoneyContractorsCount;

  @attr('number')
  year;

  @attr('number')
  month;
}
