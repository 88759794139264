import { action, computed } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { groupBy, sortBy, uniq } from 'lodash';
import moment from 'moment';
import { minutesToHours } from 'selleo-dashboard/helpers/minutes-to-hours';

export default class TimelineController extends Controller {
  @service
  paperToaster;

  @computed('model.{financeStats,workloadStats,balanceWeeklies}')
  get statsByWeek() {
    let keyFormatter = (stat) => stat.sortOrder;
    let workloadStats = groupBy(this.model.workloadStats.toArray(), keyFormatter);
    let financeStats = groupBy(this.model.financeStats.toArray(), keyFormatter);
    let balanceWeeklies = groupBy(this.model.balanceWeeklies.toArray(), keyFormatter);
    let workloadKeys = Object.keys(workloadStats);
    let financeKeys = Object.keys(financeStats);
    let weeks = (uniq([...workloadKeys, ...financeKeys])).sort().reverse();
    return weeks.reduce((stats, week) => {
      let [balance] = balanceWeeklies[week];
      stats[week] = {
        balance: balance.customerBalanceHours,
        hoursWeekly: Math.round(balance.minutesPaid / 60),
        events: [...(financeStats[week] || []), ...(workloadStats[week] || [])],
        workloadStats: workloadStats[week] || []
      };
      return stats;
    }, {});
  }

  @action
  copyToClipboard(week, stat) {
    let [year, weekNumber] = week.split('W');
    let weekName = `Week ${weekNumber}`;
    let weekStartDate = moment().year(year).week(weekNumber).day(1);
    let weekStart = weekStartDate.format('MMM Do');
    let weekEndDate = weekStartDate.add(6, 'days');
    let weekEnd = weekEndDate.format('MMM Do');
    let sortedStats = sortBy(stat.workloadStats, (workload) => workload.get('contractor.name'));
    let contractorNames = sortedStats.map((workload) => workload.get('contractor.name'));
    let contractorHours = sortedStats.map((workload) => minutesToHours([workload.get('minutesPaid')]));
    let textArea = document.createElement('TEXTAREA');
    document.body.appendChild(textArea);
    textArea.innerHTML = `${weekName}\t${weekStart}\t${weekEnd}\n\t\t\t${contractorHours.join('\t')}\n\t\t\t${contractorNames.join('\t')}`;
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.get('paperToaster').show('Copied report to clipboard', {
      position: 'top right'
    });
  }
}
