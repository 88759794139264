import { helper } from '@ember/component/helper';

export function thousandSeparator([number], { precision = 2 }) {
  if (number === 0) {
    return number;
  } else if (number) {
    return new Intl.NumberFormat('pl-PL', { maximumFractionDigits: precision }).format(number);
  }
}

export default helper(thousandSeparator);
