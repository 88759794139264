import DS from 'ember-data';

const { Model, attr, belongsTo, hasMany } = DS;

export default class MeritMoneyInteractionsStat extends Model {
  @attr('number')
  kudos;

  @belongsTo('merit-money-week')
  week;

  @belongsTo('contractor', { inverse: 'kudosInteractionsGiven' })
  giver;

  @belongsTo('contractor', { inverse: 'kudosInteractionsReceived' })
  receiver;

  @hasMany('merit-money-kudo')
  kudosGiven;
}
