import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { minutesToHours } from 'selleo-dashboard/helpers/minutes-to-hours';
import { task } from 'ember-concurrency';
import { later } from '@ember/runloop';
import moment from 'moment';
import { includes, some } from 'lodash';
export default @classNames('md-padding schedule__time-entries-import') class ImportTimeEntries extends Component {
  timeEntriesRaw = '';

  @service
  paperToaster;

  @service
  router;

  @service
  store;

  @service
  clipboard;

  init() {
    super.init(...arguments);
    this._resetMessages();

    this.set('timeEntriesRaw', this.clipboard.pasteAndClear());
  }

  @computed
  get happixButtons() {
    return [
      { face: '😔', label: 'Awful', score: '1' },
      { face: '😐', label: 'Not So Good', score: '2' },
      { face: '🙂', label: 'OK', score: '3' },
      { face: '😀', label: 'Very Good', score: '4' },
      { face: '😎', label: 'Awesome', score: '5' }
    ];
  }

  @computed('timeEntriesRaw')
  get isAnythingWeekend() {
    if (this.timeEntriesRaw) {
      let lines = this.timeEntriesRaw.split('\n');
      return some(lines, (line) => includes([0, 6], moment(line.split(' ')[0], 'YYYY-MM-DD').day()));
    } else {
      return false;
    }
  }

  @(task(function* (happix) {
    this._resetMessages();
    this.set('happix', happix);
    yield this._importTimeEntries();
  }).drop())
  import;

  _resetMessages() {
    this.set('errorMsgs', []);
  }

  _importTimeEntries() {
    let teImport = this.store.createRecord('schedule-time-entry-import', {
      happix: this.happix,
      timeEntriesRaw: this.timeEntriesRaw
    });

    teImport.save().then(() => {
      this.set('timeEntriesRaw', null);
      this.set('happix', null);

      let msg = `${minutesToHours([
        teImport.minutesImported
      ])}h have been imported.`;

      this.paperToaster.show(msg, {
        duration: 5000,
        toastClass: 'success'
      });

      later(this, function() {
        this.router.transitionTo('d.personal');
      }, 500);
    })
      .catch((error) => {
        this.set('errorMsgs', error.errors);
      });
  }
}
