import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import Component from '@ember/component';

export default @tagName('') class Chart extends Component {
  @notEmpty('dataSeries')
  notEmptyDataSeries;

  @computed('categories.[]')
  get chartOptions() {
    return {
      chart: {
        zoomType: 'xy',
        type: 'column',
      },

      title: {
        text: 'Effective Hour Rate',
      },

      xAxis: {
        categories: this.categories,
        crosshair: true,
      },

      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: '{value} PLN',
            style: {
              color: 'black',
            },
          },

          title: {
            text: 'PLN',
            style: {
              color: 'grey',
            },
          },
        },
        {
          // Secondary yAxis
          title: {
            text: 'Percentage change to last year period',
            style: {
              color: 'grey',
            },
          },

          labels: {
            style: {
              color: 'black',
            },

            formatter() {
              let label = this.axis.defaultLabelFormatter.call(this);
              if (!label.includes('-')) {
                return `+${label}%`;
              }

              return `${label}%`;
            }
          },

          opposite: true,
        },
      ],

      legend: {
        layout: 'vertical',
        align: 'left',
        x: 120,
        verticalAlign: 'top',
        y: 100,
        floating: true,
        backgroundColor: '#FFFFFF',
      },

      tooltip: {
        shared: true,
        useHTML: true,
        formatter() {
          let { points: [hourRate, percentageChange] } = this;
          return _tooltipGenerator(hourRate, percentageChange);
        },
      },
    };
  }

  @computed('dataSeries', 'percentageChangeSeries')
  get chartData() {
    return [
      {
        name: 'Effective Rate',
        type: 'column',
        data: this.dataSeries,
      },
      {
        name: 'Percentage Change',
        type: 'column',
        yAxis: 1,
        data: this.percentageChangeSeries,
      },
      {
        name: 'Effective Rate Trendline',
        type: 'line',
        data: this.dataSeries,
      },
    ];
  }
}

function _tooltipGenerator({ y: hourRate, x: semester, color: hourRateColor }, { y: percentageChange, color: percentageColor }) {
  let formattedPercentageChange = percentageChange > 0 ? `+${percentageChange}%` : `${percentageChange}%`;
  return `
  <div style="font-size: 16px">
    ${semester}
    <ul>
      <li style="color: ${hourRateColor}">Effective Rate <b>${hourRate}</b></li>
      <li style="color: ${percentageColor}">Percentage Change <b>${formattedPercentageChange}</b></li>
    </ul>
  </div>`;
}
