import GoogleChartsService from 'ember-google-charts/services/google-charts';
import RSVP from 'rsvp';

export default GoogleChartsService.extend({
  googlePackages: Object.freeze(['corechart', 'timeline']),

  loadPackages() {
    let {
      google: { charts }
    } = window;

    return new RSVP.Promise((resolve, reject) => {
      charts.load('current', {
        language: this.get('language'),
        packages: this.get('googlePackages')
      });

      charts.setOnLoadCallback((ex) => {
        if (ex) {
          reject(ex);
        }

        resolve();
      });
    });
  }
});
