import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';

export default @tagName('') class TopNav extends Component {
  @service
  navigation;

  @service
  notifications;

  @service
  session;

  @alias('session.data.authenticated.profile.picture')
  profilePic;

  @action
  toggleSideNav() {
    this.toggleProperty('isSideNavOpen');
  }
}
