import DS from 'ember-data';

const { Model, attr, belongsTo } = DS;

export default class Booking extends Model {
  @attr()
  value;

  @belongsTo()
  period;

  @belongsTo()
  invoice;
}
