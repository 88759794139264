import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

export default @tagName('') class Chart extends Component {
  @computed('categories.[]')
  get chartOptions() {
    return {
      chart: {
        height: 600,
        type: 'column'
      },

      title: {
        text: 'Your monthly earnings'
      },

      xAxis: {
        categories: this.categories,
      },

      yAxis: {
        min: 0,
        title: {
          text: 'Total Cashflow'
        },

        stackLabels: {
          enabled: true,
          format: '{total} PLN',
          verticalAlign: 'top',
          y: -10,
          style: {
            color: 'black'
          }
        }
      },

      legend: {
        align: 'right',
        x: -30,
        verticalAlign: 'top',
        y: 25,
        floating: true,
        backgroundColor: 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },

      tooltip: {
        shared: true,
        headerFormat: 'In <i>{point.x}</i> you have earn: <b>{point.total} PLN </b><br/><br/>',
        pointFormat: '{series.name}: {point.y} PLN<br/>'
      },

      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            color: 'white'
          }
        }
      }
    };
  }

  @computed('dataSeries.{}')
  get chartData() {
    return [
      {
        name: 'Oshee Relaxing',
        data: this.get('dataSeries.salaryRelaxing')
      },
      {
        name: 'Extra',
        data: this.get('dataSeries.salaryExtra')
      },
      {
        name: 'Kudos',
        data: this.get('dataSeries.salaryKudos')
      },
      {
        name: 'Seniority',
        data: this.get('dataSeries.salarySeniority')
      },
      {
        name: 'Oshee',
        data: this.get('dataSeries.salaryOshee')
      },
      {
        name: 'Base',
        data: this.get('dataSeries.salaryBase')
      },
      {
        name: 'Base Trendline',
        type: 'line',
        data: this.get('dataSeries.salaryBase')
      }
    ];
  }
}
