import { inject as service } from '@ember/service';
import DS from 'ember-data';

const { Transform } = DS;

export default class OrderedMany extends Transform {
  @service
  store;

  deserialize(ids = [], options) {
    if (ids.length === 0) {
      return ids;
    }

    return this.store.query(options.model, {
      filter: { id: ids },
      include: options.include
    }).then(function(results) {
      return results;
    });
  }

  serialize(deserialized) {
    return (deserialized || []).map((obj) => obj.get('id'));
  }
}
