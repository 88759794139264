import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';

const data = [
  'Dbanie o wysoką utylizację na kliencie zwiększa część zmienną (seniority i kudos).',
  'Co roku dostajesz punkt seniority. Maksymalnie można uzbierać 5 punktów.',
  'Wszystkie prezentacje z haxorz możesz znaleźć <a href="https://docs.google.com/spreadsheets/d/1qaxfoSceOVVePx9iyFWJWs-pJtEqxWmFGkMGPp4hy2o/edit#gid=685417574">tutaj</a>.',
  'Regularne worklogowanie ułatwia nam wystawianie faktur dla klientów.',
  'Możesz uniknąć delayed hours worklogując dany dzień nie później niż 36h po jego zakończeniu.',
  'Unikniasz bycia chomikiem rozdając wszystkie kudosy kadego tygodnia.',
  'Po roku przysługuje Ci oshee relaxing. Warto odpoczywać.',
  'Możesz korzystać z budżetu na certyfikację i konferencję. Więcej info u twojego Tech Leada i CTO.',
  'Po spełnieniu wymagań odnośnie przepracowanych godzin, od Ciebie zależy kiedy wskoczysz na wyższą stawkę pnąc się przez DevPath.',
  'Możesz odwiedzać każdą naszą lokację - wystarczy uzgodnić to z dziewczynami z office.',
  'Możesz podzielić się swoim okryciem lub zwycięstwem - żeby to zrobić napisz na <a href="https://til.selleo.com" target="_blank">til.selleo.com</a> i na slacku <span>#tech-general</span>.',
  'Czasami więcej osób może pomóc albo wnieść coś do rozmowy - warto korzystać z kanałów zamiast pisać na priv.',
  'Dzielenie wiedzą jest ważne, dlatego co miesiąc organizujemy haxorz - maksymalnie poświęcamy na to w worklogu 8h miesięcznie.',
  'Twój worklog może trafić do klienta - pamiętaj o tym i dbaj, aby oddawał rzeczywistość.',
  'Razem budujemy lokalną społeczność - uczestnicz w eventach organizowanych przez Selleo.',
  'Możesz wybrać się z zespołem na GoKarty, obiadek, laser tag, escape room itd. - każdy team posiada swój budżet na integrację. O szczegóły zapytaj osoby z działu People.',
  'Gdy bierzesz wolne, pamiętaj aby poinformować o tym swój zespół. Jeżeli odpowiadasz za coś, upewnij się, że masz zastępstwo.',
  'Warto dbać o swoje CV - aktywność open source, publikacje na dribbble i behance, dobrze zrobione case study na naszej stronie, Twój profil na LinkedIn. Każdy może dołożyć swoją cegiełkę.',
  'Co roku zbiera się ekipa która wyjeżdża na żaglówki na Mazury. Dołącz do naszego okrętu na <span>#growth-nerds_on_lakes</span>!',
  'Możesz doszlifować swój angielski - zgłoś się do działu People, a ogarniemy Ci lekcje z nativem.',
  'Jeśli spociłeś się docierając do HQ np. na rowerze - możesz skorzystaj z prysznica!',
  'Jeśli czujesz pociąg do gier - w różnych lokacjach znajdziesz różne konsole. Cel jest jeden: dobra zabawa!',
  'Grzeczne zwierzaki są mile widziane w naszych biurach - ale skonsultuj wizytę z działem people, aby nie zrobiło się tłoczno.',
  'Brakujące dostępy otrzymasz pisząc na kanale <span>#people-accesses</span>.',
  'Zbieramy w Selleo zużyte nakrętki i baterie.',
  'Jeśli widzisz problem w naszych narzędziach wewnętrznych, możesz zgłosić go na <span>#ict-general</span>',
  'Jeśli jesteś zmeczony(-a) wiecznym pisaniem tego samego, możesz skorzystac z <a href="https://github.com/Selleo/dev-templates" target="_blank">dev-templates</a> lub zrobić kontrybucję',
  'Jeśli lubisz zwierzaki - wskocz na kanał <span>#hobby-pets</span>',
  'Jest taki kanał, o którym mało kto wie... Nazywa się <span>#hobby-skitury</span> i jest dla osób lubiących drałować pod górę.',
  'Najlepszym miejscem dla miłośników rowerów jest kanał <span>#hobby-bikers</span>',
  'Fani dobrego filmu mogą znaleźć coś dla siebie na kanale <span>#hobby-kinomaniacy</span>',
  'O nieruchomościach i ofertach podyskutujesz na <span>#hobby-nieruchomosci</span>',
  'Jeśli poker, to <span>#hobby-jaskinia-hazardu</span>.',
  'Maniaków planszówek zapraszamy na <span>#hobby-boardgames</span>.',
  'Dyskusje graczy grających w gry mają znajdziesz na <span>#hobby-games</span>.',
  'Jeśli interesujesz się designem, to sprawdź <span>#tech-ux</span>.',
  'Jeśli interesujesz się piłką nożną, sprawdź kanał <span>#hobby-football</span>.',
  'Chętnych do gry w siatkówkę zapraszamy na <span>#hobby-volleyball</span>.',
  'Jeśli lubisz narty lub snowboard, sprawdź kanał <span>#hobby-ski-racing</span>.',
  '<a href="https://selleo.com/guidebook" target="_blank">Selleo Playbook</a>: “Why we exist - we provide a <b>challenging and beneficial</b> place for the <b>community of engaged co-workers</b> committed to <b>personal and professional growth</b>.”',
  '<a href="https://selleo.com/guidebook" target="_blank">Selleo Playbook</a>: “How we behave - we are <b>“kindergarten players”</b> with a <b>self-driven growth</b> towards ingenious mastery.”',
  'Selleo Value (1 of 4): “Honesty - people trust us to keep our word.”',
  'Selleo Value (2 of 4): “Integrity - we are real and consistently open, ethical and genuine.”',
  'Selleo Value (3 of 4): “Respect - we encourage healthy debate and differences of opinion.”',
  'Selleo Value (4 of 4): "Reliability - we deliver our very best in all we do, holding ourselves accountable for results.”',
  'W przypadku problemów z worklogowaniem godzin - zgłoś problem na <span>#ict-general</span>.',
  'Pzechowuj kopię swoich worklogów, na wypadek łatwiejszego zdiagnozowania problemów przez dział ICT.',
  'To nie wstyd prosić o pomoc. Jeśli masz techniczny problem na projekcie, możesz zapytać na kanale #tech-general lub na kanałach dotyczących używanej technologii np. #tech-react #tech-ruby. Możesz również skorzystać z pomocy TechLead\'ów. (Pisz na priv)',
  'Przygotowując prezentację, dla spójności wizualnej utwórz kopię na bazie <a href="https://docs.google.com/presentation/d/11Tgl5RkdzBA3v_reLxqnv2xSels3rA_gP_Qepb8qo-4/edit#slide=id.g96256219f6_0_4" target="_blank">predefiniowanego szablonu</a>.',
  'Przygotowujesz się do Devpath\'u, ale nie wiesz o co chodzi w danym pytaniu lub zadaniu? Nie czekaj do ewaluacji. Porozmawiaj ze swoim mentorem, ewaluatorem lub TechLeadem.',
  'Przygotowujesz się do Devpath\'u i niektóre z zadań robiłeś już na projekcie komercyjnym lub własnym? Możliwe że to wystarczy. Porozmawiaj ze swoim mentorem, ewaluatorem lub TechLeadem.'
];

export default class DidYouKnow extends Component {
  get randomFaq() {
    return htmlSafe(data[Math.floor(Math.random() * data.length)]);
  }
}
