import { computed } from '@ember/object';
import Component from '@glimmer/component';
import moment from 'moment';
const { filter } = computed;

export default class TimestatsFilter extends Component {
  @filter('args.dailyReports', function(dailyReport) {
    let { period } = this.args;
    let reportDate = moment(dailyReport.get('date'));

    return reportDate.isBetween(period.start, period.end, 'day', '[]');
  })
  selectedDailyReports;

  @filter('args.bookingAvailabilities', function(ba) {
    let { period } = this.args;
    let bookingDate = moment(ba.get('dayOn'));

    return bookingDate.isBetween(period.start, period.end, 'day', '[]');
  })
  selectedBookingAvailabilities;

  @computed('selectedDailyReports')
  get projects() {
    let byPeriodName = this.selectedDailyReports;
    let reduced = byPeriodName.reduce(function(hash, day) {
      let key = day.get('customer.name');
      if (hash[key]) {
        hash[key].worked += day.get('worked');
        hash[key].customer = key;
      } else {
        hash[key] = {
          customer: key,
          worked: day.get('worked')
        };
      }

      return hash;
    }, []);
    return Object.values(reduced);
  }

  @computed('selectedDailyReports')
  get billable() {
    let byPeriodName = this.selectedDailyReports;
    let worked = 0;
    let billable = 0;
    byPeriodName.forEach(function(day) {
      worked += day.get('worked');
      billable += day.get('billable');
    });
    return { worked, billable };
  }

  @computed('selectedDailyReports')
  get summarizedDays() {
    let { selectedDailyReports, selectedBookingAvailabilities } = this;
    let worked = 0;
    let overtime = 0;
    let weekend = 0;
    let target = 0;
    selectedDailyReports.forEach(function(day) {
      worked += day.get('worked');
      overtime += day.get('overtime');
      weekend += day.get('weekend');
    });
    let regular = worked - overtime - weekend;
    selectedBookingAvailabilities.forEach(function(day) {
      target += day.get('hoursTarget') * 60;
    });
    return { worked, regular, overtime, weekend, target };
  }
}
