import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default @classNames('login') class EmbeddedLoginLock extends Component {
  @service
  auth;

  didRender() {
    this.auth.autologin();
  }
}
