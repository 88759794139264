import Component from '@ember/component';
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import { sortBy } from 'lodash';
import { inject as service } from '@ember/service';

export default class ContractorAvatar extends Component {
  @service
  session;

  @service
  store;

  classNames = ['merit-contractor'];

  classNameBindings = ['currentUser:current-contractor'];

  @computed('contractor.id,session.currentUser.id')
  get currentUser() {
    return (this.get('contractor.id') === this.get('session.currentUser.id'));
  }

  @computed
  get previousWeeks() {
    let [, lastWeek, aWeekBefore] = sortBy(this.store.peekAll('merit-money-week').toArray(), (week) => `${week.year}-${week.week.toString().padStart(2, '0')}`).reverse();
    return { aWeekBefore, lastWeek };
  }

  sortOrder = Object.freeze(['sortOrder:desc']);

  @sort('contractor.meritMoneyWeeklyKudosReceived', 'sortOrder')
  sortedKudosReceived;

  @computed('sortedKudosReceived.@earch')
  get lastWeekKudos() {
    return (this.sortedKudosReceived[0] || { kudosReceived: 0 }).kudosReceived;
  }

  @computed('sortedKudosReceived.@earch')
  get aWeekBeforeKudos() {
    return (this.sortedKudosReceived[1] || { kudosReceived: 0 }).kudosReceived;
  }

  @computed
  get pendingKudos() {
    let [week] = this.contractor.get('meritWeeks').toArray().reverse();
    return week.pendingKudos;
  }

  @computed('contractor.kudosReceived.@each.{state,isNew,value,week}')
  get kudosCount() {
    if (this.currentUser) {
      return {
        pending: 0,
        lastWeek: this.lastWeekKudos,
        aWeekBefore: this.aWeekBeforeKudos,
        inThisPeriodKudos: this.get('contractor.meritMoneyMonthlyKudosReceived.kudosReceived') || 0,
      };
    } else {
      return this.kudosGivenByCurrent.reduce((memo, kudo) => {
        if (kudo.get('isNew')) {
          // do nothing
        } else if (kudo.get('state') === 'draft') {
          memo.pending += kudo.get('value');
        } else if (kudo.get('week.id') === this.get('previousWeeks.aWeekBefore.id')) {
          memo.aWeekBefore += kudo.get('value');
        } else if (kudo.get('week.id') === this.get('previousWeeks.lastWeek.id')) {
          memo.lastWeek += kudo.get('value');
        }

        return memo;
      }, {
        pending: 0,
        lastWeek: 0,
        aWeekBefore: 0,
        inThisPeriodKudos: 0
      });
    }
  }

  @computed('contractor.kudosReceived.@each.{giver}')
  get kudosGivenByCurrent() {
    return this.contractor.get('kudosReceived').filter((kudo) => kudo.get('giver.id') === this.session.get('currentUser.id'));
  }
}
