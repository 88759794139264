import DS from 'ember-data';

const {
  Model,
  attr
} = DS;

export default class WeeklyStat extends Model {
  @attr('number')
  kudosLeft;

  @attr('number')
  kudosReceived;
}
