import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';

export default class PeriodRoute extends AuthenticatedRoute {
  queryParams = {
    scope: {
      refreshModel: true
    }
  };

  model({ scope }) {
    return this.store.query('daily-time-stat', {
      include: 'customer,contractor',
      filter: {
        'period_code': scope || 'this_month'
      }
    });
  }
}
