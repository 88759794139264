import DS from 'ember-data';
import { belongsTo } from 'ember-data/relationships';

const { Model, attr } = DS;

export default class ScheduleRemainingBookingException extends Model {
  @belongsTo('contractor')
  contractor;

  @attr('number')
  workedMinutes;

  @attr('number')
  targetMinutes;

  @attr('number')
  yearlyBookingExceptionMinutes;
}
