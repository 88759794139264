import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { sort } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';

export default class TeamCustomers extends Component {
  @service
  store;

  @tracked
  customers = null;

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);
    this._loadData();
  }

  sortOrder = Object.freeze(['balance.balance', 'asc']);

  @sort('customers', 'sortOrder')
  sortedCustomers;

  @action
  loadData() {
    let customers = this.store.query('customer', {
      include: 'recent_contractors,balance',
      filter: { for_team: this.args.team.id },
    });

    this.customers = customers;
  }
}
