import { computed } from '@ember/object';
import DS from 'ember-data';
import { round } from 'lodash';

const { Model, attr, hasMany, belongsTo } = DS;

export default class ProjectStat extends Model {
  @attr('string')
  name;

  @belongsTo('period')
  period;

  @attr('number')
  hoursAssign;

  @attr('number')
  hoursWorked;

  @hasMany('achievement')
  achievements;

  @computed('period', 'name', 'hoursAssign', 'hoursWorked')
  get label() {
    return `${this.name} [${this.hoursAssign}/${this.hoursWorked}h]`;
  }

  @computed('hoursAssing', 'hoursWorked')
  get hoursLeft() {
    return round(this.hoursWorked - this.hoursAssign, 1);
  }
}
