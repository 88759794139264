import DS from 'ember-data';
import { belongsTo } from 'ember-data/relationships';

const { Model, attr } = DS;

export default class ScheduleOsheeYearlyStat extends Model {
  @belongsTo('contractor')
  contractor;

  @attr('number')
  osheeMinutes;

  @attr('number')
  year;
}
