import { classNames, layout as templateLayout } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { round, sortBy } from 'lodash';
import layout from './template';

export default

@classNames('flex')
@templateLayout(layout)
class TeamUtilization extends Component {
  @computed
  get data() {
    return [];
  }

  _sumMinutes(collection) {
    return collection.reduce(
      function(cache, contractorUtilization) {
        cache.workedMinutes += contractorUtilization.workedMinutes;
        cache.paidMinutes += contractorUtilization.paidMinutes;

        return cache;
      },
      { workedMinutes: 0, paidMinutes: 0 }
    );
  }

  _prepareStat({ workedMinutes, paidMinutes }) {
    let minutesToHours = (minutes) => round(minutes / 60.0, 0);
    let percentage = (value, max) => {
      if (max > 0) {
        return round((value / max) * 100.0);
      } else {
        return 0;
      }
    };

    let hoursAll =
      minutesToHours(paidMinutes) + minutesToHours(workedMinutes - paidMinutes);

    return {
      percentage: percentage(minutesToHours(paidMinutes), hoursAll),
      hoursBillable: minutesToHours(paidMinutes),
      hoursNonBillable: minutesToHours(workedMinutes - paidMinutes),
      hoursAll
    };
  }

  _contractorUtilizationsForTeam(team) {
    let memberIds = team.members.map((m) => m.get('contractor.id'));

    return sortBy(
      this.utilizations.filter((cu) =>
        memberIds.includes(cu.get('contractor.id'))
      ),
      (el) => el.get('contractor.name')
    );
  }
}
