import { computed } from '@ember/object';
import DS from 'ember-data';

const {
  Model,
  attr,
  belongsTo
} = DS;

export default class FinanceCustomerWeeklyStatsBalance extends Model {
  @belongsTo()
  customer;

  @attr('number')
  year;

  @attr('number')
  week;

  @attr('number')
  minutesBooked;

  @attr('number')
  hoursBookedCumulative;

  @attr('number')
  minutesPaid;

  @attr('number')
  hoursPaidCumulative;

  @attr('number')
  customerBalanceHours;

  @computed('week', 'year')
  get sortOrder() {
    return `${this.year}W${this.week.toString().padStart(2, '0')}`;
  }
}
