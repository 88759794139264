import { classNames } from '@ember-decorators/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import Changeset from 'ember-changeset';
import moment from 'moment';

export default

@classNames('layout-column', 'flex', 'md-padding')
class Form extends Component {
  @service
  store;

  @service
  session;

  @service
  can;

  errMsg = '';

  init() {
    super.init(...arguments);
    let { bookingException } = this;

    if (!bookingException) {
      bookingException = this._createNewBookingException();
      this.set('bookingException', bookingException);
    }

    this._setupChangeset(bookingException);
  }

  get isInvalid() {
    let isAfterStart = (moment(this.changeset.period.end) || '2000-01-01')
      .isSameOrAfter(moment(this.changeset.period.start));
    return !isAfterStart;
  }

  _createNewBookingException() {
    let { store } = this;
    let currentUser = store.peekRecord('contractor', this.get('session.data.contractor_id'));
    let newBookingException = store.createRecord('schedule-booking-exception', {
      period: {
        start: moment().format('YYYY-MM-DD'),
      },
    });
    newBookingException.set('contractor', currentUser);
    return newBookingException;
  }

  _setupChangeset(bookingException) {
    let changeset = new Changeset(bookingException);
    this.set('changeset', changeset);

    if (bookingException) {
      changeset.set('period', bookingException.get('period'));
      changeset.set('comment', bookingException.get('comment'));
    }
  }

  @action
  save() {
    this.changeset
      .save()
      .then(() => {
        this.hideForm();
      }).catch((error) => {
        this.set('errMsg', error.errors[0].detail);
      });
  }

  @action
  deleteException(exception) {
    if (this.can.can('edit schedule-booking-exception', exception)) {
      exception
        .destroyRecord()
        .then(() =>
          this.hideForm()
        );
    }
  }

  @action
  updatePeriodStart(date) {
    this.changeset.period.start = date;
    this.changeset.period = this.changeset.period;

    return true;
  }

  @action
  updatePeriodEnd(date) {
    this.changeset.period.end = date;
    this.changeset.period = this.changeset.period;

    return true;
  }
}
