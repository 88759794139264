import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
/* global Highcharts */
import Component from '@ember/component';

export default @tagName('') class RemainingChart extends Component {
  @computed
  get chartOptions() {
    return {
      chart: {
        type: 'solidgauge',
        width: 190,
        height: 130,
        marginTop: 0,
      },

      title: {
        text: null
      },

      pane: {
        center: ['50%', '85%'],
        size: '140%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || '#FFEAE0',

          outerRadius: '100%',
          shape: 'arc',
          borderWidth: 0
        }
      },

      tooltip: {
        enabled: false
      },

      yAxis: {
        min: this.min,
        max: this.max,
        lineWidth: 0,
        tickPositions: [],

        title: {
          text: undefined
        },

        labels: {
          enabled: false
        }
      },

      credits: {
        enabled: false
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: -55,
            borderWidth: 0,
            useHTML: true,
            format: this.dataLabelsFormat()
          }
        }
      }
    };
  }

  @computed('value')
  get chartData() {
    return [
      {
        data: [
          {
            radius: 86,
            color: '#FFFA',
            innerRadius: 81,
            y: this.max
          },
          {
            radius: 90,
            color: '#E25718',
            innerRadius: 77,
            y: this.value
          },
        ]
      }
    ];
  }

  dataLabelsFormat() {
    let unitInfo = '';

    if (this.unit) {
      unitInfo = `<span style="font-size:12px;opacity:0.4">${this.unit} left</span>`;
    }

    return `<div style="text-align:center"><span style="font-size:25px">${this.value}</span><br/>${unitInfo}</div>`;
  }
}
