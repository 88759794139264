import { action } from '@ember/object';
import { tagName } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { compact, flatten } from 'lodash';

export default @tagName('') class SideNav extends Component {
  @service
  auth;

  @service
  session;

  @service
  navigation;

  @service
  router;

  @action
  toggleSubMenu(index) {
    this.toggleProperty(`navigation.menuItems.${index}.subMenuOpen`);
  }

  @action
  goTo(item) {
    this.set('isSideNavOpen', false);
    let args = compact(flatten([item.route, item.params]));
    if (item.queryParams) {
      args.push({ queryParams: item.queryParams });
    }

    this.router.transitionTo(...args);
  }
}
