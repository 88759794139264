import { tagName } from '@ember-decorators/component';
import { action, computed } from '@ember/object';
import Component from '@ember/component';

export default @tagName('') class NotificationsButton extends Component {
  @computed('notifications.length')
  get notificationCount() {
    return this.get('notifications.length');
  }

  @action
  addNotification() {
    this.notifications.pushObject({ name: 'Test Notification', icon: 'report' });
  }

  @action
  readNotification(item) {
    this.notifications.popObject(item);
  }
}
