import Service, { inject as service } from '@ember/service';

export default class NavigationService extends Service {
  @service
  session;

  init() {
    super.init(...arguments);
    let menuItems = [
      {
        name: 'Kudos',
        icon: 'kudos',
        route: 'mm.kudos'
      },
      {
        name: 'Worklogs',
        icon: 'worklogs',
        route: 'schedule.import-time-entries'
      },
      {
        name: 'Teams',
        icon: 'teams',
        route: 'teams'
      },
      {
        name: 'Finance',
        icon: 'finance',
        route: 'finance.settlements'
      },
      {
        name: 'Portfolio',
        icon: 'portfolio',
        route: 'portfolio'
      },
    ];
    this.set('menuItems', menuItems);
  }
}
