import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { sortBy } from 'lodash';
import moment from 'moment';

export default class MeritRates extends Component {
  @service
  store;

  @computed('categories')
  get chartOptions() {
    return {
      chart: {
        height: 800,
        type: 'line'
      },

      title: {
        text: 'Merit Money Rates'
      },

      xAxis: {
        categories: this.categories,
      },

      yAxis: [{
        min: 0,
        opposite: true,
        title: {
          text: 'bucket'
        }
      }, {
        min: 0,
        title: {
          text: 'rate'
        },

        stackLabels: {
          enabled: true,
          format: '{total} zł',
          verticalAlign: 'top',
          y: -10,
          style: {
            color: 'black'
          }
        }
      }, {
        min: 0,
        title: {
          text: null
        }
      }, {
        min: 0,
        title: {
          text: 'count'
        }
      }],

      plotOptions: {
        area: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          }
        }
      }
    };
  }

  @computed('stats')
  get sortedStats() {
    return sortBy(this.stats.toArray(), (stat) => `${stat.year}M${stat.month.toString().padStart(2, '0')}`);
  }

  @computed('sortedStats')
  get categories() {
    return this.sortedStats.map((stat) => moment(`${stat.year}-${stat.month}-2`, 'YYYY-MM-D').format('MMM \'YY'));
  }

  @computed('dataSeries.{}')
  get chartData() {
    let kudosData = this.sortedStats.map((stat) => stat.kudoRate);
    let seniorityData = this.sortedStats.map((stat) => stat.seniorityRate);
    let kudosBucketData = this.sortedStats.map((stat) => stat.kudosValue);
    let seniorityBucketData = this.sortedStats.map((stat) => stat.seniorityValue);
    let kudosCount = this.sortedStats.map((stat) => stat.kudosTotal);
    let seniorityCount = this.sortedStats.map((stat) => stat.seniorityTotal);
    let contractorsCount = this.sortedStats.map((stat) => stat.meritMoneyContractorsCount);
    return [
      { name: 'Kudos Count', type: 'column', yAxis: 3, visible: false, data: kudosCount },
      { name: 'Kudos Bucket', type: 'column', yAxis: 0, visible: false, data: kudosBucketData },
      { name: 'Kudo Rate', yAxis: 1, data: kudosData },
      { name: 'Seniority Count', type: 'column', yAxis: 3, visible: false, data: seniorityCount },
      { name: 'Seniority Bucket', type: 'column', yAxis: 0, visible: false, data: seniorityBucketData },
      { name: 'Seniority Rate', yAxis: 2, data: seniorityData },
      { name: 'Contractors Count', type: 'column', yAxis: 3, visible: false, data: contractorsCount }
    ];
  }
}
