import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';

export default class OsheeRoute extends AuthenticatedRoute {
  model() {
    let team = this.modelFor('teams.team');

    return this.store.query('schedule-oshee-yearly-stat', {
      include: 'contractor',
      filter: {
        for_team: team.id
      }
    });
  }
}
