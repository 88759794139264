import RSVP from 'rsvp';
import { get } from '@ember/object';
import Auth0Base from 'ember-simple-auth-auth0/authenticators/auth0-lock';
import getSessionExpiration from 'ember-simple-auth-auth0/utils/get-session-expiration';
import now from 'ember-simple-auth-auth0/utils/now';

export default class _Auth0Base extends Auth0Base {
  restore(data) {
    // weekly
    data.expiresIn = 24 * 60 * 60 * 7;
    let expiresAt = getSessionExpiration(data || {});

    if (expiresAt > now()) {
      return RSVP.resolve(data);
    } else if (get(this, 'auth0.silentAuthOnSessionRestore')) {
      return this._performSilentAuth();
    } else {
      return RSVP.reject();
    }

  }
}
