import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { Ability } from 'ember-can';
import moment from 'moment';

export default class ScheduleBookingException extends Ability {
  @service
  session;

  @computed('model')
  get canEdit() {
    let bookingException = this.model;
    let bookingPeriodEnd = bookingException.get('period.end');
    let isNotInThePast = moment().format('YYYY-MM-DD') < bookingPeriodEnd;
    let currentUserIsOwner = bookingException.get('contractor.id') === this.get('session.data.contractor_id');

    return isNotInThePast && currentUserIsOwner;
  }
}
