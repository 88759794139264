import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class TermsService extends Service {
  @service
  store;

  @service
  session;

  @tracked
  pendingTerms = null;

  get currentTerm() {
    return this.pendingTerms?.firstObject;
  }

  async getTerms() {
    try {
      this.pendingTerms = (await this.store.findAll('term')).toArray();
    } catch(error) {
      console.error(error);
    }
  }

  async acceptTerm() {
    if (!this.session.isAuthenticated) {
      console.error(new Error('You must be logged in to accept terms'));
    }

    if (this.pendingTerms === null) {
      await this.getTerms();
    }

    await this.store.createRecord('term-confirmation', {
      termId: this.currentTerm.id,
    }).save();

    this.pendingTerms = this.pendingTerms.slice(1);
  }

  async areTermsAccepted() {
    if (!this.session.isAuthenticated) {
      console.error(new Error('You must be logged in to accept terms'));
    }

    if (this.pendingTerms === null) {
      await this.getTerms();
    }

    return this.pendingTerms?.length === 0;
  }
}
