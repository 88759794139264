import { tagName, layout as templateLayout } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias, map, mapBy } from '@ember/object/computed';
import Component from '@ember/component';
import { round } from 'lodash';
import layout from './template';
import moment from 'moment';

export default

@tagName('')
@templateLayout(layout)
class BaseComponent extends Component {
  @service
  store;

  @alias('model')
  settlements;

  @map('effectiveHourValues', function(hourValue) {
    return this._numbersRound(hourValue);
  })
  dataSeries;

  @map('percentageChanges', function(percentageChange) {
    return this._numbersRound(percentageChange);
  })
  percentageChangeSeries;

  @map('effectiveHoursPeriod', function(period) {
    return period;
  })
  categories;

  @mapBy('calculateEffectiveHours', 'key')
  effectiveHoursPeriod;

  @map('calculateEffectiveHours', (rate) => rate.hoursRate / rate.semesterCount)
  effectiveHourValues;

  @map('effectiveHourValues', function(hourValue, hourValueIndex, array) {
    if (hourValueIndex === 0) {
      return null;
    }

    let previousValue = array[hourValueIndex - 1];
    return (hourValue / previousValue * 100) - 100;
  })
  percentageChanges;

  @computed('settlements')
  get calculateEffectiveHours() {
    let aggregatedSettlement = this.settlements.reduce((hash, settlement) => {
      let { year, month, salaryBase, salaryKudos, salarySeniority, salaryExtra, hours } =
      settlement;

      let key = this.aggregateByKey
        ? this.aggregateByKey(year, month)
        : moment(`${year}-${month}`).format('MMM \'YY');
      let hoursRate = (salaryBase + salaryKudos + salarySeniority + salaryExtra) / hours;
      if (hash[key]) {
        hash[key].hoursRate += hoursRate;
        hash[key].semesterCount += 1;
      } else {
        hash[key] = {
          key,
          hoursRate,
          semesterCount: 1
        };
      }

      return hash;
    }, {});

    return Object.values(aggregatedSettlement);
  }

  _numbersRound(number) {
    return round(number, 2);
  }
}

