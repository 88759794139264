import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

export default @tagName('') class Chart extends Component {
  @computed('categories.[]')
  get chartOptions() {
    return {
      chart: {
        zoomType: 'xy',
      },

      title: {
        text: null
      },

      xAxis: [
        {
          categories: this.categories,
          crosshair: true,
        },
      ],

      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: '{value} h',
            style: {
              color: '#90ed7d',
            },
          },

          title: {
            text: 'Hours',
            style: {
              color: '#90ed7d',
            },
          },

          opposite: true,
        },
        {
          // secondary yaxis
          gridlinewidth: 0,
          title: {
            text: 'contractors',
            style: {
              color: '#7cb5ec',
            },
          },

          labels: {
            format: '{value} contractors',
            style: {
              color: '#7cb5ec',
            },
          },
        },
        {
          // third yaxis
          gridlinewidth: 0,
          min: 0,
          max: 100,
          title: {
            text: 'utilization',
            style: {
              color: '#00ff00',
            },
          },

          labels: {
            format: '{value} %',
            style: {
              color: '#7cb5ec',
            },
          },
        },
      ],

      tooltip: {
        shared: true,
      },

      legend: {
        layout: 'vertical',
        floating: false,
        backgroundColor: '#FFFFFF',
      },
    };
  }

  @computed('dataSeries.{worked,paid,contractors}')
  get chartData() {
    return [
      {
        name: 'Service Delivery (contractors)',
        type: 'column',
        yAxis: 1,
        stacking: 'normal',
        data: this.get('dataSeries.serviceDelivery'),
        tooltip: {
          valueSuffix: ' contractors',
        }
      },
      {
        name: 'Operations (contractors)',
        type: 'column',
        yAxis: 1,
        stacking: 'normal',
        data: this.get('dataSeries.operations'),
        tooltip: {
          valueSuffix: ' contractors',
        }
      },
      {
        name: 'Non-billable Service Delivery',
        type: 'areaspline',
        data: this.get('dataSeries.deliveryNonBillable'),
        stacking: 'normal',
        yAxis: 0,
        tooltip: {
          valueSuffix: ' h',
        }
      },
      {
        name: 'Non-billable Operations',
        type: 'areaspline',
        data: this.get('dataSeries.operationsNonBillable'),
        stacking: 'normal',
        yAxis: 0,
        tooltip: {
          valueSuffix: ' h',
        }
      },
      {
        name: 'Billable Operations',
        type: 'areaspline',
        data: this.get('dataSeries.operationsBillable'),
        yAxis: 0,
        stacking: 'normal',
        tooltip: {
          valueSuffix: ' h',
        }
      },
      {
        name: 'Billable Service Delivery',
        type: 'areaspline',
        data: this.get('dataSeries.deliveryBillable'),
        yAxis: 0,
        stacking: 'normal',
        tooltip: {
          valueSuffix: ' h',
        }
      },
      {
        name: 'Service Delivery Utilization',
        type: 'spline',
        data: this.get('dataSeries.deliveryUtilization'),
        yAxis: 2,
        tooltip: {
          valueSuffix: ' %',
        }
      },
      {
        name: 'Company Utilization',
        type: 'spline',
        data: this.get('dataSeries.utilization'),
        yAxis: 2,
        tooltip: {
          valueSuffix: '%',
        }
      }
    ];
  }
}
