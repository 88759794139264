import { alias } from '@ember/object/computed';
import DS from 'ember-data';

const {
  Model,
  attr,
  belongsTo
} = DS;

export default class ContractorLevelStat extends Model {
  @attr('number')
  workload;

  @belongsTo('contractor')
  contractor;

  @belongsTo('period')
  period;

  @belongsTo('level')
  level;

  @alias('level.label')
  label;
}
