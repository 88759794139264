import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import DS from 'ember-data';

const {
  Model,
  attr
} = DS;

export default class PayrollSettlement extends Model {
  @attr('number', { defaultValue: 0 })
  salaryBase;

  @alias('salaryBase') salary;

  @attr('number', { defaultValue: 0 })
  salarySeniority;

  @alias('salarySeniority') seniority;

  @attr('number', { defaultValue: 0 })
  salaryKudos;

  @alias('salaryKudos') kudos;

  @attr('number', { defaultValue: 0 })
  salaryOshee;

  @alias('salaryOshee') oshee;

  @attr('number', { defaultValue: 0 })
  salaryExtra;

  @alias('salaryExtra') extra;

  @attr('number', { defaultValue: 0 })
  salaryOsheeRelaxing;

  @alias('salaryOsheeRelaxing') salaryRelaxing;

  @attr('number')
  hoursTotal;

  @alias('hoursTotal') hours;

  @attr('number')
  paidAtHand;

  @attr('number')
  paidAtCost;

  @attr('number')
  paidVat;

  @attr('boolean')
  vat;

  @attr('number')
  hourRateEffective;

  @attr()
  meritMoneyDetails;

  @attr()
  baseRateDetails;

  @attr()
  extraDetails;

  @attr('string')
  year;

  @attr('string')
  month;

  @computed('year', 'month')
  get sortOrder() {
    return `${this.year}-${this.month.padStart(2, '0')}`;
  }
}
