import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default class ReportController extends Controller {
  @service
  router;

  @computed('router.currentURL')
  get selectedTab() {
    let selectedTab;
    let currentUrl = this.get('router.currentURL');
    if (currentUrl.includes('this_month')) {
      selectedTab = 'this_month';
    }

    if (currentUrl.includes('last_month')) {
      selectedTab = 'last_month';
    }

    return selectedTab ? selectedTab : 'report';
  }
}
