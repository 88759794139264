import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';
import RSVP from 'rsvp';

export default class PortfolioRoute extends AuthenticatedRoute {
  model() {
    return RSVP.hash({
      teams: this.store.query('team', {
        include: [
          'members.role',
          'members.contractor',
        ].join(','),

        filter: {
          service_delivery: true
        },
      }),

      utilizations: this.store.findAll('portfolio-weekly-stat',
        {
          include: [
            'contractor',
            'week',
            'customer'
          ],
        }
      ),
    });
  }
}
