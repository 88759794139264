import DS from 'ember-data';

const { Model, attr, belongsTo } = DS;

export default class MeritMoneyHamstersAndTopCollector extends Model {
  @attr('boolean')
  hamster;

  @attr('boolean')
  topCollector;

  @attr('number')
  kudosLeft;

  @belongsTo()
  contractor;
}
