import { helper } from '@ember/component/helper';

const balanceCSS = [
  [-500, 'bg-red'],
  [-200, 'bg-pink'],
  [200, 'bg-orange'],
  [500, 'bg-yellow'],
  [Infinity, 'bg-green']
];

export function colorGenerator(params) {
  if (!params.includes(undefined)) {
    let [balance] = params;
    let className = null;
    balanceCSS.any(function([limit, cssClass]) {
      if (balance < limit) {
        className = cssClass;
        return cssClass;
      }
    });
    return className;
  }
}

export default helper(colorGenerator);
