import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

const balanceCSS = [
  [-160, 'healthcheck-bg-5'],
  [-80, 'healthcheck-bg-4'],
  [0, 'healthcheck-bg-3'],
  [80, 'healthcheck-bg-2'],
  [Infinity, 'healthcheck-bg-1']
];

export default @tagName('') class Element extends Component {
  @computed
  get balanceColor() {
    let balance = this.get('customer.balance.balance');
    let className = null;
    balanceCSS.any(function([limit, cssClass]) {
      if (balance < limit) {
        className = cssClass;
        return cssClass;
      }
    });
    return className;
  }
}
