import { classNames, classNameBindings } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';

export default

@classNames('billable-cell')
@classNameBindings('currentWeek:current', 'month')
class CellBillable extends Component {
  @computed('cell')
  get percentage() {
    if (this.cell) {
      return this.cell.percentage;
    } else {
      return null;
    }
  }

  @alias('cell.week.currentWeek')
  currentWeek;

  @alias('cell.week.month')
  month;

  @computed('percentage')
  get valueClass() {
    if (this.percentage < 40) {
      if (this.cell && this.cell.workedMinutesTarget === 0) {
        return 'text-grey';
      } else {
        return 'bg-red';
      }
    } else if (this.percentage < 70) {
      return 'bg-orange';
    } else if (this.percentage < 90) {
      return 'bg-green';
    } else if (this.percentage > 100) {
      return 'bg-alarm-red';
    } else {
      return 'text-grey';
    }
  }

  @computed('utilizations.[]')
  get customerAccountsUtilizations() {
    return this.cell.utilizations.filter((utilization) => utilization.workedMinutesTarget === 0);
  }

  @computed('utilizations.[]')
  get availabilityUtilizations() {
    return this.cell.utilizations.filter((utilization) => utilization.workedMinutesTarget !== 0);
  }
}
