import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { round } from 'lodash';

export default

@classNames('flex-100', 'remaining-booking-exceptions')
class RemainingBookingExceptions extends Component {
  @computed(
    'remainingBookingException.{workedMinutes,targetMinutes,yearlyBookingExceptionLimit}'
  )
  get bookingExceptionsLeft() {
    return round(
      (this.remainingBookingException.workedMinutes -
        this.remainingBookingException.targetMinutes +
        this.remainingBookingException.yearlyBookingExceptionMinutes) /
        (60.0 * 8.0),
      1
    );
  }

  @computed('remainingBookingException.workedMinutes')
  get hoursWorked() {
    let minutes = this.remainingBookingException.get('workedMinutes');
    return `${Math.floor(minutes / 60)}:${minutes % 60}`;
  }

  @computed('remainingBookingException.targetMinutes')
  get hoursTarget() {
    let minutes = this.remainingBookingException.get('targetMinutes');
    return `${Math.floor(minutes / 60)}:${minutes % 60}`;
  }
}
