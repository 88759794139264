import Model, { attr, belongsTo } from '@ember-data/model';

export default class TaskModel extends Model {
  @attr('string')
  name;

  @attr('string')
  description;

  @attr('boolean')
  visible;

  @belongsTo('team')
  team;
}
