import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

export default @classNames('flex-100', 'remaining-booking-exceptions') class RecentDelayed extends Component {
  @computed('recentDelayed')
  get delayedHoursLeft() {
    return 8 - this.recentDelayed.delayedHours;
  }
}
