import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import SessionService from 'ember-simple-auth/services/session';

export default class _SessionService extends SessionService {
  @service
  store;

  @computed('data.contractor_id')
  get currentUser() {
    return this.store.findRecord('contractor', this.get('data.contractor_id'), {
      include: 'service_delivery_member,service_delivery_member.team,service_delivery_member.role',
      adapterOptions: {
        extraFields: 'utilization_rate_current_month,utilization_rate_last_month,team_utilization_current_month,team_utilization_last_month'
      }
    });
  }
}
