import { classNames } from '@ember-decorators/component';
import { inject as service } from '@ember/service';
import { sort, mapBy } from '@ember/object/computed';
import Component from '@ember/component';
import EmberObject, { computed } from '@ember/object';
import moment from 'moment';
import { uniq } from 'lodash';

export default @classNames('customer-finance') class FinanceChart extends Component {
  @service
  store;

  @computed('inflows.[]')
  get inflowCategories() {
    return this.inflows.map((inflow) => moment.utc(inflow.get('period.period.start'), 'YYYY-MM-DD HH:mm:ss').format('MMM \'YY'));
  }

  @computed('outflows.[]')
  get outflowCategories() {
    return this.outflows.map((outflow) => moment.utc(outflow.get('period.period.start'), 'YYYY-MM-DD HH:mm:ss').format('MMM \'YY'));
  }

  @computed('inflowCategories.[]', 'outflowCategories.[]')
  get categories() {
    return uniq([...this.inflowCategories, ...this.outflowCategories]);
  }

  @sort('model.cashInflows', function(a, b) {
    return moment.utc(a.get('period.period.start'), 'YYYY-MM-DD HH:mm:ss').unix() - moment.utc(b.get('period.period.start'), 'YYYY-MM-DD HH:mm:ss').unix();
  })
  sortedCashInflows;

  @sort('model.cashOutflows', function(a, b) {
    return moment.utc(a.get('period.period.start'), 'YYYY-MM-DD HH:mm:ss').unix() - moment.utc(b.get('period.period.start'), 'YYYY-MM-DD HH:mm:ss').unix();
  })
  sortedCashOutflows;

  @computed('sortedCashInflows.[]', 'sortedCashOutflows.[]')
  get inflows() {
    let inflows = [];
    let [firstOutflow] = this.sortedCashOutflows;
    this.sortedCashInflows.forEach((inflow, index) => {
      if (index === 0) {
        if (this._momentFor(inflow).isAfter(this._momentFor(firstOutflow))) {
          inflows.push(EmberObject.create({ value: 0, period: firstOutflow.get('period') }));
          while (this._momentFor(inflow).subtract(1, 'month').isAfter(this._momentFor(inflows.slice(-1).pop()))) {
            let date = this._momentFor(inflows.slice(-1).pop()).add(1, 'month');
            inflows.push(EmberObject.create({ value: 0, period: { period: { start: date.format('YYYY-MM-DD HH:mm:ss') } } }));
          }
        }

        inflows.push(inflow);
        return;
      }

      while (this._momentFor(inflow).subtract(1, 'month').isAfter(this._momentFor(inflows.slice(-1).pop()))) {
        let date = this._momentFor(inflows.slice(-1).pop()).add(1, 'month');
        inflows.push(EmberObject.create({ value: 0, period: { period: { start: date.format('YYYY-MM-DD HH:mm:ss') } } }));
      }

      inflows.push(inflow);
    });
    return inflows;
  }

  @computed('sortedCashInflows.[]', 'sortedCashOutflows.[]')
  get outflows() {
    let outflows = [];
    let [firstInflow] = this.sortedCashInflows;
    this.sortedCashOutflows.forEach((outflow, index) => {
      if (index === 0) {
        if (this._momentFor(outflow).isAfter(this._momentFor(firstInflow))) {
          outflows.push(EmberObject.create({ directLaborCost: 0, overheadCost: 0, period: firstInflow.get('period') }));
          while (this._momentFor(outflow).subtract(1, 'month').isAfter(this._momentFor(outflows.slice(-1).pop()))) {
            let date = moment.utc(outflows.slice(-1).pop().get('period.period.start'), 'YYYY-MM-DD HH:mm:ss').add(1, 'month');
            outflows.push(EmberObject.create({ directLaborCost: 0, overheadCost: 0, billableHours: 0, period: { period: { start: date.format('YYYY-MM-DD HH:mm:ss') } } }));
          }
        }

        outflows.push(outflow);
        return;
      }

      while (this._momentFor(outflow).subtract(1, 'month').isAfter(this._momentFor(outflows.slice(-1).pop()))) {
        let date = this._momentFor(outflows.slice(-1).pop()).add(1, 'month');
        outflows.push(EmberObject.create({ directLaborCost: 0, overheadCost: 0, billableHours: 0, period: { period: { start: date.format('YYYY-MM-DD HH:mm:ss') } } }));
      }

      outflows.push(outflow);
    });
    return outflows;
  }

  @computed('inflows.[]', 'outflows.[]')
  get profits() {
    let profits = [];
    this.inflowValues.forEach((revenue, index) => {
      profits.push(revenue - ((this.directCosts[index] + this.overheadCosts[index]) || 0));
    });
    return profits;
  }

  @mapBy('inflows', 'value')
  inflowValues;

  @mapBy('outflows', 'directLaborCost')
  directCosts;

  @mapBy('outflows', 'overheadCost')
  overheadCosts;

  @mapBy('outflows', 'billableHours')
  hoursBillable;

  _momentFor(flow) {
    if (flow) {
      return moment.utc(flow.get('period.period.start'), 'YYYY-MM-DD HH:mm:ss');
    } else {
      return null;
    }
  }

  @computed('inflows.[]', 'outflows.[]', 'categories.[]')
  get chartOptions() {
    return {
      chart: {
        zoomType: 'xy',
      },

      title: {
        text: null
      },

      xAxis: [
        {
          categories: this.categories,
          crosshair: true,
        },
      ],

      yAxis: [{
        type: 'arespline',
        title: {
          text: 'Cash',
        },

        min: 0
      }, {
        type: 'column',
        title: {
          text: 'Hours'
        },

        min: 0,
        opposite: true
      }],

      tooltip: {
        shared: true,
      },

      legend: {
        layout: 'vertical',
        floating: false,
        backgroundColor: '#FFFFFF',
      },
    };
  }

  @computed('dataSeries.{worked,paid,contractors}')
  get chartData() {
    return [
      {
        name: 'Revenue',
        type: 'areaspline',
        tooltip: {
          valueDecimals: 0,
          valueSuffix: ' zł'
        },

        data: this.inflowValues
      },
      {
        name: 'Direct Cost',
        type: 'areaspline',
        tooltip: {
          valueDecimals: 0,
          valueSuffix: ' zł'
        },

        data: this.directCosts,
        stacking: 'normal',
        visible: false
      },
      {
        name: 'Overhead Cost',
        type: 'areaspline',
        tooltip: {
          valueDecimals: 0,
          valueSuffix: ' zł'
        },

        data: this.overheadCosts,
        stacking: 'normal',
        visible: false
      },
      {
        name: 'Profit',
        type: 'areaspline',
        tooltip: {
          valueDecimals: 0,
          valueSuffix: ' zł'
        },

        data: this.profits
      },
      {
        name: 'Billable Hours',
        type: 'column',
        tooltip: {
          valueDecimals: 0,
          valueSuffix: ' h'
        },

        data: this.hoursBillable,
        visible: false,
        yAxis: 1
      }
    ];
  }
}
