import { alias } from '@ember/object/computed';
import BaseComponent from 'selleo-dashboard/components/finance/effective-hours/base-component/component';

export default class Quarterly extends BaseComponent {
  @alias('effectiveHoursPeriod')
  categories;

  aggregateByKey(year, month) {
    return `${this._whichQuarter(month)} '${year % 100}`;
  }

  _whichQuarter(month) {
    let quarter = null;
    if (month <= 3) {
      quarter = 'I';
    }

    if (month >= 4 && month <= 6) {
      quarter = 'II';
    }

    if (month >= 7 && month <= 9) {
      quarter = 'III';
    }

    if (month >= 10 && month <= 12) {
      quarter = 'IV';
    }

    return quarter;
  }
}

