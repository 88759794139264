import { inject as service } from '@ember/service';
import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';

export default class IndexRoute extends AuthenticatedRoute {
  @service
  session;

  model() {
    return this.get('session.currentUser');
  }

  afterModel(model) {
    if (model.serviceDeliveryMember && model.get('serviceDeliveryMember.role.code') === 'leader') {
      this.transitionTo('d.team');
    } else {
      this.transitionTo('d.personal');
    }
  }
}

