import DS from 'ember-data';

const {
  Model,
  attr,
  belongsTo
} = DS;

export default class Constituent extends Model {
  @attr('string')
  name;

  @attr('string')
  description;

  @belongsTo('level')
  level;
}
