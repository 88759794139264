import { tagName, layout as templateLayout } from '@ember-decorators/component';
import { mapBy } from '@ember/object/computed';
import Component from '@ember/component';
import EmberObject, { computed } from '@ember/object';
import { groupBy, round } from 'lodash';
import layout from '../chart-layout/template';

export default

@tagName('')
@templateLayout(layout)
class Yearly extends Component {
  @computed('model.[]')
  get stats() {
    let { model } = this;
    return groupBy(model.toArray(), (stat) => `'${stat.get('year') % 100}`);
  }

  @computed('stats.[]')
  get categories() {
    return Object.keys(this.stats);
  }

  @computed
  get data() {
    let yearStats = Object.values(this.stats);
    return yearStats.map((stats) => {
      let series = EmberObject.create(stats.reduce((acc, stat) => {
        let { contractors, hoursBillable, hoursNonBillable } = stat;
        if (stat.get('teamType') === 'Team::ServiceDelivery') {
          acc.serviceDelivery += contractors;
          acc.deliveryBillable += hoursBillable;
          acc.deliveryNonBillable += hoursNonBillable;
        } else {
          acc.operations += contractors;
          acc.operationsBillable += hoursBillable;
          acc.operationsNonBillable += hoursNonBillable;
        }

        return acc;
      }, {
        operations: 0,
        serviceDelivery: 0,
        deliveryBillable: 0,
        operationsBillable: 0,
        deliveryNonBillable: 0,
        operationsNonBillable: 0
      }));

      for (let key of Object.keys(series)) {
        series[key] = Math.round(series[key] / stats.length * 2);
      }

      series.deliveryUtilization = round(series.deliveryBillable / (series.deliveryBillable + series.deliveryNonBillable) * 100.0, 2);
      series.utilization = round((series.deliveryBillable + series.operationsBillable) / (series.deliveryBillable + series.deliveryNonBillable + series.operationsBillable + series.operationsNonBillable) * 100.0, 2);
      return series;
    });
  }

  @mapBy('data', 'operations')
  operations;

  @mapBy('data', 'serviceDelivery')
  serviceDelivery;

  @mapBy('data', 'deliveryBillable')
  deliveryBillable;

  @mapBy('data', 'operationsBillable')
  operationsBillable;

  @mapBy('data', 'deliveryNonBillable')
  deliveryNonBillable;

  @mapBy('data', 'operationsNonBillable')
  operationsNonBillable;

  @mapBy('data', 'deliveryUtilization')
  deliveryUtilization;

  @mapBy('data', 'utilization')
  utilization;

  @computed('data.[]')
  get dataSeries() {
    return {
      operations: this.operations,
      serviceDelivery: this.serviceDelivery,
      deliveryBillable: this.deliveryBillable,
      operationsBillable: this.operationsBillable,
      deliveryNonBillable: this.deliveryNonBillable,
      operationsNonBillable: this.operationsNonBillable,
      deliveryUtilization: this.deliveryUtilization,
      utilization: this.utilization
    };
  }
}
