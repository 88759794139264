import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default class DController extends Controller {
  @service
  session;

  @computed('session.currentUser')
  get team() {
    if (this.session.get('currentUser.serviceDeliveryMember')) {
      return this.session.get('currentUser.serviceDeliveryMember.team');
    } else {
      return null;
    }
  }
}
