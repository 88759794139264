import EmberRouter from '@ember/routing/router';
import config from './config/environment';

class Router extends EmberRouter {
  location = config.locationType;

  rootURL = config.rootURL;
}

Router.map(function() {
  this.route('mm', function() {
    this.route('kudos', function() {
      this.route('contractor', { path: ':contractor_id' }, function() {
        this.route('interactions-stats');
      });
    });
    this.route('kudo-rates');
  });

  this.route('finance', function() {
    this.route('settlements');

    this.route('ctls', function() {});
    this.route('ctls.ctl', { path: 'ctls/:contractor_id' });
    this.route('billable');

    this.route('customers', function() {
    });
    this.route('customers.customer', { path: 'customers/:customer_id' }, function() {
      this.route('timeline');
      this.route('team');
      this.route('finance');
    });
  });

  this.route('login');
  this.route('logout');

  this.route('schedule', function() {
    this.route('booking-exceptions');
    this.route('report', function() {
      this.route('period');
    });
    this.route('import-time-entries');
  });

  this.route('teams', function() {
    this.route('my');
    this.route('team', { path: '/:team_id' }, function() {
      this.route('finance');
      this.route('happix');
      this.route('schedule');
      this.route('oshee');
    });
    this.route('orphans');
  });
  this.route('d', function() {
    this.route('personal');
    this.route('team');
  });
  this.route('portfolio');
  this.route('terms');
});

export default Router;
