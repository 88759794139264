import DS from 'ember-data';
import moment from 'moment';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';

const {
  Model,
  attr
} = DS;

export default class PayrollPeriod extends Model {
  @attr('string')
  name;

  @attr('date-range')
  period;

  startOn() {
    return moment.utc(this.period.start, 'YYYY-MM-DD HH:mm:ss');
  }

  // whats below, could be seperate module

  @alias('month') monthly;

  @computed('period.start')
  get month() {
    let startOn = this.startOn();

    return {
      label: startOn.format('MMM `YY'),
      index: startOn.format('YYYY-MM')
    };
  }

  @alias('quarter') quarterly;

  @computed('period.start')
  get quarter() {
    let startOn = this.startOn();
    let toRoman = ['', 'I', 'II', 'III', 'IV'];

    return {
      label: startOn.format(`${toRoman[startOn.quarter()]} \`YY`),
      index: startOn.format('YYYY-Q')
    };
  }

  @alias('semester') semesterly;

  @computed('period.start')
  get semester() {
    let startOn = this.startOn();
    let semester = Math.ceil(((startOn.month() + 1) / 6.0));
    let toRoman = ['', 'I', 'II'];

    return {
      label: startOn.format(`${toRoman[semester]} \`YY`),
      index: startOn.format(`YYYY-${semester}`)
    };
  }

  @alias('year') yearly;

  @computed('period.start')
  get year() {
    let startOn = this.startOn();

    return {
      label: startOn.format('`YY'),
      index: startOn.format('YYYY')
    };
  }
}
