import { alias } from '@ember/object/computed';
import BaseComponent from 'selleo-dashboard/components/finance/effective-hours/base-component/component';

export default class Yearly extends BaseComponent {
  @alias('effectiveHoursPeriod')
  categories;

  aggregateByKey(year) {
    return `'${year % 100}`;
  }
}

