import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

export default @tagName('') class Chart extends Component {
  @computed('quarters')
  get categories() {
    return this.quarters.map((c) => `${c[0]} Q${c[1]}`);
  }

  @computed('categories.[]')
  get chartOptions() {
    return {
      data: {
        categories: this.categories,
        team: this.teamData,
        company: this.companyData
      },

      chart: {
        zoomType: 'xy',
        height: 220
      },

      title: {
        text: 'Customer Satisfaction'
      },

      plotOptions: {
        series: {
          connectNulls: true
        }
      },

      xAxis: [
        {
          categories: this.categories,
          crosshair: true
        }
      ],

      yAxis: [
        {
          min: 8,
          max: 9,
          tickInterval: 1,
          gridlinewidth: 0,
          title: {
            text: 'Net Promotor Score',
            style: {
              color: 'rgb(46,93, 170, 1)'
            }
          },

          opposite: true,
          labels: {
            format: '{value} NPS',
            style: {
              color: 'rgb(46,93, 170, 1)'
            }
          }
        },
        {
          min: 80,
          max: 90,
          tickInterval: 10,
          labels: {
            format: '{value}%',
            style: {
              color: 'black'
            }
          },

          title: {
            text: 'Quality',
            style: {
              color: 'black'
            }
          },

          plotBands: [
            {
              from: 90,
              to: 100,
              color: 'rgb(0,255,0,0.15)',
              label: false
            }
          ]
        }
      ],

      tooltip: {
        shared: true,
        useHTML: true,
        formatter: function() {
          let component = this;
          return function() {
            let index = component.categories.indexOf(this.x);

            return (
              `<h5>${this.x}</h5>
            <table>` +
              `<thead><tr>
              <th></th>
              <th style="width: 150px;">team</th>
              <th style="width: 50px;">company</th>
            </tr></thead>` +
              `<tr>
              <td style="color: blue">NPS: </td>
              <td style="text-align: right"><b>${component.teamData.nps[index] || 0 }</b></td>
              <td style="text-align: right"><b>${component.companyData.nps[index]}</b></td>
            </tr>` +
              `<tr>
              <td style="color: orange">Service: </td>
              <td style="text-align: right"><b>${component.teamData.qaService[index] || 0 }%</b></td>
              <td style="text-align: right"><b>${component.companyData.qaService[index]}%</b></td>
            </tr>` +
            `<tr>
            <td style="color: green">Development: </td>
            <td style="text-align: right"><b>${component.teamData.qaDevelopment[index] || 0 }%</b></td>
            <td style="text-align: right"><b>${component.companyData.qaDevelopment[index]}%</b></td>
          </tr>` +
              `<tr>
            <td style="color: red">Software: </td>
            <td style="text-align: right"><b>${component.teamData.qaSoftware[index] || 0 }%</b></td>
            <td style="text-align: right"><b>${component.companyData.qaSoftware[index]}%</b></td>
            </tr>` +
              `<tr>
            <td style="color: black">Overall: </td>
            <td style="text-align: right"><b>${component.teamData.overall[index] || 0 }%</b></td>
            <td style="text-align: right"><b>${component.companyData.overall[index]}%</b></td>
            </tr>` +
              `<tr>
            <td style="color: black">Number of Surveys: </td>
            <td style="text-align: right"><i>${component.teamData.numberOfSurveys[index] || 0 }</i></td>
            <td style="text-align: right"><i>${component.companyData.numberOfSurveys[index]}</i></td>
            </tr>`
            );
          };
        }.bind(this)()
      },

      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'middle',
        floating: false,
        backgroundColor: '#FFFFFF'
      }
    };
  }

  @computed('teamData.[],companyData.[]')
  get chartData() {
    return [
      {
        name: 'NPS S.',
        type: 'column',
        yAxis: 0,
        color: 'rgb(249,113, 4, 0.75)',
        maxPointWidth: 25,
        data: this.companyData.nps,
        tooltip: {
          valueSuffix: ' NPS'
        }
      },
      {
        name: 'NPS T.',
        type: 'column',
        yAxis: 0,
        color: 'rgb(0, 138, 216, 0.75)',
        maxPointWidth: 25,
        data: this.teamData.nps,
        tooltip: {
          valueSuffix: ' NPS'
        }
      },
      {
        name: 'Overall S.',
        type: 'line',
        color: 'rgb(249,113, 4, 1)',
        yAxis: 1,
        data: this.companyData.overall,
        tooltip: {
          valueSuffix: '%'
        }
      },
      {
        name: 'Overall T.',
        type: 'line',
        color: 'rgb(0, 138, 216, 1)',
        yAxis: 1,
        data: this.teamData.overall,
        tooltip: {
          valueSuffix: '%'
        }
      },
      {
        name: 'Development S.',
        type: 'line',
        visible: false,
        color: 'rgb(249,113, 4, 1)',
        dashStyle: 'dashdot',
        yAxis: 1,
        data: this.companyData.qaDevelopment,
        tooltip: {
          valueSuffix: '%'
        }
      },
      {
        name: 'Development T.',
        type: 'line',
        visible: false,
        dashStyle: 'dashdot',
        color: 'rgb(0, 138, 216, 1)',
        yAxis: 1,
        data: this.teamData.qaDevelopment,
        tooltip: {
          valueSuffix: '%'
        }
      },
      {
        name: 'Service S.',
        type: 'line',
        visible: false,
        color: 'rgb(249,113, 4, 1)',
        dashStyle: 'dot',
        yAxis: 1,
        data: this.companyData.qaService,
        tooltip: {
          valueSuffix: '%'
        }
      },
      {
        name: 'Service T.',
        type: 'line',
        visible: false,
        dashStyle: 'dot',
        color: 'rgb(0, 138, 216, 1)',
        yAxis: 1,
        data: this.teamData.qaService,
        tooltip: {
          valueSuffix: '%'
        }
      },
      {
        name: 'Software S.',
        type: 'line',
        visible: false,
        color: 'rgb(249,113, 4, 1)',
        dashStyle: 'longdash',
        yAxis: 1,
        data: this.companyData.qaSoftware,
        tooltip: {
          valueSuffix: '%'
        }
      },
      {
        name: 'Software T.',
        type: 'line',
        visible: false,
        color: 'rgb(0, 138, 216, 1)',
        dashStyle: 'longdash',
        yAxis: 1,
        data: this.teamData.qaSoftware,
        tooltip: {
          valueSuffix: '%'
        }
      }
    ];
  }
}
