import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default class KudosController extends Controller {
  @service
  router;

  // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
  searchResults = [];

  searchText = '';
}
