import { helper } from '@ember/component/helper';

export function periodDisplayer(params) {
  if (!params.includes(undefined)) {
    switch (params[0]) {
      case 1: return 'day';
      case 7: return 'week';
      case 30: return 'month';
      case 91: return 'quarter';
      case 365: return 'year';
      case 9999: return 'all';
    }
  }
}

export default helper(periodDisplayer);
