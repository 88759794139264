import DS from 'ember-data';

const {
  Model,
  belongsTo
} = DS;

export default class Member extends Model {
  @belongsTo('contractor', { inverse: null })
  contractor;

  @belongsTo('team')
  team;

  @belongsTo('role')
  role;
}
