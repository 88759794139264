import DS from 'ember-data';

const {
  Model,
  attr,
  belongsTo
} = DS;

export default class ContractorCompetenceStat extends Model {
  @attr('number')
  workload;

  @belongsTo('contractor')
  contractor;

  @belongsTo('period')
  period;

  @belongsTo('competence')
  competence;
}
