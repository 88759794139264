import DS from 'ember-data';

const { Model, attr, belongsTo } = DS;

export default class CustomerAccountMetaCustomer extends Model {
  @attr
  name;

  @belongsTo('customer-account-meta')
  meta;

  @belongsTo('customer')
  customerAccount;
}
