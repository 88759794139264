import DS from 'ember-data';

const { Model, attr, belongsTo } = DS;

export default class Event extends Model {
  @attr()
  label;

  @attr()
  value;

  @attr()
  date;

  @attr()
  unit;

  @attr()
  options;

  @attr()
  category;

  @belongsTo()
  invoice;

  @belongsTo()
  payment;

  @belongsTo()
  booking;

  @belongsTo()
  worked;

  @belongsTo()
  customer;
}
