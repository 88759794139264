import DS from 'ember-data';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';

const { Model, attr, belongsTo, hasMany } = DS;

export default Model.extend({
  name: attr('string'),
  uid: attr('string'),
  active: attr('boolean'),
  email: attr('string'),
  nickname: attr('string'),
  avatarUrl: attr('string'),
  isTeamLeader: attr('boolean'),
  happix: attr('number'),
  utilizationRateCurrentMonth: attr('number'),
  utilizationRateLastMonth: attr('number'),
  teamUtilizationCurrentMonth: attr('number'),
  teamUtilizationLastMonth: attr('number'),

  nick: computed('email', function() {
    return this.email.split('@')[0];
  }),

  kudosGiven: hasMany('merit-money-kudo', { inverse: 'giver' }),
  kudosReceived: hasMany('merit-money-kudo', { inverse: 'receiver' }),
  kudosInteractionsGiven: hasMany('merit-money-interactions-stat', { inverse: 'giver' }),
  kudosInteractionsReceived: hasMany('merit-money-interactions-stat', {
    inverse: 'receiver'
  }),

  bookingExceptions: hasMany('schedule-booking-exception'),
  meritWeeks: hasMany('merit-money-contractor-week'),
  customers: hasMany(),
  worked: hasMany(),
  ratings: hasMany(),
  meritMoneyWeeklyKudosReceived: hasMany(),
  meritMoneyMonthlyKudosReceived: belongsTo('merit-money-monthly-kudos-received', {
    inverse: 'receiver'
  }),

  meritMoneyHamstersAndTopCollector: belongsTo('merit-money-hamsters-and-top-collector'),
  teamMembers: hasMany('member'),
  serviceDeliveryMember: belongsTo('member'),

  // this is weird solution
  kudosLeft: alias('meritMoneyHamstersAndTopCollector.kudosLeft'),
  hamster: alias('meritMoneyHamstersAndTopCollector.hamster'),
  topCollector: alias('meritMoneyHamstersAndTopCollector.topCollector'),

  preferredCompetences: attr(),
});
