import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';

export default @tagName('') class Chart extends Component {
  @computed('data.[]', 'selectedDate')
  get chartOptions() {
    return {
      chart: {
        type: 'bar',
        height: (this.data.length > 5 ? (this.data.length * 52) + 10 : 270)
      },

      legend: {
        enabled: false
      },

      colors: ['#FF6D2A', 'rgb(94,162,210)'],
      title: {
        text: 'Team Utilization'
      },

      subtitle: {
        text: `(% in ${this.selectedDate ?? 'current month'})`
      },

      xAxis: {
        categories: this.data,
        labels: {
          useHTML: true,
          formatter() {
            let avatarHTML = `<img class="highcharts__avatar" alt="${this.value.label}" src="${this.value.avatarUrl}" />`;

            if (this.value.linkUrl) {
              return `<a href="${this.value.linkUrl}">${avatarHTML}</a>`;
            } else {
              return avatarHTML;
            }
          },
        }
      },

      yAxis: {
        min: 0,
        title: {
          enabled: false
        },

        plotBands: [
          {
            from: 90,
            to: 100,
            color: 'rgb(0,255,0,0.15)',
            label: {
              align: 'center',
              verticalAlign: 'top',
              rotation: 0,
              y: -5,
              text: 'target'
            }
          }
        ]
      },

      tooltip: {
        headerFormat: '<span style="font-size: 12px">{point.key.label}</span><br/>',
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}h</b> ({point.percentage:.0f}%)<br/>',

        shared: true
      },

      plotOptions: {
        bar: {
          stacking: 'percent',
          // width
          pointWidth: 24,
          pointPadding: 0,
        }
      },

      annotations: [
        {
          labelOptions: {
            verticalAlign: 'top',
            y: -8
          },

          labels: this.data.map((d, i) => {
            return {
              point: { xAxis: 0, yAxis: 0, x: i, y: 5 },
              text: `${d.percentage}%`
            };
          })
        },
        {
          labelOptions: {
            verticalAlign: 'top',
            y: -8
          },

          labels: this.data.map((d, i) => {
            let y = d.percentage < 20 ? 20 : d.percentage;

            return {
              point: { xAxis: 0, yAxis: 0, x: i, y },
              text: `${d.hoursBillable}h of ${d.hoursAll}h`
            };
          })
        }
      ]
    };
  }

  @computed('data.[]')
  get chartData() {
    return [
      {
        name: 'Nonbillable',
        data: this.data.map((d) => d.hoursNonBillable)
      },
      {
        name: 'Billable',
        data: this.data.map((d) => d.hoursBillable)
      }
    ];
  }
}
