import { classNames, tagName } from '@ember-decorators/component';
import Component from '@ember/component';

export default

@tagName('span')
@classNames('layout-column', 'customer-account-stats')
class ForProject extends Component {
  'data-test-projects' = true;
}
