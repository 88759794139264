import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';
import RSVP from 'rsvp';

const { hash } = RSVP;

export default class ScheduleRoute extends AuthenticatedRoute {
  model() {
    // eslint-disable-next-line camelcase
    let { team_id } = this.paramsFor('teams.team');
    return hash({
      weeklyStats: this.store.query('schedule-weekly-stat', { filter: { team_id }, include: 'customer,contractor' }),
      monthlyStats: this.store.query('schedule-monthly-stat', { filter: { team_id }, include: 'customer,contractor' })
    });
  }
}
