import Model, { belongsTo, attr } from '@ember-data/model';

export default class TermConfirmationModel extends Model {
  @attr('number')
  termId;

  @belongsTo('term')
  term;

  @belongsTo('contractor')
  contractor;
}
