import DS from 'ember-data';
import { belongsTo } from 'ember-data/relationships';

const { Model, attr } = DS;

export default class ScheduleRemainingOsheeRelaxing extends Model {
  @belongsTo('contractor')
  contractor;

  @attr('number')
  daysLeft;

  @attr('number')
  maxDays;

  @attr()
  usedDays;
}
