import { computed } from '@ember/object';
import DS from 'ember-data';
import { belongsTo } from 'ember-data/relationships';
import { inPercentage } from 'selleo-dashboard/helpers/in-percentage';

const { Model, attr } = DS;

export default class ScheduleWeeklyPerCustomerUtilizationStat extends Model {
  @belongsTo('contractor')
  contractor;

  @belongsTo('customer')
  customer;

  @belongsTo('merit-money-week')
  week;

  @attr('number')
  workedMinutes;

  @attr('number')
  workedMinutesTarget;

  @attr('number')
  billableMinutes;

  @attr('number')
  billableMinutesTarget;

  @computed('workedMinutes,paidMinutes')
  get percentage() {
    return inPercentage([this.billableMinutes, this.workedMinutes]);
  }
}
