import DS from 'ember-data';

const { Model, attr, belongsTo } = DS;

export default class ScheduleBookingAccessToken extends Model {
  @attr
  name;

  @attr
  url;

  @belongsTo('team')
  team;

  @belongsTo('customer-account-meta')
  customerAccountMeta;
}
