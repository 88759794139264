import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';
import RSVP from 'rsvp';
import { orderBy } from 'lodash';

export default class KudosRoute extends AuthenticatedRoute {
  model() {
    return RSVP.hash({
      currentContractorWeek: this.store.findRecord('merit-money-contractor-week', 0, {
        include:
          'week,contractor.merit_money_weekly_kudos_received.week,contractor.merit_money_monthly_kudos_received.receiver'
      }),

      teams: this.store.query('team', {
        include: 'members,members.contractor,members.role',
        filter: { service_delivery: true },
        sort: 'id'
      }),

      contractors: this.store.query('contractor', {
        filter: { for_merit_money: true },
        include: [
          'merit_money_hamsters_and_top_collector',
          'kudos_given',
          'kudos_received.week',
          'kudos_received.receiver',
          'kudos_received.giver',
        ].join(',')
      }),

      currentContractor: this.session.currentUser,

    });
  }

  afterModel(model) {
    model.contractors = orderBy(model.contractors.toArray(), ['name'], ['asc']);
  }
}
