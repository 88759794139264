import { inject as service } from '@ember/service';
import Component from '@ember/component';
import EmberObject, { action, computed } from '@ember/object';
import { filter } from '@ember/object/computed';
import { groupBy, keyBy, sortBy, sumBy, sum, orderBy, uniq, flatten, compact, round, some } from 'lodash';
import { inPercentage } from 'selleo-dashboard/helpers/in-percentage';

export default class Dashboard extends Component {
  @service
  store;

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);

    this.set('filters', EmberObject.create({
      'startWeek': 0,
      'endWeek': this.weeks.length - 1,
      start: [0, this.weeks.length - 1],
      range: {
        min: 0,
        max: this.weeks.length - 1
      },

      selectedTechnologies: []
    }));
  }

  @filter('teams', function(team) {
    return team.members.length > 0;
  })
  filteredTeams;

  @action
  periodChanged([startWeek, endWeek]) {
    this.set('filters.startWeek', startWeek);
    this.set('filters.endWeek', endWeek);
  }

  @computed('weeks')
  get columns() {
    return [
      {
        name: '',
        valuePath: 'data',
        width: 200,
      },
      ...this.weeks.map((week) => {
        return {
          week,
          name: `#${week.week} w.`,
          stats: this._weeklyStats[week.get('id')],
          valuePath: `week-${week.id}`,
          headerComponent: 'portfolio-d/overview/cell-week-header',
          columnComponent: 'portfolio-d/overview/cell-billable',
          width: 50,
        };
      }),
    ];
  }

  @computed('filteredTeams.[]')
  get rows() {
    return sortBy(this.filteredTeams.toArray(), (t) => t.id)
      .map((team) => this._treeFromTeam(team));
  }

  @computed('utilizations.[]')
  get weeks() {
    return orderBy(
      compact(uniq(this.utilizations.toArray().map((utilization) => utilization.get('week.id'))))
        .map((weekId) => this.store.peekRecord('merit-money-week', weekId)),
      ['year', 'week'],
      ['asc', 'asc']
    );
  }

  @computed('weeks,utilizations')
  get utilizationsByWeekAndContractor() {
    return groupBy(this.utilizations.toArray(), (utilization) => [
      utilization.get('week.id'),
      utilization.get('contractor.id'),
    ]);
  }

  @computed('filteredTeams.[]')
  get contractorIdsByTeam() {
    return this.filteredTeams.toArray().reduce((memo, team) => {
      memo[team.get('id')] = team.get('members').toArray().map((member) => member.get('contractor.id'));
      return memo;
    }, {});
  }

  @computed('weeks,utilizations')
  get _weeklyStats() {
    return keyBy(this.weeks.map((week) => {
      let utilizationsGroupByContractor = compact(flatten(Object.values(this.contractorIdsByTeam)).map((contractorId) => {
        return this.utilizationsByWeekAndContractor[`${week.get('id')},${contractorId}`];
      }));

      return TopLevelStatsForWeek.create({
        week,
        utilizationsByContractor: keyBy(utilizationsGroupByContractor, (utilizations) => utilizations[0].get('contractor.name'))
      });
    }),
    (element) => element.week.get('id'));
  }

  _teamWeeklyStats(team, week) {
    let utilizationsGroupByContractor = compact(this.contractorIdsByTeam[team.get('id')].map((contractorId) => {
      return this.utilizationsByWeekAndContractor[`${week.get('id')},${contractorId}`];
    }));

    return TopLevelStatsForWeek.create({
      week,
      utilizationsByContractor: keyBy(utilizationsGroupByContractor, (utilizations) => utilizations[0].get('contractor.name'))
    });
  }

  _treeFromTeam(team) {
    let node = {
      element: team,
      data: team.get('name'),
      name: team.get('name'),
      passFilter() {
        return true;
      },

      children: sortBy(
        team
          .get('members')
          .map((child) => this._nodeFromContractor(child.contractor))
        ,
        (o) => o.percentage
      ),
    };

    this.weeks.forEach((week) => {
      node[`week-${week.id}`] = {
        cellComponent: 'portfolio-d/overview/cell-team-header',
        stats: this._teamWeeklyStats(team, week)
      };
    });

    return node;
  }

  _nodeFromContractor(contractor) {
    let node = {
      data: {
        cellComponent: 'portfolio-d/overview/cell-contractor-header',
        contractor,
        filters: this.filters
      },

      element: contractor,
      weeks: this.weeks,
      filters: this.filters,
      name: contractor.get('name'),
      billableMinutes: 0,
      workedMinutes: 0,
      percentage: null,
      passFilter() {
        let periodFilter = true;

        let { startWeek, endWeek } = this.filters;

        if (startWeek === 0) {
          periodFilter = true;
        } else {
          let weeks = this.weeks.slice(startWeek, endWeek + 1)
            .map((week) => this[`week-${week.id}`]);
          periodFilter = some(weeks, (e) => e && e.available());
        }

        let technologyFilter =  true;
        let selectedTechnologiesIds = this.filters.selectedTechnologies.map((st) => st.id);

        if (selectedTechnologiesIds.length > 0) {
          technologyFilter = some(selectedTechnologiesIds, (tCId) => contractor.get('preferredCompetences').includes(tCId));
        }

        return periodFilter && technologyFilter;
      }
    };

    this.weeks.forEach((week) => {
      let utilizations = this.utilizationsByWeekAndContractor[
        `${week.get('id')},${contractor.get('id')}`
      ];
      if (utilizations) {
        node[`week-${week.id}`] = UtilizationsStat.create({ utilizations, week });
        node.workedMinutes += node[`week-${week.id}`].workedMinutes;
        node.workedMinutesTarget += node[`week-${week.id}`].workedMinutesTarget;
        node.billableMinutes += node[`week-${week.id}`].billableMinutes;
        node.billableMinutesTarget += node[`week-${week.id}`].billableMinutesTarget;
        node.percentage += node[`week-${week.id}`].percentage;
      }
    });
    return node;
  }
}

class UtilizationsStat extends EmberObject {
  utilizations = null;

  week = null;

  @computed
  get billableMinutes() {
    return sumBy(this.utilizations, 'billableMinutes');
  }

  @computed
  get billableMinutesTarget() {
    return sumBy(this.utilizations, 'billableMinutesTarget');
  }

  @computed
  get workedMinutes() {
    return sumBy(this.utilizations, 'workedMinutes');
  }

  @computed
  get workedMinutesTarget() {
    return sumBy(this.utilizations, 'workedMinutesTarget');
  }

  @computed('billableMinutes,workedMinutes')
  get percentage() {
    if (this.workedMinutes > 0) {
      return inPercentage([this.billableMinutes, this.workedMinutes]);
    } else {
      return inPercentage([this.billableMinutesTarget, this.workedMinutesTarget]);
    }
  }

  available() {
    return (this.workedMinutesTarget > 0) && (this.percentage < 90);
  }
}

class TopLevelStatsForWeek extends EmberObject {
  week = null;

  @computed('utilizationsByContractor')
  get utilizations() {
    return flatten(Object.values(this.utilizationsByContractor));
  }

  @computed('utilizationsByContractor')
  get utilizationsBelowNinety() {
    let weekPerContractorUtilization = function(contractor, utilizations) {
      return UtilizationsStat.create({
        contractor,
        utilizations
      });
    };

    return sortBy(
      Object.entries(this.utilizationsByContractor)
        .filter(([, utilizations]) => {
          return UtilizationsStat.create({ utilizations }).available();
        })
        .map(([contractor, utilizations]) => weekPerContractorUtilization(contractor, utilizations)),
      (ut) => ut.percentage);
  }

  @computed('totalWorkedMinutes,totalBillableMinutes')
  get percentage() {
    return UtilizationsStat.create({ utilizations: flatten(Object.values(this.utilizationsByContractor)) }).percentage;
  }

  @computed('utilizations.[]')
  get fteIdle() {
    return round(((90 * Object.keys(this.utilizationsBelowNinety).length) -
    sum(this.utilizationsBelowNinety.map((u) => u.percentage))) / 90.0, 1);
  }

  @computed('utilizations.[]')
  get fteAll() {
    return Object.keys(this.utilizationsByContractor).length;
  }
}
