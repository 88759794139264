import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';
import { hash } from 'rsvp';
import { upperFirst, uniqBy, difference } from 'lodash';

export default class IndexRoute extends AuthenticatedRoute {

  queryParams = {
    scope: {
      refreshModel: true,
    },
  };

  model(params) {
    let { teams, orphans, utilizations, surveys } = this.modelFor('teams');
    let renderableTeams;

    if (params.scope !== 'all') {
      renderableTeams = teams.filter(
        (team) => team.type === `Team::${upperFirst(params.scope)}`
      );
      orphans = [];
    } else {
      renderableTeams = [...teams];
    }

    let teamsForCharts = [...renderableTeams];

    if (params.scope === 'serviceDelivery') {
      let servantLeadershipMembers = uniqBy(
        teams
          .filter((team) => team.type === 'Team::ServantLeader')
          .map((team) => team.members.toArray())
          .flat(),
        (member) => member.contractor.get('id')
      );
      let servantLeadershipLeaderId = '19';
      let servantLeadershipLeader = servantLeadershipMembers.find(
        (member) => member.contractor.get('id') === servantLeadershipLeaderId
      );

      renderableTeams.push({
        name: 'Servant Leadership',
        leader: servantLeadershipLeader,
        members: servantLeadershipMembers,
        isNotSelectable: true,
        membersWithoutLeader: difference(servantLeadershipMembers, [
          servantLeadershipLeader,
        ]),
      });
    }

    return hash({
      renderableTeams,
      teamsForCharts,
      orphans,
      utilizations,
      surveys,
    });
  }
}
