import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';
import { hash } from 'rsvp';

export default class ReportRoute extends AuthenticatedRoute {
  model() {
    return hash({
      customers: this.store.findAll('customer'),
      contractors: this.store.findAll('contractor')
    });
  }
}
