import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import moment from 'moment';

export default

@classNames('balance')
class Balance extends Component {
  @computed
  get periodStart() {
    return moment().subtract(2, 'weeks').format('YYYY-MM-DD');
  }

  @computed
  get periodEnd() {
    return moment().format('YYYY-MM-DD');
  }
}
