import { classNameBindings } from '@ember-decorators/component';
import { computed } from '@ember/object';
import EmberTr from 'ember-table/components/ember-tr/component';

export default

@classNameBindings('isSelected', 'isGroupSelected', 'isSelectable', 'isHidden')
class _EmberTr extends EmberTr {
  @computed('api.rowValue.filters.{startWeek,endWeek,selectedTechnologies}')
  get isHidden() {
    if (this.api.rowValue && this.api.rowValue.passFilter)  {
      return !this.api.rowValue.passFilter();
    } else {
      return false;
    }
  }
}
