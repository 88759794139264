import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';
import { hash } from 'rsvp';

export default class FinanceRoute extends AuthenticatedRoute {
  model() {
    let { customer, financeStats } = this.modelFor('finance.customers.customer');
    return hash({
      customer,
      cashInflows: this.store.query('finance-customer-cash-inflow', { filter: { customer_id: customer.id }, include: 'customer,period' }),
      cashOutflows: this.store.query('finance-customer-cash-outflow', { filter: { customer_id: customer.id }, include: 'customer,period' }),
      financeStats
    });
  }
}
