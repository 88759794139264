import { computed } from '@ember/object';
import Controller from '@ember/controller';

export default class IndexController extends Controller {
  queryParams = ['scope'];

  scope = 'serviceDelivery';

  @computed('scope')
  get isServiceDelivery() {
    return this.scope === 'serviceDelivery';
  }
}
