import { computed } from '@ember/object';
import DS from 'ember-data';
import moment from 'moment';
import { uniq } from 'lodash';

const {
  Model,
  attr,
  belongsTo
} = DS;

export default class MeritMoneyWeek extends Model {
  @attr('number')
  year;

  @attr('number')
  week;

  @belongsTo('payrollPeriod')
  period;

  @computed('week', 'year')
  get sortOrder() {
    return `${this.year}-W${this.week.toString().padStart(2, '0')}`;
  }

  @computed('week', 'year')
  get monday() {
    return moment(`${this.year}-W${this.week.toString().padStart(2, '0')}-1`);
  }

  @computed('monday')
  get friday() {
    return moment(this.monday).add('4', 'days');
  }

  @computed('week', 'year')
  get currentWeek() {
    return moment().week() === this.week && moment().year() === this.year;
  }

  @computed('monday')
  get month() {
    return uniq([this.monday.format('MMM'), this.friday.format('MMM')]).join('-');
  }
}
