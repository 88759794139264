import { computed } from '@ember/object';
import { orderBy } from 'lodash';
import TeamUtilizationComponent from 'selleo-dashboard/components/schedule/team-utilization/component';

export default class ForTeams extends TeamUtilizationComponent {
  @computed('companyStat,teamStat,teammatesStat.[]')
  get data() {
    let data = [this.companyStat, ...this.teamsStat];

    if (this.filterEmpty) {
      return data.filter((stats) => stats.hoursAll > 0);
    }

    return data;
  }

  @computed('utilizations.[]')
  get companyStat() {
    return {
      label: 'Selleo',
      avatarUrl: '/images/selleo-avatar.jpg',
      ...this._prepareStat(this._sumMinutes(this.utilizations))
    };
  }

  @computed('utilizations.[],team')
  get teamsStat() {
    let teams = this.teams.map((team) => {
      return {
        label: team.name,
        avatarUrl: team.get('leader.contractor.avatarUrl'),
        linkUrl: `/teams/${team.id}`,
        ...this._prepareStat(
          this._sumMinutes(this._contractorUtilizationsForTeam(team))
        )
      };
    });

    return orderBy(teams, ['percentage', 'hoursAll'], ['desc', 'desc']);
  }
}
