import Service from '@ember/service';

export default class ClipboardService extends Service {
  clipboardText = '';

  pasteAndClear() {
    let text = this.clipboardText;
    this.clear();
    return text;
  }

  clear() {
    this.set('clipboardText', '');
  }
}
