import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class IssueSubjectSupport extends Component {
  @service
  paperToaster;

  get currentTeam() {
    return this.filteredTeams[this.args.activeTab];
  }

  get filteredTeams() {
    return this.args.teams?.filter(({ tasks: { length } }) => length > 0) ?? [];
  }

  @action
  notice(text) {
    this.paperToaster.show(`Copied: '${text}'`);
  }
}
