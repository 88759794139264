import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { uniqWith, isEqual } from 'lodash';
import { hash } from 'rsvp';

export default

@classNames('team-css')
class TeamCss extends Component {
  @service
  store;

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);
    this.companyStats = [];
    this.teamStats = [];

    this._loadData();
  }

  @computed('companyStats.[],teamStats.[]')
  get quarters() {
    return uniqWith(
      [
        ...this.companyStats.map((cs) => [cs.year, cs.quarter]),
        ...this.teamStats.map((ts) => [ts.year, ts.quarter])
      ],
      isEqual
    ).sort();
  }

  @computed('quarters.[]')
  get teamData() {
    return this._dataObject(
      this._fetchDataInOrder.bind(this, this.teamStats.toArray())
    );
  }

  @computed('quarters.[]')
  get companyData() {
    return this._dataObject(
      this._fetchDataInOrder.bind(this, this.companyStats.toArray())
    );
  }

  _fetchDataInOrder(collection, key) {
    return this.quarters.map(([year, quarter]) => {
      let stat = collection.find(
        (el) => el.year === year && el.quarter === quarter
      );
      if (stat) {
        return stat[key];
      } else {
        return null;
      }
    });
  }

  _dataObject(func) {
    return {
      overall: func('average'),
      qaService: func('qaService'),
      qaSoftware: func('qaSoftware'),
      qaDevelopment: func('qaDevelopment'),
      nps: func('nps'),
      numberOfSurveys: func('numberOfSurveys')
    };
  }

  _loadData() {
    hash({
      team: this.store.query('css-survey', {
        filter: { for_team: this.team.id, group_for: 'team_avg_quarterly' }
      }),

      company: this.store.query('css-survey', {
        filter: { group_for: 'avg_quarterly' }
      })
    }).then(({ team, company }) => {
      this.set('teamStats', team);
      this.set('companyStats', company);
    });
  }
}
