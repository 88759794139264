import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { sum } from 'lodash';
import moment from 'moment';

export default @classNames('md-padding') class SummaryReport extends Component {
  @service
  store;

  @service
  paperToaster;

  categories = Object.freeze(['billable', 'non-billable', 'all', 'oshee']);

  columns = Object.freeze([
    {
      name: '',
      valuePath: 'labelData',
      width: 600
    },
    {
      name: 'Time Total',
      valuePath: 'minutesInclusive',
      cellComponent: 'schedule/summary-report/cell-hours'
    },
    {
      name: 'Time Exclusive',
      valuePath: 'minutesExclusive',
      cellComponent: 'schedule/summary-report/cell-hours'
    }
  ])

  rows = null;

  @computed('rows')
  get compactRows() {
    if (this.rows) {
      return this._doCompact(this.rows);
    } else  {
      return [];
    }
  }

  @computed('rows')
  get totalMinutes() {
    let { rows } = this;
    return sum(rows.map((row) => row.minutesInclusive));
  }

  @computed('compactRows')
  get isNoResults() {
    return this.compactRows.length === 0;
  }

  period = {};

  @(task(function* () {
    this.set('rows', []);
    let filter = {
      period: [this.period.start, this.period.end].map((d) => moment(d).format('YYYY-MM-DD'))
    };

    if (yield this.get('selectedContractor')) {
      filter.contractor_id = this.selectedContractor.get('id');
    }

    if (yield this.get('selectedCustomer')) {
      filter.customer_id = this.selectedCustomer.get('id');
    }

    if (this.searchText) {
      filter.search_text = this.searchText;
    }

    if (filter.customer_id || filter.contractor_id) {
      let tableData = yield this.store.query('schedule-time-entries-stat', {
        filter,
        include: 'parent,work_unit'
      });

      this.set('tableData', tableData);

      this._startTree();
    } else {
      this.get('paperToaster').show('please select customer or contractor', {
        position: 'top right'
      });
    }
  }).drop())
  fetchReport;

  _startTree() {
    let roots = this.tableData.filter(
      ({ parent }) => parent.get('id') === undefined
    );
    this.set(
      'rows',
      roots.map((root) => this._treeFrom(root))
    );
  }

  _treeFrom({
    workUnit,
    paidMinutesInclusive,
    paidMinutesExclusive,
    workedMinutesInclusive,
    workedMinutesExclusive,
    osheeMinutesInclusive,
    osheeMinutesExclusive
  }) {
    let node = {
      name: workUnit.get('label')
    };
    if (this.category === 'billable') {
      node.minutesInclusive = paidMinutesInclusive;
      node.minutesExclusive = paidMinutesExclusive;
    } else if (this.category === 'all') {
      node.minutesInclusive = workedMinutesInclusive;
      node.minutesExclusive = workedMinutesExclusive;
    } else if (this.category === 'non-billable') {
      node.minutesInclusive = workedMinutesInclusive - paidMinutesInclusive;
      node.minutesExclusive = workedMinutesExclusive - paidMinutesExclusive;
    } else if (this.category === 'oshee') {
      node.minutesInclusive = osheeMinutesInclusive;
      node.minutesExclusive = osheeMinutesExclusive;
    }

    let children = this.tableData.filter(
      ({ parent, workUnit: wu }) =>
        parent.get('id') === workUnit.get('id') && wu.get('id') !== undefined
    );
    if (children.length > 0) {
      node.children = children.map((child) => this._treeFrom(child));
    }

    return node;
  }

  _doCompact(rows) {
    return (rows || []).reduce((acc, rawRow) => {
      if (rawRow.minutesInclusive > 0) {
        let row = Object.assign({}, rawRow);
        if (row.children) {
          let children = this._doCompact(row.children);
          row.children = children;
        }

        row.labelData = [row.name, row.minutesInclusive];
        acc.push(row);
      }

      return acc;
    }, []);
  }

  _whichQuarter(month) {
    let quarter = null;
    if (month <= 3) {
      quarter = 0;
    }

    if (month >= 4 && month <= 6) {
      quarter = 1;
    }

    if (month >= 7 && month <= 9) {
      quarter = 2;
    }

    if (month >= 10 && month <= 12) {
      quarter = 3;
    }

    return quarter;
  }

  _setPeriodTo(unit, last) {
    let time = moment.utc();
    let period;
    if (unit === 'year') {
      let year = last ? time.year() - 1 : time.year();
      period = {
        start: `${year}-01-01`,
        end: last ? `${year}-12-31` : time.format('YYYY-MM-DD')
      };
    } else if (unit === 'month') {
      let target = last ? time.subtract(1, 'months') : time;
      period = {
        start: target.format('YYYY-MM-01'),
        end: last
          ? target.endOf('month').format('YYYY-MM-DD')
          : time.format('YYYY-MM-DD')
      };
    } else if (unit === 'quarter') {
      let currentQuarter = this._whichQuarter(time.month());
      let targetQuarter = (currentQuarter - (last ? 1 : 0) + 4) % 4;
      let year = currentQuarter < targetQuarter ? time.year() - 1 : time.year();
      let startMonth = 3 * targetQuarter;
      let target = moment()
        .year(year)
        .month(startMonth)
        .startOf('month');
      period = {
        start: target.format('YYYY-MM-DD'),
        end: target
          .add(2, 'months')
          .endOf('month')
          .format('YYYY-MM-DD')
      };
    } else if (unit === '2 weeks') {
      let target = time.subtract(2, 'weeks').day(1);
      period = {
        start: target.format('YYYY-MM-DD'),
        end: target
          .add(2, 'weeks')
          .day(0)
          .format('YYYY-MM-DD')
      };
    }

    this.set('period', period);
    this.set('periodStart', period.start);
    this.set('periodEnd', period.end);
  }

  _rowsToText(rows, level) {
    return rows
      .reduce((acc, row) => {
        let timeInclusive = `${Math.floor(
          row.minutesInclusive / 60
        )}:${row.minutesInclusive % 60}`;
        let timeExclusive =
          row.minutesExclusive > 0
            ? `${Math.floor(row.minutesExclusive / 60)}:${row.minutesExclusive %
                60}`
            : '';
        acc.push(
          `${'>'.repeat(level)}${level > 0 ? ' ' : ''}${
            row.name
          }\t${timeInclusive}\t${timeExclusive}`
        );
        if (row.children) {
          acc.push(this._rowsToText(row.children, level + 1));
        }

        return acc;
      }, [])
      .join('\n');
  }

  @action
  setDefaults() {
    this.set('period.start', this.periodStart);
    this.set('period.end', this.periodEnd);
    if (this.customer) {
      this.set('selectedCustomer', this.store.find('customer', this.customer));
    }

    if (this.contractor) {
      this.set(
        'selectedContractor',
        this.store.find('contractor', this.contractor)
      );
    }

    if (this.customer || this.contractor) {
      this.fetchReport.perform();
    }
  }

  @action
  updateCategory(newValue) {
    this.set('category', newValue);
    if (this.tableData !== undefined) {
      this._startTree();
    }
  }

  @action
  updateSearchText(newValue) {
    this.set('searchText', newValue);
  }

  @action
  updateCustomer(newValue) {
    this.set('selectedCustomer', newValue);
    if (newValue == null) {
      this.set('customer', null);
      this.set('rows', []);
    } else {
      this.set('customer', newValue.get('id'));
      this.fetchReport.perform();
    }
  }

  @action
  updateContractor(newValue) {
    this.set('selectedContractor', newValue);
    if (newValue == null) {
      this.set('contractor', null);
      this.set('rows', []);
    } else {
      this.set('contractor', newValue.get('id'));
      this.fetchReport.perform();
    }
  }

  @action
  updatePeriodStart(newValue) {
    this.set('period.start', newValue);
    this.set('periodStart', newValue);
    if (this.period.start && this.period.end) {
      this.fetchReport.perform();
    }
  }

  @action
  updatePeriodEnd(newValue) {
    this.set('period.end', newValue);
    this.set('periodEnd', newValue);
    if (this.period.start && this.period.end) {
      this.fetchReport.perform();
    }
  }

  @action
  setPeriodTo(unit, last) {
    this._setPeriodTo(unit, last);
    this.fetchReport.perform();
  }

  @action
  copyToClipboard() {
    let text = this._rowsToText(this.compactRows, 0);
    let textArea = document.createElement('TEXTAREA');
    document.body.appendChild(textArea);
    textArea.innerHTML = `\tTime Total\tTime Exclusive\n${text}`;
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.get('paperToaster').show('Copied report to clipboard', {
      position: 'top right'
    });
  }
}
