import DS from 'ember-data';

const { Model, attr, belongsTo } = DS;

export default class Worked extends Model {
  @attr()
  hoursWorked;

  @belongsTo()
  contractor;
}
