import Controller from '@ember/controller';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import moment from 'moment';

export default Controller.extend({
  router: service(),
  sortOrder: Object.freeze(['osheeMinutes:desc']),
  oshees: sort('model', 'sortOrder'),
  actions: {
    reportUrl(contractorId) {
      return this.router.urlFor('schedule.report', {
        queryParams: {
          category: 'oshee',
          contractor: contractorId,
          periodStart: moment().startOf('year').format('YYYY-MM-DD'),
          periodEnd: moment().endOf('year').format('YYYY-MM-DD')
        }
      });
    }
  }
});
