import { tagName } from '@ember-decorators/component';
import { computed } from '@ember/object';
import Component from '@ember/component';
import moment from 'moment';

export default @tagName('') class WidgetsView extends Component {
  @computed
  get periods() {
    return {
      lastMonth: {
        label: 'Last Month',
        start: moment().subtract(1, 'months').startOf('month'),
        end: moment().subtract(1, 'months').endOf('month')
      },

      lastWeek: {
        label: 'Last Week',
        start: moment().subtract(1, 'weeks').startOf('isoweek'),
        end: moment().subtract(1, 'weeks').endOf('isoweek')
      },

      thisWeek: {
        label: 'This Week',
        start: moment().startOf('isoweek'),
        end: moment().endOf('isoweek')
      },

      thisMonth: {
        label: 'This Month',
        start: moment().startOf('month'),
        end: moment().endOf('month')
      }
    };
  }
}
