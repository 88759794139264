import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { sum, alias } from '@ember/object/computed';
import Component from '@ember/component';
import moment from 'moment';

export default @classNames('finance-stats') class FinanceStats extends Component {
  @sum('stats.inflowValues')
  inflowSum;

  @sum('stats.directCosts')
  directSum;

  @sum('stats.overheadCosts')
  overheadSum;

  @sum('stats.profits')
  profitSum;

  @alias('model.customer.balance.hoursBooked')
  bookedSum;

  @alias('model.customer.balance.hoursPayable')
  billableSum;

  @computed('bookedSum', 'billableSum')
  get balanceSum() {
    return this.bookedSum - this.billableSum;
  }

  @computed('model.customer')
  get sinceMonth() {
    return moment(this.model.customer.since).format("MMM 'YY");
  }

  @computed('bookedSum,billableSum')
  get bookedOrBillable() {
    if (this.bookedSum > this.billableSum) {
      return this.bookedSum;
    } else {
      return this.billableSum;
    }
  }

  @computed('inflowSum,bookedOrBillable')
  get revenuePerHour() {
    if (this.inflowSum && this.bookedOrBillable && this.bookedOrBillable > 0) {
      return this.inflowSum / this.bookedOrBillable;
    } else {
      return 0;
    }
  }

  @computed('profitSum,bookedOrBillable')
  get profitPerHour() {
    if (this.profitSum && this.bookedOrBillable && this.bookedOrBillable > 0) {
      return this.profitSum / this.bookedOrBillable;
    } else {
      return 0;
    }
  }
}
