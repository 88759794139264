import { classNames, classNameBindings } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';
import Component from '@ember/component';

export default

@classNames('kudo-badge')
@classNameBindings('directionClass', 'hide', 'smallTextClass:mm-font-size-small')
class Badge extends Component {
  @computed('direction')
  get directionClass() {
    return `badge-${this.direction}`;
  }

  @computed('kudos')
  get smallTextClass() {
    return this.kudos > 99;
  }

  @equal('kudos', 0)
  hide;

  @computed('kudos')
  get kudosCountable() {
    return Number.isInteger(this.kudos);
  }
}
