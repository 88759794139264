import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ImportTimeEntriesController extends Controller {
  queryParams = ['activeTab'];

  @tracked
  activeTab = 0;

  @action
  setActiveTab(value) {
    this.activeTab = value;
  }
}
