import AuthenticatedRoute from 'selleo-dashboard/routes/authenticated-route';
import RSVP from 'rsvp';
import { orderBy } from 'lodash';

export default class TeamsRoute extends AuthenticatedRoute {
  model() {
    return RSVP.hash({
      teams: this.store.query('team', {
        include: 'members,members.contractor,members.role'
      }),

      orphans: this.store.query('contractor', {
        filter: { orphans: true }
      }),

      utilizations: this.store.findAll('schedule-contractor-utilization', {
        include: ['contractor']
      }),

      // temp fix, will be properly fix in seprate story
      // surveys: this.store.query('css-survey', { filter: { group_for: 'css_chart' }, include: 'team,team.members' })
    });
  }

  afterModel(model) {
    model.teams = orderBy(model.teams.toArray(), (team) => team.members.length, 'desc');
    return model;
  }
}

