/* global Highcharts moment */
export function initialize() {
  Highcharts.dateFormats.W = function(timestamp) {
    return moment(timestamp).week();
  };
}

export default {
  initialize
};
