import { className, classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { or } from '@ember/object/computed';
import { inRange } from 'lodash';
import { inPercentage } from 'selleo-dashboard/helpers/in-percentage';

export default @classNames('image-circle') class ImageWithProgressBar extends Component {
  bounds = Object.freeze([26, 51]);

  colourClasses = Object.freeze(['low', 'average', 'high']);

  @or('fill', 'percentage')
  fillOrPercentage;

  @or('scope', 'progress')
  scale;

  @computed('value', 'scale')
  get percentage() {
    return inPercentage([this.value, this.scale]);
  }

  @computed('value')
  get hideProgress() {
    return this.value === 0;
  }

  @className
  @computed('scale')
  get colourScale() {
    let  [lowerBound, upperBound] = this.bounds;
    let { percentage } = this;
    let colour;

    if (inRange(percentage, -Infinity, lowerBound)) {
      colour = this.colourClasses[0];
    }

    if (inRange(percentage, lowerBound, upperBound)) {
      colour = this.colourClasses.length < 3 ? this.colourClasses[0] : this.colourClasses[1];
    }

    return colour ? colour : this.colourClasses.length < 3 ? this.colourClasses[1] : this.colourClasses[2];
  }
}
