import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { sortBy, groupBy, uniqBy, sum, compact, flatMap } from 'lodash';
import { computed } from '@ember/object';

export default class TeamFinance extends Component {
  @service
  store;

  @computed('scope')
  get categories() {
    return this.aggregates[this.scope].map((a) => a.label);
  }

  @computed('model')
  get series() {
    let customers = uniqBy(
      this.model.cashFlow.clientAndTeamLeads
        .map((ctl) => ctl.get('customer')),
      (c) => c.get('id'));

    let calcRevenue = (collection)=> {
      return collection.reduce(
        (acc, inflow) => {
          acc[inflow.get('period.id')] = inflow.value;
          return acc;
        }, {});

    };

    let calcProfit = (collection, revenueByPeriod) => {
      return collection.reduce(
        (acc, outflow) => {
          let periodId = outflow.get('period.id');
          let revenue = revenueByPeriod[periodId] || 0;

          acc[periodId] = sum([revenue, -outflow.directLaborCost, -outflow.overheadCost]);

          return acc;
        }, {});
    };

    let anyValues = (profit, revenue) => Object.values(revenue)[0] || Object.values(profit)[0];

    return sortBy(
      compact(customers.map((customer) => {
        let revenueByPeriod = calcRevenue(customer.get('cashInflows').toArray());
        let profitByPeriod = calcProfit(customer.get('cashOutflows').toArray(), revenueByPeriod);

        if (anyValues(profitByPeriod, revenueByPeriod)) {
          return {
            name: customer.get('name'),
            profit: profitByPeriod,
            revenue: revenueByPeriod
          };
        }
      })),
      (d) => sum(Object.values(d.profit))
    );
  }

  @computed('data', 'scope')
  get chartData() {

    let sumValues = (collection) => {
      return this.aggregates[this.scope].map(({ periodIds }) => {
        return Math.round(sum(periodIds.map((pId) => collection[pId])));
      });
    };

    return flatMap(this.series, ((serie) => {
      return [{
        name: `[P] ${serie.name}`,
        data: sumValues(serie.profit)
      },

      {
        visible: false,
        name: `[R] ${serie.name}`,
        data: sumValues(serie.revenue)
      }];
    }));
  }

  @computed('series.[]', 'categories.[]')
  get chartOptions() {
    return {
      chart: {
        height: 600,
        type: 'areaspline',
        zoomType: 'xy'
      },

      title: {
        text: null
      },

      xAxis: [
        {
          categories: this.categories,
          crosshair: true,
        },
      ],

      yAxis: {
        labels: {
          format: '{value} zł',
        },

        title: {
          text: 'PLN',
        }
      },

      tooltip: {
        shared: true,
        pointFormat: '<span style="color: {series.color}">●</span> {series.name}: <b>{point.y:.2f} zł</b><br/>'
      },

      legend: {
        backgroundColor: '#FFFFFF',
        itemWidth: 200,
        width: 800
      },

      plotOptions: {
        areaspline: {
          stacking: 'normal'
        }
      }
    };
  }

  // this pretty generic and should be extracted
  @computed('periods')
  get aggregates() {
    let scopes = ['monthly', 'quarterly', 'semesterly', 'yearly'];
    let periods = sortBy(this.model.periods.toArray(), (p) => p.month.index);

    return scopes.reduce((acc, scope) => {
      let groupedPeriods = Object.values(
        groupBy(periods,
          (p) => p.get(`${scope}.index`)
        )
      );

      acc[scope] = groupedPeriods.map((group) => {
        return {
          label: group[0][scope].label,
          periodIds: group.map((period) => period.get('id'))
        };
      });

      return acc;
    }, {});
  }
}
