import DS from 'ember-data';
const { Model, attr, belongsTo } = DS;
export default class DailyTimeStat extends Model {
  @belongsTo()
  contractor;

  @belongsTo()
  customer;

  @attr('date')
  date;

  @attr('number')
  worked;

  @attr('number')
  overtime;

  @attr('number')
  weekend;

  @attr('number')
  billable;
}
