import DS from 'ember-data';

const {
  Model,
  attr,
  belongsTo
} = DS;

export default class MeritMoneyContractorWeek extends Model {
  @belongsTo('merit-money-week')
  week;

  @attr('number')
  kudosRation;

  @belongsTo()
  contractor;

  @attr('number')
  pendingKudos;
}
