import Model, { attr } from '@ember-data/model';

export default class TermModel extends Model {
  @attr('string')
  title;

  @attr('string')
  richContent;

  @attr('number')
  version;
}
